enum ActionType {
  GET_WORK_ORDERS_BY_FILTERS = 'work-order/get-work-orders-by-filter',
  GET_WORK_ORDER_BY_ID = 'work-order/get-work-order-by-id',
  ADD_WORK_ORDERS = 'work-order/add-work-order',
  GET_WORK = 'work-order/get-work',
  REMOVE_WORK = 'work-order/remove-work',
  ADD_WORK = 'work-order/add-work',
  UPDATE_WORK_ORDER = 'work-order/update',
  UPDATE_CLIENT_SP_IN_WORK_ORDER = 'work-order/update-client-sp',
}

export { ActionType };
