import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CarDto,
  GetCarByIdRequestDto,
  GetCarsDataRequestDto,
  GetCarsDataResponseDto,
  InfoVinSearchDto,
  InfoVinSearchRequest,
  Model,
  ModifyCarDataRequestDto,
} from 'common/types/types';

import { AsyncThunkConfig } from '../../common/types/app/async-thunk-config.type';
import { ActionType } from './common';

const getModels = createAsyncThunk<
  Model[],
  { car_brand_id: number },
  AsyncThunkConfig
>(ActionType.GET_MODELS, async (payload, { extra }) => {
  const { carApi } = extra;

  return carApi.getModels(payload);
});

const addCar = createAsyncThunk<
  CarDto,
  ModifyCarDataRequestDto,
  AsyncThunkConfig
>(ActionType.ADD_CAR, async(payload, { extra }) => {
  const { carApi } = extra;

  return carApi.addCar(payload);
});

const getCars = createAsyncThunk<
  GetCarsDataResponseDto,
  GetCarsDataRequestDto,
  AsyncThunkConfig
>(ActionType.GET_CARS, async(payload, { extra }) => {
  const { carApi } = extra;
  const response = await carApi.getCars(payload);
  //TODO we really need it 15.06.2023
  // if (response.cars.length < response.carsCount) {
  //   return carApi.getCars({ ...payload, size: response.carsCount });
  // }

  return response;
});

const getCarById = createAsyncThunk<
  CarDto,
  GetCarByIdRequestDto,
  AsyncThunkConfig
>( ActionType.GET_CAR_BY_ID, async (payload, { extra }) => {
  const { carApi } = extra;

  return carApi.getCarById(payload);
});

const updateCarById = createAsyncThunk<
  CarDto,
  ModifyCarDataRequestDto,
  AsyncThunkConfig
>(ActionType.UPDATE_CAR_BY_ID, async(payload, { extra }) => {
  const { carApi } = extra;

  return carApi.updateCar(payload);
});

const searchCar = createAsyncThunk<
  InfoVinSearchDto,
  InfoVinSearchRequest,
  AsyncThunkConfig
>(ActionType.SEARCH, async(payload, { extra }) => {
  const { carApi } = extra;

  return carApi.searchCar(payload);
});

export { addCar, getCarById, getCars, getModels, searchCar, updateCarById };
