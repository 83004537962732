import { LocalStorageKey } from 'common/enums/enums';

type Constructor = {
  storage: globalThis.Storage;
};

class Storage {
  #storage: globalThis.Storage;

  public constructor({ storage }: Constructor) {
    this.#storage = storage;
  }

  public getItem(key: LocalStorageKey): string | null {
    return this.#storage.getItem(key);
  }

  public getBoolean(key: LocalStorageKey, defaultValue?: boolean): boolean {
    const value = this.#storage.getItem(key);

    return value === null ? !!defaultValue : (value === 'true');
  }

  public setItem(key: LocalStorageKey, value: string): void {
    return this.#storage.setItem(key, value);
  }

  public removeItem(key: LocalStorageKey): void {
    return this.#storage.removeItem(key);
  }

  public clear(): void {
    return this.#storage.clear();
  }
}

export { Storage };
