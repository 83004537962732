import { TableColumn } from 'common/types/types';

const getFavCarWorkTableColumn = (labels: string): TableColumn[] => {
  const labelsArgs = labels.split('|');

  return [
    {
      key: 'carWorkName',
      label: labelsArgs[0],
      _style: { width: '30%' },
    },
    {
      key: 'carWorkType',
      label: labelsArgs[1],
      _style: { width: '25%' },
    },
    {
      key: 'time',
      label: labelsArgs[2],
      filter: false,
      _style: { width: '10%' },
    },
    {
      key: 'cost',
      label: labelsArgs[3],
      filter: false,
      _style: { width: '10%' },
    },
    {
      key: 'info',
      label: labelsArgs[4],
      filter: false,
      sorter: false,
      _style: { width: '25%' },
    },
  ];
};

export { getFavCarWorkTableColumn };
