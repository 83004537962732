import {
  CButton,
  CCardBody,
  CCardFooter,
  CCol,
  CForm,
  CFormInput, CFormSelect,
  CLoadingButton,
  CRow,
} from '@coreui/react-pro';
import { AppTranslationKey, DataStatus } from 'common/enums/enums';
import { CarServiceDto, CarServiceEditRequestDto } from 'common/types/types';
import { useAppSelector, useEffect, useNavigate } from 'hooks/hooks';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextArea } from '../../textarea/text-area';
import { DEFAULT_EDIT_CAR_SERVICE_PAYLOAD } from './common';

type Props = {
  carService?: CarServiceDto;
  onSubmit: (payload: CarServiceEditRequestDto) => void;
};

const CarServiceForm: FC<Props> = ({ onSubmit, carService = null }) => {

  const navigate = useNavigate();
  const { t } = useTranslation(AppTranslationKey.CAR_SERVICE);
  const {
    updateDataStatus,
    carServiceStatuses,
  } = useAppSelector(({ dictionary, carServices }) =>
    ({
      updateDataStatus: carServices.updateDataStatus,
      carServiceStatuses: dictionary.data?.carServiceStatuses || [],
    }),
  );

  const isLoading = Boolean(updateDataStatus === DataStatus.PENDING);

  const {
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<CarServiceEditRequestDto>({
    defaultValues: DEFAULT_EDIT_CAR_SERVICE_PAYLOAD,
  });

  const watchInfo = watch('info');

  useEffect(() => {
    if (carService) {
      reset({
        name: carService.name,
        address: carService.address,
        info: carService.info,
        id: carService.id,
        statusId: carService.statusId,
      });
    }
  }, [ reset, carService ]);

  return (
    <CForm onSubmit={handleSubmit(onSubmit)}>
      <CCardBody>
        <CRow xs={{ gutterY: 3 }}>
          <CCol md={3}>
            <CFormInput
              {...register('name',
                {
                  required: t('carServiceFormFieldNameErrorRequired') ?? 'Required',
                })
              }
              type="text"
              label={t('carServiceFormFieldName')}
              name="name"
              invalid={Boolean(errors.name)}
              feedbackInvalid={errors.name?.message}
            />
          </CCol>
          <CCol>
            <CFormSelect
              {...register('statusId',
                {
                  required: t('carServiceFormFieldNameErrorRequired') ?? 'This field is required',
                },
              )}
              name="statusId"
              label={t('carServiceFormFieldStatus')}
              invalid={Boolean(errors.statusId)}
              feedbackInvalid={errors.statusId?.message}
            >
              {
                carServiceStatuses.map((item) => (
                  <option
                    key={item.id} value={item.id}>{item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                  </option>
                ))
              }
            </CFormSelect>
          </CCol>
          <CCol md={6}>
            <CFormInput
              {...register('address',
                {
                  required: t('carServiceFormFieldAddressErrorRequired') ?? 'Required',
                })
              }
              type="text"
              label= {t('carServiceFormFieldAddress')}
              name="address"
              invalid={Boolean(errors.address)}
              feedbackInvalid={errors.address?.message}
            />
          </CCol>
          <CCol md={6}>
            <TextArea<CarServiceEditRequestDto>
              register={ register }
              limitSymbols={ 1024 }
              label={t('carServiceFormFieldInfo')}
              rows={3}
              nameFiled="info"
              value={ watchInfo }
            />
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <CRow>
          <CCol style={{ display: 'flex', justifyContent: 'flex-end' }} md={12}>
            <CButton
              className="me-2"
              color="dark"
              variant="ghost"
              onClick={ (): void => navigate(-1) }
            >
              {t('backCaptionButton')}
            </CButton>
            <CLoadingButton
              color="primary"
              type="submit"
              disabledOnLoading
              loading={isLoading}
            >
              {t('saveCaptionButton')}
            </CLoadingButton>
          </CCol>
        </CRow>
      </CCardFooter>
    </CForm>
  );
};

export { CarServiceForm };
