import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react-pro';
import { useParams } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { routes } from './router-config';

interface IRouteName {
  name: string,
  params?: string[],
}

interface IRoute {
  path: string,
  names: IRouteName[],
}

interface IBreadcrumbsName {
  name: string,
  params?: Record<string, string | number>,
}

interface IBreadcrumbs {
  pathname: string,
  names: IBreadcrumbsName[],
  active: boolean,
}

const Breadcrumbs: FC = () => {
  const { t }    = useTranslation('breadcrumbs');
  const currentLocation = useLocation().pathname;
  const params = useParams();

  const getRouteName = (pathname:string, routes:IRoute[]): IRouteName[] => {
    const currentRoute = routes.find((route) => route.path === pathname);

    return currentRoute ? [...currentRoute.names] : [];
  };

  const getBreadcrumbs = (location:string):IBreadcrumbs[] => {
    const breadcrumbs:IBreadcrumbs[] = [];

    location.split('/').reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`;
      const routeNames = getRouteName(currentPathname, routes);

      if (routeNames.length) {
        const breadcrumbsNames: IBreadcrumbsName[] = routeNames.map((item) => {
          const nameParams: Record<string, string | number> = {};

          if (item.params) {
            item.params.forEach((param) => {
              nameParams[param] = params[param] || '';
            });
          }

          return {
            name: item.name,
            params: nameParams,
          };
        });

        breadcrumbs.push({
          pathname: currentPathname,
          names: breadcrumbsNames,
          active: (index + 1) === array.length,
        });
      }

      return currentPathname;
    });

    breadcrumbs[breadcrumbs.length - 1].active = true;

    return breadcrumbs;
  };

  const breadcrumbs = getBreadcrumbs(currentLocation);

  return (
    <CBreadcrumb className="m-0 ms-2">
      {/*<CBreadcrumbItem href="/personal/dashboards">{t('home')}</CBreadcrumbItem>*/}
      {
        breadcrumbs.map(({ active, pathname, names }, index) => names.map((name) =>  {
          return (
            <CBreadcrumbItem
              {...(active ? { active: true } : { href: pathname })}
              key={`${index}-${name.name}`}
            >
              {t(name.name, name.params ?? {})}
            </CBreadcrumbItem>
          );
        }))
      }
    </CBreadcrumb>
  );
};

export { Breadcrumbs };
