import { AppTranslationKey, ClientSparePartsTranslationKey } from 'common/enums/enums';
import { TableColumn } from 'common/types/types';
import { t } from 'i18next';

const getClientSparePartsTableColumn = (): TableColumn[] => {
  return [
    {
      key: 'name',
      label: t(`${AppTranslationKey.CLIENT_SPARE_PARTS}:${ClientSparePartsTranslationKey.NAME}`),
      _style: { width: '45%' },
    },
    {
      key: 'count',
      label: t(`${AppTranslationKey.CLIENT_SPARE_PARTS}:${ClientSparePartsTranslationKey.COUNT}`),
      _style: { width: '25%' },
    },
    {
      key: 'remove',
      label: '',
    },
  ];
};

export { getClientSparePartsTableColumn };
