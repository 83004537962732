import cn from 'classnames';
import React, { FC } from 'react';

import styles from './styles.module.scss';

interface IProps {
  title: string,
  message: string | React.ReactElement,
  isError?: boolean,
}

const InfoMessage: FC<IProps> = ({ title, message, isError }) => (
  <div className={ cn(styles.messageWrapper, { [styles.error]: isError }) }>
    <div className={ styles.messageTitle }>{ title }</div>
    <div className={ styles.messageText }>{ message }</div>
  </div>
);

export { InfoMessage };
