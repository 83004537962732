import { Column } from '@coreui/react-pro/dist/components/smart-table/types';
import { AppTranslationKey } from 'common/enums/enums';
import { i18next } from 'i18n';

const columns: Column[] = [
  {
    key: 'carRegNum',
    label: i18next.t(`${AppTranslationKey.CAR_MODAL}:table.regNum`) as string,
  },
  {
    key: 'carBrandName',
    label: i18next.t(`${AppTranslationKey.CAR_MODAL}:table.brand`) as string,
  },
  {
    key: 'carModelName',
    label: i18next.t(`${AppTranslationKey.CAR_MODAL}:table.model`) as string,
  },
  {
    key: 'carInfo',
    label: i18next.t(`${AppTranslationKey.CAR_MODAL}:table.info`) as string,
  },
];

export { columns };
