import { createSlice } from '@reduxjs/toolkit';
import { DataStatus } from 'common/enums/enums';
import { SparePartsDto, SparePartsGetListDto, UserSpareParts } from 'common/types/types';

import {
  createSparePart,
  getAllSparePartsByStoreId,
  getListSpareParts,
  getSparePartsByUserId,
  updateSparePart,
} from './action';

type State = {
  data: SparePartsGetListDto,
  userSpareParts: UserSpareParts[],
  stockSpareParts: SparePartsDto[],
  dataStatusGetSpareParts: DataStatus,
  dataStatusCreate: DataStatus,
  dataStatusGetList: DataStatus,
  dataStatusUpdate: DataStatus,
};

const initialState: State = {
  data: {
    currentPage: 0,
    totalPages: 0,
    sparePartsCount: 0,
    spareParts: [],
  },
  userSpareParts: [],
  stockSpareParts: [],
  dataStatusGetSpareParts: DataStatus.IDLE,
  dataStatusCreate: DataStatus.IDLE,
  dataStatusGetList: DataStatus.IDLE,
  dataStatusUpdate: DataStatus.IDLE,
};

const sparePartsSlice = createSlice({
  name: 'spareParts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // create spare part
      .addCase(createSparePart.pending, (state) => {
        state.dataStatusCreate = DataStatus.PENDING;
      })
      .addCase(createSparePart.rejected, (state) => {
        state.dataStatusCreate = DataStatus.REJECTED;
      })
      .addCase(createSparePart.fulfilled, (state) => {
        state.dataStatusCreate = DataStatus.FULFILLED;
      })
      // get list spare parts
      .addCase(getListSpareParts.pending, (state) => {
        state.dataStatusGetList = DataStatus.PENDING;
      })
      .addCase(getListSpareParts.rejected, (state) => {
        state.dataStatusGetList = DataStatus.REJECTED;
        state.data = initialState.data;
      })
      .addCase(getListSpareParts.fulfilled, (state, action) => {
        state.dataStatusGetList = DataStatus.FULFILLED;
        state.data = action.payload;
      })
      // update spare part
      .addCase(updateSparePart.pending, (state) => {
        state.dataStatusUpdate = DataStatus.PENDING;
      })
      .addCase(updateSparePart.rejected, (state) => {
        state.dataStatusUpdate = DataStatus.REJECTED;
      })
      .addCase(updateSparePart.fulfilled, (state) => {
        state.dataStatusUpdate = DataStatus.FULFILLED;
      })

      //get spare parts by user id
      .addCase(getSparePartsByUserId.pending, (state) => {
        state.dataStatusGetSpareParts = DataStatus.PENDING;
      })
      .addCase(getSparePartsByUserId.rejected, (state) => {
        state.dataStatusGetSpareParts = DataStatus.REJECTED;
        state.userSpareParts = [];
      })
      .addCase(getSparePartsByUserId.fulfilled, (state, action) => {
        state.dataStatusGetSpareParts = DataStatus.FULFILLED;
        state.userSpareParts = action.payload;
      })

      //get spare parts by stock id
      .addCase(getAllSparePartsByStoreId.pending, (state) => {
        state.dataStatusGetSpareParts = DataStatus.PENDING;
      })
      .addCase(getAllSparePartsByStoreId.rejected, (state) => {
        state.dataStatusGetSpareParts = DataStatus.REJECTED;
        state.stockSpareParts = [];
      })
      .addCase(getAllSparePartsByStoreId.fulfilled, (state, action) => {
        state.dataStatusGetSpareParts = DataStatus.FULFILLED;
        state.stockSpareParts = action.payload;
      });
  },
});

const sparePartsReducer = sparePartsSlice.reducer;

export { sparePartsReducer };
