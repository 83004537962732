import { CFormSwitch, CSmartTable } from '@coreui/react-pro';
import { AppTranslationKey, CarWorkTranslationKey } from 'common/enums/enums';
import { CarWorkGetAllResponse } from 'common/types/types';
import { NoData, Spinner } from 'components/common/common';
import { getItemPerPageOptions } from 'helpers/helpers';
import { useMemo } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getCarWorkTableColumn } from './heplpers/helpers';

type Props = {
  carWork: CarWorkGetAllResponse[];
  isLoading: boolean;
  onSelectCarWork: (item: CarWorkGetAllResponse) => void;
};

const CarWorkTable: FC<Props> = ({ carWork, isLoading, onSelectCarWork }) => {

  const carWorkModalTableColumn = useMemo(() => getCarWorkTableColumn(), []);
  const { t } = useTranslation([AppTranslationKey.CAR_WORK, AppTranslationKey.COMMON]);
  const itemsPerPage: number[] = useMemo( () => getItemPerPageOptions(carWork.length), [ carWork ] );

  if (isLoading) {
    return <Spinner isOverflow containerHeight={120}/>;
  }

  return <CSmartTable
    columns={carWorkModalTableColumn}
    columnSorter
    columnFilter
    sorterValue={{ column: 'serviceWorkName', state: 'asc' }}
    items={carWork}
    itemsPerPageSelect
    noItemsLabel={
      <NoData
        title={t('noItemsFoundTitle', { ns: AppTranslationKey.COMMON })}
        message={t('noItemsFoundMsg', { ns: AppTranslationKey.COMMON })}
      />
    }
    itemsPerPageOptions={itemsPerPage}
    itemsPerPageLabel={t(CarWorkTranslationKey.ITEMS_PER_PAGE_LABEL) ?? 'Елементів на сторінці'}
    pagination
    scopedColumns={{
      isFavorite: (item: CarWorkGetAllResponse) => (
        <td>
          <CFormSwitch type="checkbox" checked={item.isFavorite} onChange={(): void => onSelectCarWork(item)} />
        </td>
      ),
    }}
    tableHeadProps={{
      color: 'light',
    }}
    tableProps={{
      striped: true,
      hover: true,
    }}
  />;
};

export { CarWorkTable };
