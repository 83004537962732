import { createSlice } from '@reduxjs/toolkit';
import { DataStatus } from 'common/enums/enums';
import { SupplierDto } from 'common/types/types';

import { createSupplier, editSupplier, getListSuppliers, getSupplierById } from './actions';

type State = {
  list: SupplierDto[],
  currentSupplier: SupplierDto | undefined,
  dataStatusCreate: DataStatus,
  dataStatusList: DataStatus,
  dataStatusCurrent: DataStatus,
  dataStatusEdit: DataStatus,
};

const initialState: State = {
  list: [],
  currentSupplier: undefined,
  dataStatusCreate: DataStatus.IDLE,
  dataStatusList: DataStatus.IDLE,
  dataStatusCurrent: DataStatus.IDLE,
  dataStatusEdit: DataStatus.IDLE,
};

const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    resetEditPage: (state) => {
      state.currentSupplier = undefined;
      state.dataStatusEdit = DataStatus.IDLE;
    },
    resetCreatePage: (state) => {
      state.dataStatusCreate = DataStatus.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      // create supplier
      .addCase(createSupplier.pending, (state) => {
        state.dataStatusCreate = DataStatus.PENDING;
      })
      .addCase(createSupplier.rejected, (state) => {
        state.dataStatusCreate = DataStatus.REJECTED;
      })
      .addCase(createSupplier.fulfilled, (state) => {
        state.dataStatusCreate = DataStatus.FULFILLED;
      })
      // get list
      .addCase(getListSuppliers.pending, (state) => {
        state.dataStatusList = DataStatus.PENDING;
      })
      .addCase(getListSuppliers.rejected, (state) => {
        state.dataStatusList = DataStatus.REJECTED;
        state.list = [];
      })
      .addCase(getListSuppliers.fulfilled, (state, { payload }) => {
        state.dataStatusList = DataStatus.FULFILLED;
        state.list = payload;
      })
      // get by id
      .addCase(getSupplierById.pending, (state) => {
        state.dataStatusCurrent = DataStatus.PENDING;
      })
      .addCase(getSupplierById.rejected, (state) => {
        state.dataStatusCurrent = DataStatus.REJECTED;
      })
      .addCase(getSupplierById.fulfilled, (state, { payload }) => {
        state.dataStatusCurrent = DataStatus.FULFILLED;
        state.currentSupplier = payload;
      })
      // edit
      .addCase(editSupplier.pending, (state) => {
        state.dataStatusEdit = DataStatus.PENDING;
      })
      .addCase(editSupplier.rejected, (state) => {
        state.dataStatusEdit = DataStatus.REJECTED;
      })
      .addCase(editSupplier.fulfilled, (state) => {
        state.dataStatusEdit = DataStatus.FULFILLED;
      });
  },
});

const supplierReducer = supplierSlice.reducer;
const { resetEditPage, resetCreatePage } = supplierSlice.actions;

export { resetCreatePage, resetEditPage, supplierReducer };
