import { CButton } from '@coreui/react-pro';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './style.module.scss';

type Props = {
  onClick: () => void,
};

const EditBtn: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation('common');

  return (
    <CButton
      variant="outline"
      color="danger"
      className={styles.editButton}
      onClick={ onClick }
    >
      {t('editButton')}
    </CButton>
  );
};

export { EditBtn };
