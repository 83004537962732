import { ApiPath, EmployeesApiPath } from 'common/enums/enums';
import { Employee } from 'common/types/types';
import { Http } from 'services/http/http.service';

type Constructor = {
  http: Http;
  apiPrefix: string;
};

class EmployeesApi {

  #http: Http;

  #apiPrefix: string;

  public constructor({ http, apiPrefix }: Constructor) {
    this.#http = http;
    this.#apiPrefix = apiPrefix;
  }

  public getEmployeesByCarServiceId({ carServiceId }:{ carServiceId:number }):Promise<Employee[]> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.EMPLOYEES}${EmployeesApiPath.CAR_SERVICE}/${carServiceId}`,
    );
  }

}

export { EmployeesApi };
