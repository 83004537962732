import { CCard, CCardBody, CCardText, CCardTitle } from '@coreui/react-pro';
import { Colors } from 'common/types/types';
import { CSSProperties, FC, ReactElement } from 'react';

type Props = {
  title: string,
  message: string | ReactElement | null,
  color?: Colors,
  textColor?: Colors,
  style?: CSSProperties,
};
const NoData: FC<Props> = ({ style = { padding: 0, margin: 0 }, title, message, color= 'info', textColor= 'info' }) => {

  return (
    <CCard
      className={`text-center border-${color}`}
      textColor={textColor}
    >
      <CCardBody style={style}>
        <CCardTitle className="m-1">{title}</CCardTitle>
        <CCardText>{message}</CCardText>
      </CCardBody>
    </CCard>
  );
};

export { NoData };
