enum Tariffs {
  NO_TARIFF = 0,
  PARTNERSHIP = 1,
  TEST_UNLIMITED = 2,
  START_UNLIMITED = 3,
  MINIMUM = 4,
  STANDARD = 5,
  UNLIMITED = 6,
}

export { Tariffs };
