import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkConfig, GetConfidentialTextResponse } from 'common/types/types';

import { ActionType } from './common';

const getConfidentialText = createAsyncThunk<
  GetConfidentialTextResponse,
  { textId: number },
  AsyncThunkConfig
>(ActionType.GET_CONFIDENTIAL_TEXT, async(payload, { extra }) => {
  const { confidentialTextApi } = extra;

  return confidentialTextApi.getConfidentialText(payload);
});

export { getConfidentialText };
