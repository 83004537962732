import { cilInfo } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CAlert, CForm, CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react-pro';
import { AppTranslationKey, DataStatus, SelectOrderModalTranslationKey } from 'common/enums/enums';
import { BindOrder } from 'common/types/types';
import { Spinner } from 'components/common/common';
import { ModalFooter } from 'components/modals/components/components';
import { useAppDispatch, useAppSelector, useEffect, useForm, useMemo, useState } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { carServicesActions } from 'store/actions';
import { addBindOrder } from 'store/work-order/reducer';

import { SelectedOrderAlert, SelectOrdersTable } from './components/component';

type Props = {
  isOpen: boolean,
  onCloseModal: () => void,
  carServiceId: number,
};
const SelectOrderModal: FC<Props> = ({ isOpen, onCloseModal, carServiceId }) => {

  const dispatch = useAppDispatch();
  const { handleSubmit } = useForm();
  const { t } = useTranslation(AppTranslationKey.SELECT_ORDER_MODAL);
  const { orders, getOrdersDataStatus } = useAppSelector(({ carServices }) => carServices);
  const [bindOrder, setBindOrder] = useState<BindOrder | null>(null);

  const isLoadingOrders = getOrdersDataStatus === DataStatus.PENDING;

  useEffect(() => {
    isOpen && dispatch(carServicesActions.getActiveOrdersByCarServiceId({ carServiceId }));
  }, [carServiceId, dispatch, isOpen]);

  const selectedOrderText = useMemo( () => {

    if (bindOrder) {
      const { orderId, dateTime, carInfo } = bindOrder;

      return <SelectedOrderAlert orderId={orderId} dateTime={dateTime} carInfo={carInfo} />;
    }

    return <h6>{t(SelectOrderModalTranslationKey.NO_ORDER_SELECTED)}</h6>;
  },[bindOrder, t] );

  const handleSelectOrder = (items: BindOrder[]): void => {
    setBindOrder(items.length ? items[items.length - 1] : null);
  };

  const handleSaveSelectedOrder = (): void => {
    dispatch(addBindOrder(bindOrder));
    handleCloseModal();
  };

  const handleCloseModal = (): void => {
    setBindOrder(null);
    onCloseModal();
  };

  return(
    <CModal
      visible={ isOpen }
      backdrop="static"
      onClose={ handleCloseModal }
      size={'xl'}
      scrollable
    >
      <CModalHeader>
        <CModalTitle>{t(SelectOrderModalTranslationKey.TITLE)}</CModalTitle>
      </CModalHeader>
      <CModalBody >
        <CAlert color="primary" className="d-flex align-items-center">
          <CIcon icon={cilInfo} className="flex-shrink-0 me-2" width={24} height={24} />
          {selectedOrderText}
        </CAlert>
        {isLoadingOrders ? <Spinner isOverflow containerHeight={250}/> : <SelectOrdersTable
          orders={ orders }
          onSelectOrder={ handleSelectOrder }
          selectedOrder={ bindOrder ? [ bindOrder ] : [] }
        /> }
      </CModalBody>
      <CForm className="form-scroll" onSubmit={handleSubmit(handleSaveSelectedOrder)}>
        <ModalFooter
          isUpdateData={false}
          onClose={ handleCloseModal }
        />
      </CForm>
    </CModal>
  );
};

export { SelectOrderModal };
