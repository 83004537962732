import { createSlice } from '@reduxjs/toolkit';
import { DataStatus } from 'common/enums/enums';
import { TariffsCreatePaymentDto } from 'common/types/types';

import { createPayment } from './action';

type State = {
  payment: TariffsCreatePaymentDto | null,
  dataStatusCreatePayment: DataStatus,
};

const initialState: State = {
  payment: null,
  dataStatusCreatePayment: DataStatus.IDLE,
};

const tariffsSlice = createSlice({
  name: 'tariffs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // create payment
      .addCase(createPayment.pending, (state) => {
        state.dataStatusCreatePayment = DataStatus.PENDING;
      })
      .addCase(createPayment.rejected, (state) => {
        state.dataStatusCreatePayment = DataStatus.REJECTED;
        state.dataStatusCreatePayment = initialState.dataStatusCreatePayment;
      })
      .addCase(createPayment.fulfilled, (state, action) => {
        state.dataStatusCreatePayment = DataStatus.FULFILLED;
        state.payment = action.payload;
      });
  },
});

const tariffsReducer = tariffsSlice.reducer;

export { tariffsReducer };
