import { WorkDto } from 'common/types/types';
import { CarWorkTableRow } from 'components/modals/work-order-modal/type/type';

const workDtoToCarWorkTableRow = (item: WorkDto, canRemove: boolean) : CarWorkTableRow => {
  return {
    carWorkName: item.serviceWorkName ?? 'No name',
    qnt: item.workCount,
    cost: item.priceOne,
    workId: item.serviceWorkId,
    rowId: item.rowId,
    canRemove: canRemove,
    sum: item.priceTotal,
  };
};

export { workDtoToCarWorkTableRow };
