import { useCallback, useState } from 'hooks/hooks';

type PaginationStateType = {
  perPage: number,
  activePage: number,
};

type PaginationStocksStateType = Record<number, PaginationStateType>;

type UseSparePartsTableType = {
  paginationState: PaginationStocksStateType,
  onChangePerPage: (perPage: number, stockId: number) => void,
  onChangePage: (activePage: number, stockId: number) => void,
  onResetPageState: (stockId: number) => void,
};

const INIT_PAGINATION: PaginationStateType = { perPage: 10, activePage: 0 };

const useSparePartsTable = (): UseSparePartsTableType => {
  const [paginationState, setPaginationState] = useState<PaginationStocksStateType>({});

  const onChangePerPage = (perPage: number, stockId: number): void => {
    setPaginationState((prevState) => ({
      ...prevState,
      [stockId]: { activePage: 0, perPage },
    }));
  };

  const onChangePage = (activePage: number, stockId: number): void => {
    setPaginationState((prevState) => ({
      ...prevState,
      [stockId]: { ...prevState[stockId], activePage },
    }));
  };

  const onResetPageState = useCallback((stockId: number): void => {
    setPaginationState((prevState) => ({
      ...prevState,
      [stockId]: INIT_PAGINATION,
    }));
  }, []);

  return {
    paginationState,
    onChangePerPage,
    onChangePage,
    onResetPageState,
  };
};

export { useSparePartsTable };
