enum AllCarWorkModalTranslationKey {
  ALL_CAR_WORK_MODAL_TITLE = 'allCarWorkModalTitle',
  CAR_WORK_MODAL_WORK_NAME = 'carWorkModalWorkName',
  CAR_WORK_MODAL_WORK_TYPE = 'carWorkModalWorkType',
  CAR_WORK_NUM_MODAL = 'carWorkNumModal',
  CAR_WORK_PRICE_MODAL = 'carWorkPriceModal',
  CAR_WORK_QTY_MODAL = 'carWorkQtyModal',
  CAR_WORK_SUM_MODAL = 'carWorkSumModal',
  FEEDBACK_BUTTON_TITLE = 'feedbackButtonTitle',
}

export { AllCarWorkModalTranslationKey };
