import { ModalFormMode,ModalFormType } from 'common/enums/enums';
import { CarModalForm } from 'components/modals/modals';
import { useAppSelector, useState } from 'hooks/hooks';
import React, { ReactElement } from 'react';

type Props = {
  activeCarServiceId: number,
};

type UseCarModal = {
  getCarModal: (payload: Props) => ReactElement,
  toggleCarModal: (isVisible: boolean) => void,
};

const useCarModal = (): UseCarModal => {

  const {
    modalFormMode,
  } = useAppSelector( ({ modal })=> ({
    modalFormMode: modal.modalFormMode?.[ModalFormType.ORDER_MODAL] ?? ModalFormMode.READONLY_MODE,
  }));

  const [ isCarModalOpen, setIsCarModalOpen ] = useState<boolean>(false);

  const isCarModalFormReadOnly = modalFormMode === ModalFormMode.READONLY_MODE;

  const toggleCarModal = (isVisible: boolean): void => {
    setIsCarModalOpen(isVisible);
  };

  const getCarModal = ({ activeCarServiceId }: Props): ReactElement => {
    return <CarModalForm
      isReadOnly={ isCarModalFormReadOnly }
      isOpen={ isCarModalOpen }
      onCloseCarModal={ (): void => toggleCarModal(false) }
      carServiceId={ activeCarServiceId }
    />;
  };

  return { getCarModal, toggleCarModal };
};

export { useCarModal };
