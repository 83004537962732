import { noop } from 'lodash';
import React, { createContext, FC, useContext, useState } from 'react';

const initialExtraButton = {
  isHide: true,
  translateTitleKey: '',
  action: noop,
};

interface IExtraButtonState {
  isHide: boolean,
  translateTitleKey: string,
  action: (args: void) => void
}

interface IUseExtraButton {
  extraButton: IExtraButtonState,
  setExtraButton: (extraButton: IExtraButtonState) => void,
  removeExtraButton: () => void,
}

const useExtraButton = (): IUseExtraButton => {
  const [extraButton, setExtraButton] = useState<IExtraButtonState>(initialExtraButton);
  const removeExtraButton = ():void => setExtraButton(initialExtraButton);

  return {
    extraButton,
    setExtraButton,
    removeExtraButton,
  };
};

const ExtraButtonContext = createContext<IUseExtraButton | null>(null);

const useExtraButtonContext = (): IUseExtraButton => {
  const context = useContext(ExtraButtonContext);

  if (!context) {
    throw new Error('useExtraButtonContext must be used within the ExtraButtonContextType');
  }

  return context;
};

interface IProps {
  children: React.ReactNode,
}

const ExtraButtonContextProvider: FC<IProps> = ({ children }) => (
  <ExtraButtonContext.Provider
    value={ useExtraButton() }
  >
    { children }
  </ExtraButtonContext.Provider>
);

export { ExtraButtonContextProvider,useExtraButtonContext };
