import { SorterValue } from '@coreui/react-pro/dist/components/smart-table/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataStatus } from 'common/enums/enums';
import { BindOrder, ClientSpareParts, SparePartsBindToWorkOrder, WorkDto, WorkOrderDto } from 'common/types/types';
import { compareDate, compareNumber, compareString } from 'helpers/helpers';
import { isNumber } from 'lodash';

import {
  addWorkOrder,
  getWorkOrderById,
  getWorkOrdersByFilters,
  updateWorkOrder,
  updateWorkOrderClientSP,
} from './actions';

type State = {
  bindOrder: BindOrder | null,
  allWorkOrdersDataStatus: DataStatus,
  modifyWorkOrderDataStatus: DataStatus,
  workOrderDataStatus: DataStatus,
  workOrders: WorkOrderDto[],
  workOrder: WorkOrderDto | null,
  bindWork: WorkDto[],
  bindSpareParts: SparePartsBindToWorkOrder[],
  bindClientSpareParts: ClientSpareParts[],
  updatedClientSpareParts: ClientSpareParts[],
  updatedWorkId: number[],
  removedWorkId: number[],
  updatedSparePartsId: number[],
  removedSparePartsId: number[],
};

type ChangeSelectedWorkPayload = {
  value: string,
  workId: number,
};

type ChangeSelectedSparePartPayload = {
  value: string,
  sparePartId: number,
};

const initialState: State = {
  bindOrder: null,
  allWorkOrdersDataStatus: DataStatus.IDLE,
  workOrderDataStatus: DataStatus.IDLE,
  modifyWorkOrderDataStatus: DataStatus.IDLE,
  workOrder: null,
  workOrders: [],
  bindWork: [],
  bindSpareParts: [],
  bindClientSpareParts: [],
  updatedClientSpareParts: [],
  updatedWorkId: [],
  removedWorkId: [],
  updatedSparePartsId: [],
  removedSparePartsId: [],
};

const workOrderSlice = createSlice({
  name: 'workOrder',
  initialState,
  reducers: {
    addBindOrder: (state, action: PayloadAction<BindOrder | null>) => {
      state.bindOrder = action.payload;
      state.bindWork = action.payload?.work.filter((it) => !isNumber(it.orderId)) ?? [];
      state.bindClientSpareParts =
        action.payload?.clientSpareParts.filter((it) => !isNumber(it.orderId)) ?? [];
    },
    clearBindOrder: (state ) => {
      state.bindOrder = null;
      state.bindWork = [];
      state.bindSpareParts = [];
      state.bindClientSpareParts = [];
    },
    addWorkToBindOrder: (state, action: PayloadAction<WorkDto>) => {
      const { payload } = action;
      state.bindWork.push(payload);
    },
    addSparePartsToWorkOrder: (state, action: PayloadAction<SparePartsBindToWorkOrder>) => {
      const { payload } = action;
      state.bindSpareParts.push(payload);
    },
    addClientSparePartsToWorkOrder: (state, action: PayloadAction<ClientSpareParts[]>) => {
      const { payload } = action;
      state.bindClientSpareParts = payload;
    },
    changeBindQtySelectedWork: (state, { payload }: PayloadAction<ChangeSelectedWorkPayload>) => {
      const { value, workId } = payload;

      if (state.bindWork) {
        if (!state.updatedWorkId.includes(workId)) state.updatedWorkId.push(workId);
        const floatQty = parseFloat(value);

        state.bindWork =  state?.bindWork.map((it) => {
          if (it.serviceWorkId === workId) {
            const sum = (floatQty * parseFloat(it.priceOne as string)).toFixed(4) ?? '0';

            return { ...it, workCount:  value, priceTotal: sum };
          }

          return it;
        });
      }
    },
    changeBindQtySelectedSparePart: (state, { payload }: PayloadAction<ChangeSelectedSparePartPayload>) => {
      const { value, sparePartId } = payload;

      if (state.bindSpareParts) {
        if (!state.updatedSparePartsId.includes(sparePartId)) state.updatedSparePartsId.push(sparePartId);
        const floatQty = parseFloat(value);

        state.bindSpareParts =  state?.bindSpareParts.map((it) => {
          if (it.sparePartId === sparePartId) {
            const sum = (floatQty * parseFloat(it.priceOne as string)).toFixed(4) ?? '0';

            return { ...it, count:  value, priceTotal: sum };
          }

          return it;
        });
      }
    },
    changeBindPriceSelectedWork: (state, { payload }: PayloadAction<ChangeSelectedWorkPayload>) => {
      const { value, workId } = payload;

      if(state.bindWork) {
        const floatPrice = parseFloat(value);

        if (!state.updatedWorkId.includes(workId)) state.updatedWorkId.push(workId);
        state.bindWork = state?.bindWork?.map((it) => {
          if (it.serviceWorkId === workId) {
            const sum = (floatPrice * parseFloat(it.workCount as string)).toFixed(4) ?? '0';

            return { ...it, priceOne: value, priceTotal: sum };
          }

          return it;
        });
      }
    },
    changeBindPriceSelectedSparePart: (state, { payload }: PayloadAction<ChangeSelectedSparePartPayload>) => {
      const { value, sparePartId } = payload;

      if(state.bindSpareParts) {
        const floatPrice = parseFloat(value);

        if (!state.updatedSparePartsId.includes(sparePartId)) state.updatedSparePartsId.push(sparePartId);
        state.bindSpareParts = state?.bindSpareParts?.map((it) => {
          if (it.sparePartId === sparePartId) {
            const sum = (floatPrice * parseFloat(it.count as string)).toFixed(4) ?? '0';

            return { ...it, priceOne: value, priceTotal: sum };
          }

          return it;
        });
      }
    },
    removeWorkFromWorkOrder: (state, action: PayloadAction<number>) => {
      if (state.bindWork) {
        state.bindWork = state?.bindWork.filter((it) => it.serviceWorkId !== action.payload);

        if (!state.removedWorkId.includes(action.payload)) {
          state.removedWorkId.push(action.payload);
        }
      }
    },
    removeClientSparePartFromWorkOrder: (state, action: PayloadAction<ClientSpareParts>) => {
      const { sparePartId } = action.payload;
      state.bindClientSpareParts = state.bindClientSpareParts.filter((it) => it.sparePartId !== sparePartId);
      state.updatedClientSpareParts.push({ ...action.payload, orderId: null });
    },
    removeSparePartsFromWorkOrder: (state, action: PayloadAction<number>) => {
      if (state.bindSpareParts) {
        state.bindSpareParts = state?.bindSpareParts.filter((it) => it.sparePartId !== action.payload);

        if (!state.removedSparePartsId.includes(action.payload)) {
          state.removedSparePartsId.push(action.payload);
        }
      }
    },
    clearWorkOrder: (state) => {
      state.workOrder = null;
      state.removedWorkId = [];
      state.updatedWorkId = [];
      state.removedSparePartsId = [];
      state.updatedSparePartsId = [];
      state.updatedClientSpareParts = [];
    },
    updateWorkOrderList: (state, action: PayloadAction<{ carServiceId: number, rowId: number }>) => {
      const { rowId, carServiceId } = action.payload;
      state.workOrders = state.workOrders
        .map((order) => {
          if (order.carServiceId === carServiceId) {
            order.works = order.works.filter((work) => work.rowId !== rowId);
          }

          return order;
        });

    },
    sortWorkOrder: (state, action: PayloadAction<SorterValue>) => {
      const { column  } = action.payload;

      switch (column) {
      case 'sumSpareParts':
      case 'sumWorks':
      case 'requestId':
      case 'orderId':
      case 'sumFull': {
        state.workOrders = state.workOrders.sort((a,b) => {
          return compareNumber(a[column], b[column], action.payload.state as string);
        },
        );
        break;
      }
      case 'carName': {
        state.workOrders = state.workOrders.sort((a,b) => {
          return compareString(
            `${a.car.carBrandName} ${a.car.carModelName}`,
            `${b.car.carBrandName} ${b.car.carModelName}`,
            action.payload.state as string);
        });
        break;
      }
      case 'dateTime': {
        state.workOrders = state.workOrders.sort((a,b) => {
          return compareDate(new Date(a.dateStartPlane), new Date(b.dateStartPlane), action.payload.state as string);
        });
        break;
      }
      case 'status': {
        state.workOrders = state.workOrders.sort((a,b) => {
          return compareString(
            a.orderStatus.name,
            b.orderStatus.name,
            action.payload.state as string);
        });
        break;
      }
      }
    },
  },
  extraReducers: (builder) =>  {
    builder

      //get work orders by filter
      .addCase(getWorkOrdersByFilters.pending, (state) => {
        state.allWorkOrdersDataStatus = DataStatus.PENDING;
      })
      .addCase(getWorkOrdersByFilters.rejected, (state) => {
        state.allWorkOrdersDataStatus = DataStatus.REJECTED;
        state.workOrders = [];
      })
      .addCase(getWorkOrdersByFilters.fulfilled, (state, { payload }) => {
        state.allWorkOrdersDataStatus = DataStatus.FULFILLED;
        state.workOrders = payload;
      })

      //add work order
      .addCase(addWorkOrder.pending, (state) => {
        state.modifyWorkOrderDataStatus = DataStatus.PENDING;
      })
      .addCase(addWorkOrder.rejected, (state) => {
        state.modifyWorkOrderDataStatus = DataStatus.REJECTED;
      })
      .addCase(addWorkOrder.fulfilled, ( state ) => {
        state.modifyWorkOrderDataStatus = DataStatus.FULFILLED;
      })

      //update work order
      .addCase(updateWorkOrder.pending, (state) => {
        state.modifyWorkOrderDataStatus = DataStatus.PENDING;
      })
      .addCase(updateWorkOrder.rejected, (state) => {
        state.modifyWorkOrderDataStatus = DataStatus.REJECTED;
      })
      .addCase(updateWorkOrder.fulfilled, (state) => {
        state.modifyWorkOrderDataStatus = DataStatus.FULFILLED;
      })

      //get work order by ID
      .addCase(getWorkOrderById.pending, (state) => {
        state.workOrderDataStatus = DataStatus.PENDING;
      })
      .addCase(getWorkOrderById.rejected, (state) => {
        state.workOrderDataStatus = DataStatus.REJECTED;
      })
      .addCase(getWorkOrderById.fulfilled, (state, { payload }) => {
        state.workOrderDataStatus = DataStatus.FULFILLED;
        state.workOrder = payload;
        state.bindWork = payload.works ?? [];
        state.bindSpareParts = payload.spareParts ?? [];
        state.bindClientSpareParts = payload.sparePartsFromClient ?? [];
      })
      .addCase(updateWorkOrderClientSP.fulfilled, (state, { payload }) => {
        state.workOrders.forEach((it) => {
          if (it.orderId === payload.workOrder.orderId) {
            it.sparePartsFromClient = payload.clientSpareParts;
          }
        });

        state.bindClientSpareParts = payload.clientSpareParts;
      });
  },
});

const workOrderReducer = workOrderSlice.reducer;
const {
  addBindOrder,
  clearBindOrder,
  removeWorkFromWorkOrder,
  clearWorkOrder,
  addWorkToBindOrder,
  addClientSparePartsToWorkOrder,
  changeBindQtySelectedWork,
  changeBindPriceSelectedWork,
  changeBindPriceSelectedSparePart,
  changeBindQtySelectedSparePart,
  removeSparePartsFromWorkOrder,
  updateWorkOrderList,
  addSparePartsToWorkOrder,
  removeClientSparePartFromWorkOrder,
  sortWorkOrder,
} = workOrderSlice.actions;

export {
  addBindOrder,
  addClientSparePartsToWorkOrder,
  addSparePartsToWorkOrder,
  addWorkToBindOrder,
  changeBindPriceSelectedSparePart,
  changeBindPriceSelectedWork,
  changeBindQtySelectedSparePart,
  changeBindQtySelectedWork,
  clearBindOrder,
  clearWorkOrder,
  removeClientSparePartFromWorkOrder,
  removeSparePartsFromWorkOrder,
  removeWorkFromWorkOrder,
  sortWorkOrder,
  updateWorkOrderList,
  workOrderReducer,
};
