const getShortUsername = (username: string): string => {
  const array = username.split(' ');

  if (array.length === 1) {
    return username.slice(0, 2).toUpperCase();
  }

  return array.slice(0, 2).map( (item) => item[0]?.toUpperCase()).join('');
};

export { getShortUsername };
