enum DangerAlertModalTranslationKey {
  CANCEL_WORK_ORDER_TEXT = 'cancelWorkOrderText',
  CANCEL_BUTTON_TEXT = 'cancelButtonText',
  CANCEL_WORK_ORDER_BUTTON_TEXT = 'cancelWorkOrderButtonText',
  DELETE_PHONE_TEXT = 'deletePhoneText',
  DELETE_PHONE_BUTTON_TEXT = 'deletePhoneButtonText',
  CANCEL_ORDER_TEXT = 'cancelOrderText',
  CANCEL_ORDER_BUTTON_TEXT = 'cancelOrderButtonText',
}

export { DangerAlertModalTranslationKey };
