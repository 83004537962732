import { AppTranslationKey, SelectOrderModalTranslationKey } from 'common/enums/enums';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  orderId: number,
  dateTime: string,
  carInfo: string[],
};

const SelectedOrderAlert: FC<Props> = ({ orderId, dateTime, carInfo }) => {
  const { t } = useTranslation(AppTranslationKey.SELECT_ORDER_MODAL);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex">
        <h6 style={{ minWidth: 70 }}>{t(SelectOrderModalTranslationKey.ALERT_ORDER_TITLE)}</h6>
        <h6>{t(SelectOrderModalTranslationKey.SELECTED_ORDER_MSG, { orderId : orderId, date: dateTime })}</h6>
      </div>
      <div className="d-flex">
        <h6 style={{ minWidth: 70 }}>{t(SelectOrderModalTranslationKey.ALERT_AUTO_TITLE)}</h6>
        <h6>{ carInfo?.join(' ') ?? '' }</h6>
      </div>
    </div>
  );
};

export { SelectedOrderAlert };
