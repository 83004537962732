import { CButton, CForm, CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react-pro';
import {
  AllCarWorkModalTranslationKey,
  AppTranslationKey,
  CarWorkTranslationKey,
  DataStatus,
} from 'common/enums/enums';
import { CarWorkGetAllResponse } from 'common/types/types';
import { ModalFooter } from 'components/modals/components/components';
import { useAppSelector ,useState } from 'hooks/hooks';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FeedBackModalForm } from '../feed-back-modal/feed-back-modal-form';
import { CarWorkTable } from './components/components';
import styles from './styles.module.scss';

type Props = {
  isOpen: boolean;
  tableData: CarWorkGetAllResponse[],
  isLoading: boolean,
  onSubmit: () => void;
  onCloseModal: () => void;
  onSelectCarWork: (item: CarWorkGetAllResponse) => void;
};

const CarWorkModal: FC<Props> = (
  { isOpen, tableData, onSubmit, onSelectCarWork, isLoading, onCloseModal },
) => {

  const { t } = useTranslation(AppTranslationKey.CAR_WORK);
  const { handleSubmit } = useForm();
  const { updateFavCarWorkListDataStatus } = useAppSelector((state) => state.carWork);

  const [ feedBackModalIsOpen, setFeedBackModalIsOpen ] = useState<boolean>(false);

  const isUpdating = updateFavCarWorkListDataStatus === DataStatus.PENDING;

  const handleOpenFeedBackModal = (): void => {
    setFeedBackModalIsOpen(true);
  };

  const handleCloseFeedBackModal = (): void => {
    setFeedBackModalIsOpen(false);
  };

  return (
    <CModal
      visible={isOpen}
      backdrop="static"
      onClose={onCloseModal}
      size="lg"
      scrollable
    >

      <CModalHeader>
        <CModalTitle>
          {t(`${CarWorkTranslationKey.ALL_CAR_WORK_MODAL}.${AllCarWorkModalTranslationKey.ALL_CAR_WORK_MODAL_TITLE}`)}
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CarWorkTable onSelectCarWork={onSelectCarWork} isLoading={isLoading} carWork={tableData} />
      </CModalBody>
      <CForm
        className="form-scroll"
        onSubmit={handleSubmit(onSubmit)}
      >
        <ModalFooter
          isReadOnly={false}
          isUpdateData={isUpdating}
          onClose={onCloseModal}
          desc={
            <CButton color="link" className={ styles.feedbackButton } onClick={ handleOpenFeedBackModal }>
              {t(`${CarWorkTranslationKey.ALL_CAR_WORK_MODAL}.${AllCarWorkModalTranslationKey.FEEDBACK_BUTTON_TITLE}`)}
            </CButton>
          }
        />
      </CForm>
      <FeedBackModalForm
        isOpen={feedBackModalIsOpen}
        isReadOnly={ false }
        isUpdating={ false }
        onCloseModal={handleCloseFeedBackModal}
        defaultThemeId={ 4 }
      />
    </CModal>
  );
};

export { CarWorkModal };
