import { useAppSelector, useState } from 'hooks/hooks';

type Props = {
  isVisible: boolean,
  onPermissionAction: (onAction: () => void, exceptionTariff?: number) => () => void,
  onHide: () => void,
};

const usePermissionToast = (): Props => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { isActiveUser, tariffId } = useAppSelector(({ auth }) => ({
    isActiveUser: auth.currentUser?.tariff?.isActive,
    tariffId: auth.currentUser?.tariff.tariffId,
  }));

  const onPermissionAction = (onAction: () => void, exceptionTariff?: number) => (): void => {
    if (isActiveUser || exceptionTariff === tariffId) {
      onAction();
    } else {
      setIsVisible(true);
    }
  };

  const onHide = (): void => (setIsVisible(false));

  return {
    isVisible,
    onPermissionAction,
    onHide,
  };
};

export { usePermissionToast };
