enum StorageTranslationKey {
  ADD_STORAGE = 'addStorage',
  NO_DATA_TITLE = 'noDataTitle',
  NO_DATA_TEXT = 'noDataText',
  ADD_STORAGE_TITLE = 'addStorageTitle',
  STORAGE_FORM_FIELD_REQUIRED = 'storageFormFieldRequired',
  STORAGE_FORM_NAME_LABEL = 'storageNameFormLabel',
  STORAGE_FORM_STATUS_LABEL = 'storageFormStatusLabel',
  STORAGE_FORM_ADDRESS_LABEL = 'storageFormAddressLabel',
  STORAGE_FORM_TOO_MANY_CHAR = 'storageFormTooManyChar',
  STORAGE_FORM_INFO_LABEL = 'storageFormInfoLabel',
  STORAGE_FORM_INFO_TEXT = 'storageFormInfoText',
  BACK_CAPTION_BUTTON = 'backCaptionButton',
  SAVE_CAPTION_BUTTON = 'saveCaptionButton',
  ADD_STORAGE_SUCCESS = 'addStorageSuccess',
  UPDATE_STORAGE_SUCCESS = 'updateStorageSuccess',
  EDIT_STORAGE_TITLE = 'editStorage',
}

export { StorageTranslationKey };
