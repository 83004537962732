enum ActionType {
  GET_STATUSES = 'task/get-statuses',
  ADD_TASK = 'task/add-task',
  GET_TASK_BY_ID = 'task/get-task-by-id',
  UPDATE_TASK = 'task/update-task',
  ADD_WORK_IN_TASK = 'task/add-work-in-task',
  REMOVE_WORK_FROM_TASK = 'task/remove-work-from-task',
  EDIT_WORK_IN_TASK = 'task/edit-work-in-task',
  UPDATE_CLIENT_SP_IN_TASK = 'task/update-client-sp-in-task',
  REMOVE_CLIENT_SP_FROM_TASK = 'task/remove-client-sp-from-task',
}

export { ActionType };
