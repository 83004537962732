import { AppTranslationKey, EmployeesProfilesKey, Pages } from 'common/enums/enums';
import { WithCarServicesTabs } from 'common/types/types';
import { NoDataWithAction, usePermissionToastContext } from 'components/common/common';
import { Tariffs } from 'constants/tariffs';
import { useExtraButtonContext } from 'contexts/extra-button-context';
import { withCarServicesTabs } from 'hoc/hoc';
import { useEffect } from 'hooks/hooks';
import { t as tt } from 'i18next';
import noop from 'lodash/noop';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const EmployeesProfiles: FC<WithCarServicesTabs> = () => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEES_PROFILES);
  const { setExtraButton, removeExtraButton } = useExtraButtonContext();
  const { onPermissionAction } = usePermissionToastContext();

  useEffect(() => {
    setExtraButton({
      isHide: false,
      translateTitleKey: `${AppTranslationKey.EMPLOYEES_PROFILES}:${EmployeesProfilesKey.ADD_BUTTON_TITLE}`,
      action: onPermissionAction(noop, Tariffs.NO_TARIFF),
    });

    return () => {
      removeExtraButton();
    };
  }, []);

  return (
    <NoDataWithAction
      title={t(EmployeesProfilesKey.NO_UNIT_TITLE)}
      message={t(EmployeesProfilesKey.NO_UNIT_TEXT)}
      actionName={t(EmployeesProfilesKey.ADD_BUTTON_TITLE)}
      callback={ onPermissionAction(noop, Tariffs.NO_TARIFF) }
    />
  );
};

export const StorageProfilesWithCarServicesTabs = withCarServicesTabs(
  EmployeesProfiles,
  false,
  Pages.EMPLOYEES,
  () => tt('mainMenu:employeesProfiles') ?? '',
);
