import { CForm, CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react-pro';
import { AppTranslationKey, DataStatus, ModalFormType, NotificationType } from 'common/enums/enums';
import { CarDto, CarModalFormData, InfoSearchDataDto, ModifyCarDataRequestDto } from 'common/types/types';
import { Spinner } from 'components/common/common';
import { useAppDispatch, useAppSelector, useEffect } from 'hooks/hooks';
import { isNull } from 'lodash';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { appActions, carActions } from 'store/actions';
import { clearSearchCarInfo, manualAddCar, setCurrentCar } from 'store/car/reducer';
import { disableReadOnlyModalMode } from 'store/modal/reducer';

import { ModalFooter } from '../components/components';
import { DEFAULT_CAR_MODAL_PAYLOAD } from './common';
import {
  BodyTypeField,
  BrandField, CapacityField,
  CarInfoField,
  ColorField,
  CreateYearField,
  MileageKmField,
  ModelField,
  RegNumField,
  VinField,
} from './components/components';

type Props = {
  isOpen: boolean;
  isReadOnly: boolean;
  onCloseCarModal: () => void;
  carServiceId: number,
  car?: CarDto | null,
};

const CarModalForm: FC<Props> = ({
  isOpen,
  isReadOnly,
  onCloseCarModal,
  carServiceId,
  car,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(AppTranslationKey.CAR_MODAL);

  const {
    brands,
    models,
    bodyTypes,
    colors,
    getCarByIdDataStatus,
    modifyCarDataStatus,
    searchedInfoCar,
  } = useAppSelector(({ car, dictionary }) => ({
    brands: dictionary.data?.carBrands || [],
    models: car.models,
    searchedInfoCar: car.searchedInfoCar,
    bodyTypes: dictionary.data?.bodyTypes || [],
    colors: dictionary.data?.carColors || [],
    getCarByIdDataStatus: car.getCarByIdDataStatus,
    modifyCarDataStatus: car.modifyCarDataStatus,
  }));

  const isLoading = getCarByIdDataStatus === DataStatus.PENDING;
  const isUpdating = modifyCarDataStatus === DataStatus.PENDING;

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<CarModalFormData>({
    defaultValues: DEFAULT_CAR_MODAL_PAYLOAD,
  });

  const watchBrand = watch('carBrandId');
  const watchCarInfo = watch('carInfo');

  const handleClose = (): void => {
    reset(DEFAULT_CAR_MODAL_PAYLOAD);
    onCloseCarModal();
  };

  const onEditButtonClick = (): void => {
    dispatch(disableReadOnlyModalMode(ModalFormType.CAR_MODAL));
  };

  useEffect(() => {

    if (car) {
      reset({
        carId: car.carId,
        carBrandId: car.carBrandId,
        carModelId: car.carModelId,
        carBodyTypeId: car.carBodyTypeId ?? -1,
        carColorId: car.carColorId ?? -1,
        carRegNum: car.carRegNum,
        createYear: car.createYear,
        carInfo: car.carInfo,
        mileageKm: car.mileageKm,
        vinCode: car.vinCode,
        capacity: car.capacity,
      });
    } else {
      reset(DEFAULT_CAR_MODAL_PAYLOAD);
    }
  }, [ car, reset ]);

  const handleCarModify = (data: CarModalFormData): void => {

    const car: ModifyCarDataRequestDto = {
      vinCode: data.vinCode,
      carBrandId: data.carBrandId < 0 ? undefined : +data.carBrandId,
      carModelId: data.carModelId < 0 ? undefined : +data.carModelId,
      carBodyTypeId: data.carBodyTypeId && (data.carBodyTypeId < 0 ? null : +data.carBodyTypeId),
      carColorId: data.carColorId && (data.carColorId < 0 ? null : +data.carColorId),
      carRegNum: data.carRegNum,
      createYear: data.createYear as number || 0,
      mileageKm: data.mileageKm as number || 0,
      carInfo: data.carInfo,
      capacity: data.capacity as number || 0,
      carServiceId,
    };

    if (data.carId) {
      dispatch(carActions.updateCarById({ ...car, carId: data.carId }))
        .unwrap()
        .then(() => {
          dispatch(appActions.notify({ type: NotificationType.SUCCESS, message: t('successModifyCarMes') }));
          handleClose();
        });
    } else {
      dispatch(carActions.addCar(car))
        .unwrap()
        .then((car) => {
          dispatch(manualAddCar(car));
          dispatch(setCurrentCar(car.carId));
          dispatch(appActions.notify({ type: NotificationType.SUCCESS, message: t('successCreateCarMes') }));
          handleClose();
        });
    }
  };

  useEffect(() => {

    if (watchBrand) {
      dispatch(carActions.getModels({ car_brand_id: watchBrand }))
        .unwrap()
        .then((it) => {
          if(isNull(searchedInfoCar)) {
            setValue('carModelId', car?.carModelId ?? -1);
          } else {
            const modelId = it.find((model) =>
              model.name.toUpperCase() === (searchedInfoCar as InfoSearchDataDto).model.toUpperCase())?.id ?? -1;
            setValue('carModelId', modelId);
          }
        })
        .then(() => dispatch(clearSearchCarInfo()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ watchBrand ]);

  return (
    <CModal
      onClose={handleClose}
      visible={isOpen}
      backdrop="static"
      size="lg"
      scrollable
    >
      <CModalHeader>
        <CModalTitle>{t('modalTitle')}  #{car?.carId}</CModalTitle>
      </CModalHeader>
      {isLoading ?
        <Spinner isOverflow containerHeight={150}/> :
        <CForm
          onSubmit={handleSubmit(handleCarModify)}
          className="form-scroll"
        >
          <CModalBody >
            <RegNumField
              getValues={getValues}
              disabled={isReadOnly}
              register={register}
              errors={errors}
              setError={setError}
              setValue={setValue}
              clearErrors={clearErrors}
            />
            <VinField disabled={isReadOnly} register={register}/>
            <BrandField disabled={isReadOnly} register={register} brands={brands} errors={errors}/>
            <ModelField disabled={isReadOnly} register={register} models={models} errors={errors}/>
            <BodyTypeField disabled={isReadOnly} register={register} bodyTypes={bodyTypes}/>
            <ColorField disabled={isReadOnly} register={register} colors={colors}/>
            <CreateYearField disabled={isReadOnly} register={register} setValue={setValue}/>
            <CapacityField disabled={isReadOnly} register={register} setValue={setValue}/>
            <MileageKmField disabled={isReadOnly} register={register} setValue={setValue}/>
            <CarInfoField disabled={isReadOnly} register={register} value={watchCarInfo}/>
          </CModalBody>
          <ModalFooter
            isReadOnly={isReadOnly}
            isUpdateData={isUpdating}
            onEditButtonClick={onEditButtonClick}
            onClose={handleClose}
          />
        </CForm>}
    </CModal>
  );
};

export { CarModalForm };
