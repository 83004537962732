import { CButton, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  isVisible: boolean,
  onClose: () => void,
  onConfirm: () => void,
  onAllConfirm: () => void,
};

const ConfirmFillFormModal: FC<Props> = ({ onAllConfirm, onConfirm, isVisible, onClose }) => {

  const { t } = useTranslation(AppTranslationKey.CAR_MODAL);

  return (
    <CModal
      alignment={'center'}
      visible={isVisible}
      onClose={onClose}
      backdrop="static"
    >
      <CModalHeader>
        <b>{t('fillFormModalHeader')}</b>
      </CModalHeader>
      <CModalBody style={{ marginBottom: 30 }}>
        <b>
          {t('fillFormModalBody')}
        </b>
      </CModalBody>
      <CModalFooter>
        <CButton color={'dark'} onClick={onClose} variant={'ghost'}>
          {t('fillFormBtnClose')}
        </CButton>
        <CButton color={'primary'} variant={'outline'} onClick={onConfirm}>
          {t('fillFormBtnConfirm')}
        </CButton>
        <CButton color={'primary'} variant={'outline'} onClick={onAllConfirm}>
          {t('fillFormBtnConfirmAll')}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export { ConfirmFillFormModal };
