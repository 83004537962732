import { CButton, CCol, CListGroup } from '@coreui/react-pro';
import { AppRoute } from 'common/enums/app/app-route.enum';
import { PersonalRoute } from 'common/enums/app/personal-route.enum';
import { AppTranslationKey, StorageDetailsTranslationKey } from 'common/enums/enums';
import { StorageDto } from 'common/types/types';
import { ProfileDetailRow, usePermissionToastContext } from 'components/common/common';
import { generateDynamicPath } from 'helpers/app/generate-dynamic-path.helper';
import { useAppSelector, useNavigate } from 'hooks/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  storage: StorageDto | null,
};

const StorageDetails: FC<Props> = ({ storage }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(AppTranslationKey.STOCK_DETAILS);
  const { storageStatus } = useAppSelector(({ dictionary }) => ({
    storageStatus: dictionary.data?.stockStatuses || [],
  }));
  const { onPermissionAction } = usePermissionToastContext();

  const linkToEditCarService = (): void => {
    navigate(`${AppRoute.PERSONAL}${PersonalRoute.ROOT}${
      generateDynamicPath(PersonalRoute.EDIT_STORAGE_$STORAGE_ID,
        { storageId: storage?.stockId ?? '' })}`);
  };

  const getStatusById = (id: number): string => {
    const statusText = storageStatus.find((item) => item.id === id)?.name ?? '';

    return  statusText.charAt(0).toUpperCase() + statusText.slice(1);
  };

  return (
    <CListGroup flush>
      <ProfileDetailRow
        caption={t(StorageDetailsTranslationKey.STORAGE_DETAILS_NAME_FIELD)}
        text={storage?.stockName ?? ''}/>
      <ProfileDetailRow
        caption={t(StorageDetailsTranslationKey.STORAGE_DETAILS_STATUS_FIELD)}
        text={getStatusById(storage?.stockStatusId ?? 0)}/>
      <ProfileDetailRow
        caption={t(StorageDetailsTranslationKey.STORAGE_DETAILS_ADDRESS_FIELD)}
        text={storage?.address ?? ''}/>
      <ProfileDetailRow
        caption={t(StorageDetailsTranslationKey.STORAGE_DETAILS_INFO_FIELD)}
        text={storage?.stockInfo ?? ''}/>
      <CCol className="align-self-end pt-3">
        <CButton variant="outline" onClick={ onPermissionAction(linkToEditCarService) }>
          {t(StorageDetailsTranslationKey.STORAGE_DETAILS_EDIT_BUTTON)}
        </CButton>
      </CCol>

    </CListGroup>
  );
};

export { StorageDetails };
