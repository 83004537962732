import { CFormSwitch, CSmartTable } from '@coreui/react-pro';
import { AppTranslationKey, SelectSparePartsTableTranslationKey } from 'common/enums/enums';
import { SparePartsBindToWorkOrder, UserSpareParts } from 'common/types/types';
import { NoData } from 'components/common/common';
import { getItemPerPageOptions } from 'helpers/helpers';
import { useMemo } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getSelectSparePartsTableColumns } from './helpers/helpers';

type Props = {
  spareParts: UserSpareParts[],
  isLoading: boolean,
  onSelectSpareParts: (item: UserSpareParts) => void,
  selectedSpareParts: SparePartsBindToWorkOrder[],
};

const SelectSparePartsTable: FC<Props> = (
  { spareParts,
    onSelectSpareParts,
    selectedSpareParts,
    isLoading,
  },
) => {

  const { t } = useTranslation([AppTranslationKey.SELECT_SPARE_PARTS_TABLE, AppTranslationKey.COMMON]);
  const columns = useMemo(() => getSelectSparePartsTableColumns(), []);
  const itemsPerPage: number[] = useMemo( () => getItemPerPageOptions(spareParts.length), [ spareParts ] );

  if (spareParts.length === 0) {
    return <NoData
      title={t(SelectSparePartsTableTranslationKey.NO_SPARE_PARTS_WORK_TITLE)}
      message={''}
    />;
  }

  return (
    <CSmartTable
      columns={columns}
      columnSorter
      columnFilter
      loading={isLoading}
      sorterValue={{ column: 'sparePartsName', state: 'asc' }}
      items={spareParts}
      noItemsLabel={
        <NoData
          title={t('noItemsFoundTitle', { ns: AppTranslationKey.COMMON })}
          message={t('noItemsFoundMsg', { ns: AppTranslationKey.COMMON })}
        />
      }
      itemsPerPageSelect
      itemsPerPageOptions={itemsPerPage}
      itemsPerPageLabel={t('itemPerPageLabel', { ns: AppTranslationKey.COMMON }) ?? 'Елементів на сторінці'}
      pagination
      scopedColumns={{
        isSelect: (item: UserSpareParts) => (
          <td>
            <CFormSwitch
              style={{ cursor: 'pointer' }}
              type="checkbox"
              checked={
                selectedSpareParts.some((it) => it.sparePartId === item.sparePartsId)
              }
              onChange={(): void => {
                onSelectSpareParts({
                  ...item,
                  rowId: selectedSpareParts.find((it) => it.sparePartId === item.sparePartsId)?.rowId ?? null,
                });
              }}
            />
          </td>
        ),
      }}
      tableHeadProps={{
        color: 'light',
      }}
      tableProps={{
        striped: true,
        hover: true,
      }}
    />
  );
};

export { SelectSparePartsTable };
