import { appReducer as app, IAppState } from './app/reducer';
import { authReducer as auth } from './auth/reducer';
import { carReducer as car } from './car/reducer';
import { carServicesReducer as carServices } from './car-service/reducer';
import { carWorkReducer as carWork } from './car-work/reducer';
import { confidentialTextReducer as confidentialText } from './confidential-text/reducer';
import { dictionaryReducer as dictionary } from './dictionary/reducer';
import { documentationReducer as documentation } from './documentation/reducer';
import { employeeReducer as employees } from './employees/reducer';
import { sendErrorReducer as sendError } from './error-handler/reducer';
import { feedBackReducer as feedBack } from './feed-back/reducer';
import { modalReducer as modal } from './modal/reducer';
import { sparePartsReducer as spareParts } from './spare-parts/reducer';
import { stockReducer as stock } from './storage/reducer';
import { supplierReducer as supplier } from './supplier/reducer';
import { tariffsReducer as tariff } from './tariffs/reducer';
import { taskReducer as task } from './task/reducer';
import { userReducer as user } from './user/reducer';
import { widgetReducer as widget } from './widget/reducer';
import { workOrderReducer as workOrder } from './work-order/reducer';

export interface IRootState {
  app: IAppState
}

const rootReducer = {
  auth,
  user,
  app,
  carServices,
  dictionary,
  employees,
  task,
  car,
  widget,
  modal,
  sendError,
  stock,
  supplier,
  carWork,
  spareParts,
  feedBack,
  tariff,
  workOrder,
  documentation,
  confidentialText,
};

export { rootReducer };
