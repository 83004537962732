import { createSlice } from '@reduxjs/toolkit';
import { DataStatus } from 'common/enums/enums';
import { Employee } from 'common/types/types';

import { getEmployees } from './actions';

type State = {
  employees: Employee[],
  dataStatusEmployees: DataStatus,
};

const initialState: State = {
  employees: [],
  dataStatusEmployees: DataStatus.IDLE,
};

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get employees
      .addCase(getEmployees.pending, (state) => {
        state.dataStatusEmployees = DataStatus.PENDING;
      })
      .addCase(getEmployees.rejected, (state) => {
        state.dataStatusEmployees = DataStatus.REJECTED;
        state.employees = [];
      })
      .addCase(getEmployees.fulfilled, (state, { payload }) => {
        state.dataStatusEmployees = DataStatus.FULFILLED;
        state.employees = payload;
      });
  },
});

const employeeReducer = employeesSlice.reducer;
export { employeeReducer };
