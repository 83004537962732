import { CButton } from '@coreui/react-pro';
import { NoData } from 'components/common/common';
import React, { FC, ReactElement } from 'react';

type Props = {
  title: string,
  message: string,
  callback: () => void,
  actionName: string,
};

const NoDataWithAction: FC<Props> = ({ title, message, actionName, callback }) => {

  const renderMessage = (): ReactElement => {
    const mes = message.split(actionName);

    return (
      <span>
        {mes[0]?.slice(0, -1)}
        <CButton className="mx-1" onClick={callback} size="sm">{actionName}</CButton>
        {mes[1]?.slice(1)}
      </span>
    );
  };

  return (
    <NoData style={{ margin: 1 }} title={title} message={renderMessage()}/>
  );
};

export { NoDataWithAction };
