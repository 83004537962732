import { CCol, CFormLabel, CRow } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { SparePartForm } from 'common/types/types';
import { TextArea } from 'components/textarea/text-area';
import React, { FC } from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  register: UseFormRegister<SparePartForm>,
  disabled: boolean,
  value: string,
};

const AddInfoField: FC<Props> = ({ register, disabled, value }) => {
  const { t } = useTranslation(AppTranslationKey.SPARE_PARTS);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="sparePartsInfo" className="col-sm-3 col-form-label">
        { t('addInfo') }
      </CFormLabel>
      <CCol sm={9}>
        <TextArea<SparePartForm>
          id="sparePartsInfo"
          register={ register }
          limitSymbols={ 1024 }
          rows={3}
          nameFiled="sparePartsInfo"
          value={ value }
          disabled={ disabled }
        />
      </CCol>
    </CRow>
  );
};

export { AddInfoField };
