import { StorageModifyRequest } from 'common/types/types';

const DEFAULT_STORAGE_FORM_PAYLOAD: StorageModifyRequest = {
  addressId: -1,
  address: '',
  stockInfo: '',
  stockName: '',
  stockStatusId: 1,
};

export { DEFAULT_STORAGE_FORM_PAYLOAD };
