import { ENV } from 'common/enums/enums';
import { toBase64 } from 'helpers/helpers';
import { useCallback } from 'react';

type UseStaticType = {
  sendStat: () => void;
};

const useStatistics = (): UseStaticType => {

  const getDevice = useCallback((): boolean => {
    const isMobile = window.matchMedia || window.matchMedia;

    if (isMobile) {
      const match_mobile = isMobile('(pointer:coarse)');

      return match_mobile.matches;
    }

    return false;
  }, []);

  const getOs = useCallback((): string => {
    const OS_MAP = {
      'Windows NT 10.0': 'Windows 10',
      'Windows NT 6.3': 'Windows 8.1',
      'Windows NT 6.2': 'Windows 8',
      'Windows NT 6.1': 'Windows 7',
      'Windows NT 6.0': 'Windows Vista',
      'Windows NT 5.1': 'Windows XP',
      'Windows NT 5.0': 'Windows 2000',
      'Mac': 'Mac/iOS',
      'X11': 'UNIX',
      'Linux': 'Linux',
    };
    const entriesOfMap = Object.entries(OS_MAP);

    for (let index = 0; index < entriesOfMap.length; index++) {
      const item = entriesOfMap[index];

      if (window.navigator.userAgent.indexOf(item[0]) !== -1) {
        return item[1];
      }
    }

    return 'unknown';
  },[]);

  const sendStat = useCallback((): void => {

    const info = {
      url: window.location.href,
      ip: '',
      deviceInfo: navigator.userAgent,
      isMobile: getDevice(),
      platform: navigator.platform,
      os: getOs(),
      // brands: navigator.userAgent ? navigator.userAgent : [],
      brands: [],
    };

    const request = new Request(ENV.IPIFY_URL);
    const formData = new FormData();
    fetch(request).then((response) => response.json()).then((data) => {
      info.ip = data.ip;
      formData.append('st', toBase64(JSON.stringify(info)));
    }).finally(() => fetch(ENV.SITE_STAT_BASE64_URL, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: formData,
    }));

  },[getOs, getDevice]);

  return { sendStat };

};

export { useStatistics };
