import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkConfig, TariffsCreatePaymentDto, TariffsCreatePaymentRequestDto } from 'common/types/types';

import { ActionType } from './common';

const createPayment = createAsyncThunk<
  TariffsCreatePaymentDto,
  TariffsCreatePaymentRequestDto,
  AsyncThunkConfig
>(ActionType.CREATE_PAYMENT, async (payload, { extra }) => {
  const { tariffsApi } = extra;

  return tariffsApi.createPayment(payload);
});

export { createPayment };
