import {
  CButton,
  CCardBody,
  CCardFooter,
  CCol,
  CForm,
  CFormInput,
  CFormSelect,
  CLoadingButton,
  CRow,
} from '@coreui/react-pro';
import { AppTranslationKey, DataStatus } from 'common/enums/enums';
import { SupplierAddRequestDto, SupplierDto } from 'common/types/types';
import { TextArea } from 'components/textarea/text-area';
import { useAppSelector, useEffect, useNavigate } from 'hooks/hooks';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DEFAULT_EDIT_SUPPLIER_PAYLOAD } from './common';

type Props = {
  onSubmit: (supplier: SupplierAddRequestDto) => void,
  supplier?: SupplierDto,
};

const SupplierForm: FC<Props> = ({ onSubmit, supplier }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(AppTranslationKey.SUPPLIERS);
  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SupplierAddRequestDto>({
    defaultValues: DEFAULT_EDIT_SUPPLIER_PAYLOAD,
  });
  const { statuses, dataStatusCreate } = useAppSelector(({ supplier, dictionary }) => ({
    statuses: dictionary.data?.counterpartyStatuses || [],
    dataStatusCreate: supplier.dataStatusCreate,
  }));

  const watchInfo = watch('counterpartyInfo');

  useEffect(() => {
    if(supplier) {
      reset({
        counterpartyStatusId: supplier.counterpartyStatus?.id,
        counterpartyName: supplier.counterpartyName,
        address: supplier.address,
        counterpartyInfo: supplier.counterpartyInfo,
      });
    }
  }, [reset, supplier]);

  return (
    <CForm onSubmit={ handleSubmit(onSubmit) }>
      <CCardBody>
        <CRow xs={{ gutterY: 3 }}>
          <CCol md={6}>
            <CFormInput
              { ...register(
                'counterpartyName',
                { required: t('requiredFormField') ?? 'This field is required' },
              ) }
              type="text"
              label={ `${t('fieldNameLabel')}*` }
              invalid={Boolean(errors.counterpartyName)}
              feedbackInvalid={errors.counterpartyName?.message}
            />
          </CCol>
          <CCol md={6}>
            <CFormInput
              { ...register('address') }
              type="text"
              label={ t('fieldAddressLabel') }
              invalid={Boolean(errors.address)}
              feedbackInvalid={errors.address?.message}
            />
          </CCol>
          <CCol md={6}>
            <CFormSelect
              { ...register('counterpartyStatusId') }
              label={ t('fieldStatusLabel') }
            >
              {
                statuses.map((status) => (
                  <option key={status.id} value={status.id}>
                    {status.name}
                  </option>
                ))
              }
            </CFormSelect>
          </CCol>
          <CCol md={6}>
            <TextArea<SupplierAddRequestDto>
              register={ register }
              limitSymbols={ 1024 }
              label={ t('fieldInfoLabel') }
              nameFiled="counterpartyInfo"
              value={ watchInfo }
              rows={ 3 }
            />
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <CRow>
          <CCol style={{ display: 'flex', justifyContent: 'flex-end' }} md={12}>
            <CButton
              className="me-2"
              color="dark"
              variant="ghost"
              onClick={ (): void => navigate(-1) }
            >
              {t('backCaptionButton')}
            </CButton>
            <CLoadingButton
              color="primary"
              type="submit"
              disabledOnLoading
              loading={ dataStatusCreate === DataStatus.PENDING }
            >
              {t('saveCaptionButton')}
            </CLoadingButton>
          </CCol>
        </CRow>
      </CCardFooter>
    </CForm>
  );
};

export { SupplierForm };
