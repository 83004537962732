import { CCol, CFormSelect, CRow } from '@coreui/react-pro';
import { FeedBackSendRequestType } from 'common/types/types';
import { useAppDispatch, useAppSelector, useEffect } from 'hooks/hooks';
import React, { FC } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { feedBackActions } from 'store/actions';

type Props = {
  register: UseFormRegister<FeedBackSendRequestType>,
  setValue: UseFormSetValue<FeedBackSendRequestType>,
  disabled?: boolean,
  defaultThemeId?: number,
};

const TitleField: FC<Props> = ({
  disabled = false,
  register,
  setValue,
  defaultThemeId,
}) => {
  const dispatch = useAppDispatch();
  const selectParams = register('titleId');

  const themes = useAppSelector(({ feedBack }) => feedBack.themes);

  useEffect(() => {
    dispatch(feedBackActions.getFeedBackThemes());
  }, []);

  useEffect(() => {
    if (themes.length > 0) {
      const themeId = themes.find((item) => item.themeId === defaultThemeId)
        ? defaultThemeId
        : themes[0].themeId;
      setValue('titleId', themeId);
    }
  }, [themes]);

  return (
    <>
      <CRow className="mb-3">
        <CCol sm={12}>
          <CFormSelect disabled={disabled} { ...selectParams }  id="title">
            {
              themes.map(({ feedbackTheme, themeId }) => (
                <option key={themeId} value={themeId}>{ feedbackTheme }</option>
              ))
            }
          </CFormSelect>
        </CCol>
      </CRow>
    </>
  );
};

export { TitleField };
