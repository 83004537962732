import { CButton, CModal, CModalBody, CModalFooter } from '@coreui/react-pro';
import { AppTranslationKey, DangerAlertModalMode } from 'common/enums/enums';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { config } from './config';

type Props = {
  mode: DangerAlertModalMode,
  isVisible: boolean,
  onClose: () => void,
  onConfirm: () => void,
};

const DangerAlertModal: FC<Props> = ({ mode, isVisible, onClose, onConfirm }) => {
  const { t } = useTranslation([AppTranslationKey.DANGER_ALERT_MODAL]);
  const { text, cancelButtonText, applyButtonText } = config[mode];

  return (
    <CModal
      alignment="center"
      visible={isVisible}
      onClose={onClose}
      aria-labelledby="VerticallyCenteredExample"
    >
      <CModalBody>
        <h6>{t(text)}</h6>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={onClose}>
          {t(cancelButtonText)}
        </CButton>
        <CButton style={{ color: 'white' }} color="danger" onClick={onConfirm}>
          {t(applyButtonText)}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export { DangerAlertModal };
