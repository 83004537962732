import vinImage from 'assets/img/vin.png';
import vinCarImage from 'assets/img/vin-car.png';
import { AppTranslationKey, InfoVinTranslationKey } from 'common/enums/enums';
import React, { FC } from 'react';
import { Trans,useTranslation } from 'react-i18next';

import { InfoBlock } from '../info-block/info-block';
import { InfoMessage } from '../info-message/info-message';
import styles from './styles.module.scss';

const InfoSector: FC = () => {
  const { t } = useTranslation(AppTranslationKey.INFO_VIN);

  return (
    <div className={ styles.infoSectorContainer }>
      <InfoMessage
        title={ t(InfoVinTranslationKey.SEARCH_INFO_MESSAGE_TITLE) }
        message={ <Trans
          t={ t }
          i18nKey={ InfoVinTranslationKey.SEARCH_INFO_MESSAGE_TEXT }
          components={{ p: <p />, bold: <strong />, ul: <ul />, li: <li /> }}
        /> }
      />
      <div className={ styles.infoSectorBlocks }>
        <InfoBlock
          img={ vinImage }
          width={ 250 }
          title={ t(InfoVinTranslationKey.INFO_BLOCK_VIN_TITLE) as string }
          text={ <Trans t={ t } i18nKey={ InfoVinTranslationKey.INFO_BLOCK_VIN_TEXT } components={[<strong />]} /> }
        />
        <InfoBlock
          img={ vinCarImage }
          width={ 180 }
          title={ t(InfoVinTranslationKey.INFO_BLOCK_VIN_CAR_TITLE) as string }
          text={ t(InfoVinTranslationKey.INFO_BLOCK_VIN_CAR_TEXT) as string }
        />
      </div>
    </div>
  );
};

export { InfoSector };
