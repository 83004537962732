enum FeedBackModalKey {
  TITLE = 'title',
  DEFAULT_PLACEHOLDER = 'defaultPlaceholder',
  SEND_BUTTON_TITLE = 'sendButtonTitle',
  SUCCESS_MESSAGE = 'successMessage',
  ERROR_MESSAGE = 'errorMessage',
  KB = 'kb',
  MB = 'mb',
  WARNING_FILES_SIZE = 'warningFilesSizes',
}

export { FeedBackModalKey };
