import { createAsyncThunk } from '@reduxjs/toolkit';
import { LocalStorageKey } from 'common/enums/enums';
import {
  AddWorkInOrderResponse,
  AsyncThunkConfig, DeleteClientSparePartsRequest, EditWorkInOrderDto, FiltersStorage,
  OrderDataRequestDto,
  OrderDto,
  OrderGetByIdRequestDto, RemoveWorkFromOrderDto,
  StatusTask, UpdateClientSp, WorkDto,
} from 'common/types/types';
import { initialFilters } from 'components/common/common';
import { getPayloadByOrderFilters } from 'helpers/helpers';
import { storage } from 'services/services';
import { carServicesActions } from 'store/actions';

import { removeSPFromOrderLocally, updateClientSPLocally } from '../car-service/reducer';
import { ActionType } from './common';

const getStatuses = createAsyncThunk<
  StatusTask[],
  void,
  AsyncThunkConfig
>(ActionType.GET_STATUSES, async (payload, { extra }) => {
  const { dictionaryApi } = extra;

  return dictionaryApi.getStatuses();
});

const addTask = createAsyncThunk<
  void,
  OrderDataRequestDto,
  AsyncThunkConfig
>(ActionType.ADD_TASK, async (payload, { extra, dispatch }) => {
  const { orderApi } = extra;
  const { carServiceId } = payload;
  await orderApi.addTask(payload);

  if (carServiceId) {
    const filtersSettings: FiltersStorage = JSON.parse(storage.getItem(LocalStorageKey.ORDER_FILTERS) ?? '{}');
    const payloadOrders = getPayloadByOrderFilters(carServiceId, filtersSettings[carServiceId] || initialFilters);

    await dispatch(carServicesActions.getOrdersByFilters(payloadOrders));
  }
});

const getOrderById = createAsyncThunk<
  OrderDto,
  OrderGetByIdRequestDto,
  AsyncThunkConfig
>(ActionType.GET_TASK_BY_ID, async (payload, { extra, dispatch }) => {
  const { orderApi } = extra;

  const order = await orderApi.getOrderById(payload);
  dispatch(updateClientSPLocally(order));

  return order;
});

const updateOrder = createAsyncThunk<
  void,
  OrderDataRequestDto,
  AsyncThunkConfig
>(ActionType.UPDATE_TASK, async (payload,
  { extra, dispatch }) => {
  const { orderApi } = extra;
  const { carServiceId, requestId } = payload;

  if (requestId && carServiceId) {
    const filtersSettings: FiltersStorage = JSON.parse(storage.getItem(LocalStorageKey.ORDER_FILTERS) ?? '{}');
    const payloadOrders = getPayloadByOrderFilters(carServiceId, filtersSettings[carServiceId] || initialFilters);
    await orderApi.updateOrder(payload);
    await dispatch(carServicesActions.getOrdersByFilters(payloadOrders));
  }
});

const updateClientSPInOrder = createAsyncThunk<
  void,
  UpdateClientSp,
  AsyncThunkConfig
>(ActionType.UPDATE_CLIENT_SP_IN_TASK, async (payload,
  { extra, dispatch }) => {
  const { sparePartsApi } = extra;
  const { order, sparePartFromClientList } = payload;
  const removedClientSparePartsId: number[] = [];
  order?.sparePartsFromClient
    .filter((it) => sparePartFromClientList.every((item) => item.sparePartId !== it.sparePartId))
    .forEach((it) => it.sparePartId && removedClientSparePartsId.push(it.sparePartId));
  //delete
  await sparePartsApi.deleteClientSpareParts({
    sparePartIdList: removedClientSparePartsId,
  });
  //add
  await sparePartsApi.addClientSpareParts({
    sparePartFromClientList: sparePartFromClientList.filter((it) => Boolean(it.id)),
  });
  //update
  await sparePartsApi.editClientSpareParts({
    sparePartFromClientList: sparePartFromClientList.filter((it) => Boolean(it.sparePartId)),
  });
  order && dispatch(getOrderById({ orderId: order.id }));
});

const addWorkInTask = createAsyncThunk<
  AddWorkInOrderResponse,
  WorkDto,
  AsyncThunkConfig
>(ActionType.ADD_WORK_IN_TASK, async (payload, { extra }) => {
  const { orderApi } = extra;

  return orderApi.addWorkInOrder(payload);
});

const removeClientSpareParts = createAsyncThunk<
  DeleteClientSparePartsRequest,
  DeleteClientSparePartsRequest,
  AsyncThunkConfig
>(ActionType.REMOVE_CLIENT_SP_FROM_TASK, async (payload, { extra, dispatch }) => {
  const { sparePartsApi } = extra;
  await sparePartsApi.deleteClientSpareParts(payload);
  dispatch(removeSPFromOrderLocally(payload));

  return payload;
});

const removeWork = createAsyncThunk<
  RemoveWorkFromOrderDto,
  RemoveWorkFromOrderDto,
  AsyncThunkConfig
>(ActionType.REMOVE_WORK_FROM_TASK, async (payload, { extra }) => {
  const { orderApi } = extra;

  return orderApi.removeWorkFromOrder(payload);
});

const editWork = createAsyncThunk<
  EditWorkInOrderDto,
  EditWorkInOrderDto,
  AsyncThunkConfig
>(ActionType.EDIT_WORK_IN_TASK, async (payload, { extra }) => {
  const { orderApi } = extra;

  return orderApi.editWorkInOrder(payload);
});
export {
  addTask,
  addWorkInTask,
  editWork,
  getOrderById,
  getStatuses,
  removeClientSpareParts,
  removeWork,
  updateClientSPInOrder,
  updateOrder,
};
