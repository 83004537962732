import { CCol, CFormLabel, CFormSelect, CRow } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/translation-key/app-translation-key.enum';
import { BodyType, CarModalFormData } from 'common/types/types';
import React, { FC } from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  register: UseFormRegister<CarModalFormData>,
  bodyTypes: BodyType[],
  disabled?: boolean,
};

const BodyTypeField: FC<Props> = ({
  disabled = false, register, bodyTypes,
}) => {
  const { t } = useTranslation(AppTranslationKey.CAR_MODAL);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="bodyType" className="col-sm-3 col-form-label">
        {t('bodyType')}
      </CFormLabel>
      <CCol sm={9}>
        <CFormSelect
          disabled={disabled}
          { ...register('carBodyTypeId') }
          id="bodyType"
        >
          <option key={-1} value={-1}>{t('selectBodyType')}</option>
          {
            bodyTypes.map((bodyType) => (
              <option key={bodyType.id} value={bodyType.id}>
                {bodyType.name}
              </option>
            ))
          }
        </CFormSelect>
      </CCol>
    </CRow>
  );
};

export { BodyTypeField };
