import { CButton, CContainer } from '@coreui/react-pro';
import { AppRoute, AppTranslationKey, ErrorPageTranslationKey } from 'common/enums/enums';
import { FeedBackModalForm } from 'components/modals/modals';
import { useState } from 'hooks/hooks';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Icon } from '../common/common';
import styles from './style.module.scss';

const ErrorPage: FC = () => {

  const { t } = useTranslation(AppTranslationKey.ERROR_PAGE);
  const [feedBackModalIsOpen, setFeedBackModalIsOpen] = useState<boolean>(false);

  const handleOpenFeedBackModal = (): void => setFeedBackModalIsOpen(true);

  const handleCloseFeedBackModal = ():void => setFeedBackModalIsOpen(false);

  return <CContainer >
    <div className={styles.container}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Icon name="error500" className={styles.iconSize}/>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
          <h1>ERROR</h1>
          <h4>{t(ErrorPageTranslationKey.TITLE)}</h4>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="pt-4" style={{ textAlign: 'start', width: '80%' }}>{
          <Trans
            t={ t }
            i18nKey={ ErrorPageTranslationKey.MSG }
            components={{
              p: <p />,
              bold: <strong />,
              ul: <ul />,
              li: <li />,
              contactUs:  <CButton
                className="ms-1 mb-1 p-0"
                color="link"
                size="sm"
                title="Відправити листа"
                onClick={handleOpenFeedBackModal}
              > {t(ErrorPageTranslationKey.CONTACT_US_LINK_TEXT)}
              </CButton>,
            }}
          />
        }
        </div>
      </div>
      <p className="pt-4">
        <CButton
          style={{ textDecoration: 'none' }}
          size="sm" color="primary"
          href={AppRoute.ROOT}
        >
          {t(ErrorPageTranslationKey.BACK_TO_MAIN)}
        </CButton>
      </p>
    </div>
    <FeedBackModalForm
      isOpen={feedBackModalIsOpen}
      isReadOnly={ false }
      isUpdating={false}
      onCloseModal={handleCloseFeedBackModal}
    />
  </CContainer>;
};

export { ErrorPage };
