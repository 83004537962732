import { CButton, CCol, CFormInput, CFormLabel, CRow } from '@coreui/react-pro';
import { OrderModalFormData } from 'common/types/types';
import { OrderCertificateButton, usePermissionToastContext } from 'components/common/common';
import { Tariffs } from 'constants/tariffs';
import { useEffect } from 'hooks/hooks';
import React, { FC } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  register: UseFormRegister<OrderModalFormData>,
  errors: FieldErrors<OrderModalFormData>,
  isReadOnly: boolean,
  onOpenAddCarModal: () => void,
  onOpenListCarsModal: () => void,
  orderId: number | undefined,
};

const CarField: FC<Props> = ({
  register,
  isReadOnly,
  errors,
  onOpenAddCarModal,
  onOpenListCarsModal,
  orderId,
}) => {
  const { t } = useTranslation('orderModal');
  const { onPermissionAction } = usePermissionToastContext();

  useEffect(() => {
    register('autoId',
      {
        required: t('requiredFormField') ?? 'This field is required',
        validate: (value) => value > -1 || t('requiredFormField') as string,
      });
  }, [register, t]);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="auto" className="col-sm-2 col-form-label">
        {t('autoFieldLabel')}
      </CFormLabel>
      <CCol sm={isReadOnly ? 6 : 5}>
        <CFormInput
          { ...register('autoName') }
          disabled
          type="text"
          id="auto"
          invalid={ Boolean(errors.autoId) }
          feedbackInvalid={ errors.autoId?.message }
          className="bg-white"
        />
      </CCol>
      {
        !isReadOnly && [
          <CCol sm={2} key="select-btn">
            <CButton color="primary" variant="outline" onClick={onOpenListCarsModal}>
              {t('selectBtn')}
            </CButton>
          </CCol>,

          <CCol sm={2} key="new-btn">
            <CButton color="dark" variant="outline" onClick={onPermissionAction(onOpenAddCarModal, Tariffs.NO_TARIFF)} >
              {t('newBtn')}
            </CButton>
          </CCol>,
        ]
      }
      {
        (isReadOnly && orderId) && (
          <CCol sm={3} key="select-btn">
            <OrderCertificateButton orderId={ orderId } />
          </CCol>
        )
      }
    </CRow>
  );
};

export { CarField };
