import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { en } from './en';
import { ru } from './ru';
import { uk } from './uk';

enum LANG {
  EN = 'en',
  UK = 'uk',
  RU = 'ru',
}

// eslint-disable-next-line import/no-named-as-default-member
i18next.use(LanguageDetector).init({
  resources: { en, uk, ru },
  fallbackLng: LANG.UK,
  debug: false,
  keySeparator: '.',
  interpolation: {
    escapeValue    : false,
    formatSeparator: ',',
  },
});

export { i18next, LANG };
