import { LocalStorageKey } from 'common/enums/enums';
import { StorageDto } from 'common/types/types';
import { useAppDispatch, useCallback, useEffect, useSearchParams, useState } from 'hooks/hooks';
import { storage } from 'services/services';
import { storageActions, supplierActions } from 'store/actions';

type UseUserStoragesResult = {
  activeStorage: StorageDto | null,
  handleActiveStorageChange: (storage: StorageDto | null) => void,
  getActiveStorageById: (userStorages: StorageDto[], id: number) => StorageDto | null,
};

type UseUserStoragesArg = {
  userId: number;
};

const useUserStorages = ({ userId }: UseUserStoragesArg): UseUserStoragesResult => {
  const dispatch = useAppDispatch();
  const [, setTabId] = useSearchParams();
  const [activeStorage, setActiveStorage] = useState<StorageDto | null>(null);

  useEffect( () => {
    dispatch(storageActions.getStocksByUserId({ userId }));
    dispatch(supplierActions.getListSuppliers({ userId }));
  },[dispatch, userId]);

  const handleActiveStorageChange = useCallback((activeStorage: StorageDto | null): void => {
    activeStorage?.stockId && storage.setItem(LocalStorageKey.ACTIVE_STORAGE_TAB, `${activeStorage?.stockId}`);
    activeStorage && setTabId({ tabId: activeStorage?.stockId.toString() ?? '' });
    setActiveStorage(activeStorage);
  }, [setTabId]);

  const getActiveStorageById = useCallback(
    (userStorages: StorageDto[], id: number): StorageDto | null =>
      userStorages.find((item) => item?.stockId === id) ?? null, []);

  return { activeStorage, handleActiveStorageChange, getActiveStorageById };

};

export { useUserStorages };
