import { cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CButton,
  CForm,
  CFormInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CSmartTable,
} from '@coreui/react-pro';
import { AppTranslationKey, ClientSparePartsTranslationKey } from 'common/enums/enums';
import { ClientSpareParts } from 'common/types/types';
import { NoData } from 'components/common/no-data/no-data';
import { onlyNumberString } from 'helpers/helpers';
import { useEffect, useForm, useState } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AddClientSparePartForm } from './add-client-spare-part-form.type';
import { DEFAULT_ADD_CLIENT_SPARE_PART_PAYLOAD } from './common';
import { getClientSparePartsTableColumn } from './helpers/get-client-spare-parts-table-column';
import styles from './styles.module.scss';

type Props = {
  isVisible: boolean,
  onClose: () => void,
  orderId: number | null,
  alwaysEdit?: boolean,
  bindSpareParts: ClientSpareParts[],
  onSaveSpareParts: (clientSparePart: ClientSpareParts[]) => void,
};

const CustomerSparePartsModal: FC<Props> = (
  { onSaveSpareParts,
    bindSpareParts,
    orderId,
    isVisible,
    onClose,
    alwaysEdit = false,
  }) => {

  const { t } = useTranslation(AppTranslationKey.CLIENT_SPARE_PARTS);
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm<AddClientSparePartForm>({
    defaultValues: DEFAULT_ADD_CLIENT_SPARE_PART_PAYLOAD,
  });
  const [clientSpareParts, setClientSpareParts] = useState<ClientSpareParts[]>([]);
  const [isFormClearVisible, setFormClearVisible] = useState<boolean>(false);
  const columns = getClientSparePartsTableColumn();

  useEffect(() => {
    setClientSpareParts(bindSpareParts);
  }, [bindSpareParts, isVisible]);

  const handleAddSparePart = (payload: AddClientSparePartForm):void => {
    setClientSpareParts((prev) => [
      ...prev,
      {
        id: crypto.randomUUID(),
        quantity: payload.count as number ?? 0,
        sparePartName: payload.name,
        requestId: orderId ?? null,
        orderId: null,
      }]);
    setFormClearVisible(false);
    reset(DEFAULT_ADD_CLIENT_SPARE_PART_PAYLOAD);
  };

  const handleClose = (): void => {
    reset(DEFAULT_ADD_CLIENT_SPARE_PART_PAYLOAD);
    setFormClearVisible(false);
    onClose();
  };

  const handleRemoveSparePart = (item: ClientSpareParts): void => {
    setClientSpareParts(
      (prev) =>
        prev.filter((it) => it.id ? it.id !== item.id : it.sparePartId !== item.sparePartId),
    );
  };

  const handleChangeQty = (value: string, item: ClientSpareParts): void => {
    setClientSpareParts(clientSpareParts.map((it) => {
      if (it.id === item.id && it.sparePartId === item.sparePartId) {

        return { ...it, quantity: +value };
      }

      return it;
    }));
  };

  const handleChange = (value: string): void => {
    setValue('count', onlyNumberString(value));
    !isFormClearVisible && setFormClearVisible(true);
  };

  const handleChangeName = (value: string, item: ClientSpareParts): void => {
    setClientSpareParts(clientSpareParts.map((it) => {
      if (it.id === item.id && it.sparePartId === item.sparePartId) {

        return { ...it, sparePartName: value };
      }

      return it;
    }));
  };

  const handleSaveSpareParts = (): void => {
    const value =  getValues();

    if(value.name !== '' || value.count !== null){
      setError('name', { message: t(ClientSparePartsTranslationKey.NOT_ADDED_SPARE_PART_ERROR) ?? 'Error' });
      setError('count', { message: '' });
    } else {
      onSaveSpareParts(clientSpareParts);
    }
  };

  return <CModal
    alignment="center"
    visible={isVisible}
    onClose={handleClose}
    backdrop="static"
    size={'lg'}
  >
    <CModalHeader>
      <CModalTitle>
        {t(ClientSparePartsTranslationKey.TITLE)}
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <div className={styles.formWrap}>
        <CForm onSubmit={handleSubmit(handleAddSparePart)}>
          <div className={styles.formContainer}>
            <div style={{ width: '50%' }}>
              <CFormInput
                {...register('name',
                  {
                    required: t(ClientSparePartsTranslationKey.REQUIRED) ?? 'required',
                  })
                }
                size="sm"
                type="text"
                label={t(ClientSparePartsTranslationKey.NAME)}
                name="name"
                onChange={(): void => {
                  !isFormClearVisible && setFormClearVisible(true);
                }}
                invalid={Boolean(errors.name)}
                feedbackInvalid={errors.name?.message}
              />
            </div>
            <div style={{ width: '20%' }}>
              <CFormInput
                {...register('count',
                  {
                    required: t(ClientSparePartsTranslationKey.REQUIRED) ?? 'required',
                  })
                }
                size="sm"
                type="text"
                label={t(ClientSparePartsTranslationKey.COUNT)}
                name="count"
                min={ 0 }
                step={ 0.01 }
                onChange={(event): void => handleChange(event.target.value)}
                invalid={Boolean(errors.count)}
                feedbackInvalid={errors.count?.message}
              />
            </div>
            <div>
              <CButton
                style={{ marginTop: 25 }}
                color="primary"
                variant="outline"
                type="submit"
              >
                {t(ClientSparePartsTranslationKey.ADD_SPARE_PART_TITLE)}
              </CButton>
            </div>
            <div  className={styles.iconFormContainer}>
              {isFormClearVisible && <CIcon
                style={{ cursor: 'pointer' }}
                className="text-danger"
                size="xl"
                icon={cilTrash}
                onClick={(): void => {
                  setFormClearVisible(false);
                  reset(DEFAULT_ADD_CLIENT_SPARE_PART_PAYLOAD);
                }}
              />}
            </div>
          </div>
        </CForm>
      </div>
      <p className={styles.cardTitle}>
        {`${t(ClientSparePartsTranslationKey.ADDED)} (${clientSpareParts.length})`}
      </p>
      {
        clientSpareParts.length > 0 ?
          <div className={styles.selectedTableWrap}>
            <CSmartTable
              columns={columns}
              items={clientSpareParts}
              itemsPerPage={clientSpareParts.length}
              loading={false}
              scopedColumns={{
                remove: (item: ClientSpareParts) => (
                  <td style={ { verticalAlign: 'middle', textAlign: 'right' } }>
                    {(alwaysEdit ? !alwaysEdit : Boolean(item.orderId)) ? '' : <CIcon
                      style={{ cursor: 'pointer' }}
                      className="text-danger"
                      size="xl"
                      icon={cilTrash}
                      onClick={(): void =>
                        handleRemoveSparePart(item)}
                    />}
                  </td>
                ),
                name: (item: ClientSpareParts) => (
                  <td>
                    <CFormInput
                      disabled={ alwaysEdit ? !alwaysEdit : Boolean(item.orderId) }
                      type="text"
                      id="name"
                      value={item.sparePartName}
                      onChange={ (event): void =>
                        handleChangeName(event.target.value, item)}
                    />
                  </td>
                ),
                count: (item: ClientSpareParts) => (
                  <td>
                    <CFormInput
                      disabled={ alwaysEdit ? !alwaysEdit : Boolean(item.orderId)}
                      type="number"
                      id="count"
                      min={0}
                      step={0.01}
                      value={item.quantity}
                      onChange={ (event): void =>
                        handleChangeQty(onlyNumberString(event.target.value), item)}
                    />
                  </td>
                ),
              }}
              tableHeadProps={{
                color: 'light',
              }}
              tableProps={{
                striped: true,
                hover: true,
              }}
            />
          </div> :
          <NoData
            title={t(ClientSparePartsTranslationKey.NO_SPARE_PARTS)} message=""
          />
      }
    </CModalBody>
    <CModalFooter>
      <CButton color="secondary" onClick={handleClose}>
        {t(ClientSparePartsTranslationKey.CLOSE)}
      </CButton>
      <CButton style={{ color: 'white' }} color="primary" onClick={handleSaveSpareParts}>
        {t(ClientSparePartsTranslationKey.SAVE)}
      </CButton>
    </CModalFooter>
  </CModal>;
};

export { CustomerSparePartsModal };
