import { SupplierDto, SupplierTableRaw } from 'common/types/types';

const getData = (list: SupplierDto[]): SupplierTableRaw[] => list.map((item) => ({
  number: item.counterpartyId,
  name: item.counterpartyName,
  status: item.counterpartyStatus ? item.counterpartyStatus.name : '',
  address: item.address,
  info: item.counterpartyInfo,
}));

export { getData };
