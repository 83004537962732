import { CFormSwitch } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { getPeriodCurrentWeek, isPeriodCurrentWeek } from 'helpers/helpers';
import { useEffect } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useFilterPanelContext } from '../filter-panel-context';

const FieldCurrentWeek: FC = () => {
  const { t } = useTranslation(AppTranslationKey.CAR_SERVICE_ORDER);
  const { filters: { isCurrentWeek, dateFrom, dateTo }, setOrderFilters } = useFilterPanelContext();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = event.target;

    setOrderFilters({
      isToday: false,
      isCurrentWeek: checked,
      isCurrentMonth: false,
      ...getPeriodCurrentWeek(),
    });
  };

  useEffect(() => {
    if(isCurrentWeek && !isPeriodCurrentWeek({ dateFrom, dateTo })) {
      setOrderFilters({ isCurrentWeek: false });
    }
  }, [isCurrentWeek, dateFrom, dateTo]);

  return (
    <CFormSwitch
      size="lg"
      label={ t('filters.currentWeek') }
      checked={ isCurrentWeek }
      onChange={ onChange }
    />
  );
};

export { FieldCurrentWeek };
