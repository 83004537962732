import { CMultiSelect } from '@coreui/react-pro';
import { Option } from '@coreui/react-pro/dist/components/multi-select/types';
import { AppTranslationKey } from 'common/enums/enums';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useFilterPanelContext } from '../filter-panel-context';
import styles from '../style.module.scss';

const FieldStatuses: FC = () => {
  const { t } = useTranslation(AppTranslationKey.CAR_SERVICE_ORDER);
  const { filters: { statuses }, setOrderFilters }  = useFilterPanelContext();

  const onChange = (selectedOptions: Option[]): void => {
    const options = statuses.map(({ value, text }) => ({
      value,
      text,
      selected: selectedOptions.some((item) => item.value === value),
    }));

    setOrderFilters({ statuses: options });
  };

  return (
    <CMultiSelect
      className={ styles.fieldStatus }
      options={ statuses }
      selectionType="counter"
      selectionTypeCounterText="виб..."
      placeholder={ t('filters.status') || 'Статус...' }
      selectAllLabel={ t('filters.selectAllOptions') }
      onChange={ onChange }
    />
  );
};

export { FieldStatuses };
