enum ActionType {
  GET_WIDGETS_DATA = 'widget/get-widgets-data',
  GET_NOT_COMPLETED_TASKS_BY_USER_ID = 'widget/get-not-completed-tasks-by-user-id',
  GET_OPEN_REQUESTS = 'widget/get-open-requests',
  GET_OPEN_WORK_ORDERS = 'widget/get-open-work-orders',
  GET_NOT_COMPLETED_TASKS_BY_CAR_SERVICE_ID = 'widget/get-not-completed-tasks-by-car-service-id',
  GET_ALL_CARS_BY_CAR_SERVICE_ID = 'widget/get-all-cars-by-car-service-id',
  GET_ALL_REQUESTS_BY_CAR_SERVICE_ID = 'widget/get-all-requests-by-car-service-id',
}

export { ActionType };
