import { ApiPath, ContentType, HttpMethod } from 'common/enums/enums';
import { SendErrorRequest,SendErrorResponse } from 'common/types/types';
import { Http } from 'services/http/http.service';

type Constructor = {
  http: Http;
  apiPrefix: string;
};

class ErrorHandlerApi {

  #http: Http;

  #apiPrefix: string;

  public constructor({ http, apiPrefix }: Constructor) {
    this.#http = http;
    this.#apiPrefix = apiPrefix;
  }

  public sendError( payload: SendErrorRequest):Promise<SendErrorResponse> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.SEND_ERROR}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }

}

export { ErrorHandlerApi };
