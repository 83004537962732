import { SelectWorkToOrderModal } from 'components/modals/modals';
import { useState } from 'hooks/hooks';
import React, { ReactElement } from 'react';

type Props = {
  activeCarServiceId: number,
  orderId: number,
};

type UseSelectWorkToOrderModal = {
  getSelectWorkToOrderModal: (payload: Props ) => ReactElement,
  toggleSelectWorkToOrderModal: (isVisible: boolean) => void,
};

const useSelectWorkToOrderModal = (): UseSelectWorkToOrderModal => {

  const [ isSelectWorkOpen, setIsSelectWorkOpen ] = useState<boolean>(false);

  const toggleSelectWorkToOrderModal = (isVisible: boolean): void => {
    setIsSelectWorkOpen(isVisible);
  };

  const handleCloseSelectWorkModal = (): void => {
    toggleSelectWorkToOrderModal(false);
  };

  const getSelectWorkToOrderModal = ({ orderId, activeCarServiceId }: Props): ReactElement => {

    return <SelectWorkToOrderModal
      isOpen={ isSelectWorkOpen }
      carServiceId={ activeCarServiceId }
      orderId={ orderId }
      onClose={ handleCloseSelectWorkModal }
    />;
  };

  return { getSelectWorkToOrderModal, toggleSelectWorkToOrderModal };

};

export { useSelectWorkToOrderModal };
