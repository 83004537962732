import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro';
import {
  AppRoute,
  AppTranslationKey,
  DataStatus, LocalStorageKey,
  Pages,
  PersonalRoute,
  StorageTranslationKey,
} from 'common/enums/enums';
import { WithStorageTabs } from 'common/types/types';
import { NavTabs, NoDataWithAction, Spinner, usePermissionToastContext } from 'components/common/common';
import { Page404 } from 'components/page-404/page-404';
import { Tariffs } from 'constants/tariffs';
import { useAppSelector, useEffect, useNavigate, useSearchParams, useUserStorages } from 'hooks/hooks';
import React, { ComponentType, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { storage } from 'services/services';

import { extraButtonTitle } from '../contexts/constants';
import { useExtraButtonContext } from '../contexts/extra-button-context';
import { getValidClasses } from '../helpers/dom/get-valid-classes/get-valid-classes.helper';
import styles from './styles.module.scss';

export const withStoragesTabs = (
  WrappedComponent: ComponentType<WithStorageTabs>,
  typePage?: Pages,
  getTitle = (): string => '',
) => {

  return (): ReactElement => {
    const {
      userId,
      stocks,
      dataStatus,
    } = useAppSelector(({ auth, stock }) => ({
      userId: auth.currentUser?.id ?? 0,
      stocks: stock.stocks,
      dataStatus: stock.getStocksByUserIdDataStatus,
    }));
    const navigate = useNavigate();
    const { t } = useTranslation(AppTranslationKey.STOCK);
    const [ tabId ] = useSearchParams();
    const { activeStorage, handleActiveStorageChange, getActiveStorageById } = useUserStorages({ userId });
    const isLoading = dataStatus === DataStatus.PENDING;
    const { onPermissionAction } = usePermissionToastContext();
    const { setExtraButton, removeExtraButton } = useExtraButtonContext();

    useEffect(() => {
      if (stocks.length === 0 && typePage && extraButtonTitle[typePage]) {
        setExtraButton({
          isHide: false,
          translateTitleKey: extraButtonTitle[typePage],
          action: () => {
            if (typePage === Pages.STORAGE) {
              onPermissionAction(
                () => navigate(`${AppRoute.PERSONAL}/${PersonalRoute.ADD_STORAGE}`),
                Tariffs.NO_TARIFF,
              )();
            }
          },
        });
      }

      return () => {
        removeExtraButton();
      };
    }, [stocks.length, typePage]);

    useEffect(() => {

      const storageTabId = storage.getItem(LocalStorageKey.ACTIVE_STORAGE_TAB);

      if (tabId.get('tabId') !== null) {
        const stock = getActiveStorageById(stocks, parseInt(tabId.get('tabId') ?? '-1'));
        handleActiveStorageChange(stock);
      } else {
        storageTabId ?
          handleActiveStorageChange(getActiveStorageById(stocks, +storageTabId)) :
          handleActiveStorageChange(stocks[0]);
      }
    }, [getActiveStorageById, handleActiveStorageChange, stocks, tabId]);

    const handleTabClick = (id: number): void => {
      handleActiveStorageChange(getActiveStorageById(stocks, id));
    };

    const handledAddStorage = (): void => {
      navigate(`${AppRoute.PERSONAL}/${PersonalRoute.ADD_STORAGE}`);
    };

    if (isLoading) {
      return <Spinner isOverflow containerHeight={250}/>;
    }

    if (stocks.length === 0) {

      return <NoDataWithAction
        title={t(StorageTranslationKey.NO_DATA_TITLE)}
        message={t(StorageTranslationKey.NO_DATA_TEXT)}
        actionName={t(StorageTranslationKey.ADD_STORAGE)}
        callback={onPermissionAction(handledAddStorage, Tariffs.NO_TARIFF)}
      />;
    }

    if (activeStorage === null) {
      return <Page404 />;
    }

    return (
      <CCard>
        <CCardHeader className="d-flex flex-column px-lg-0">
          <h5 className={getValidClasses(styles.headerTitle, 'text-dark')}>{getTitle()}</h5>
          <div style={{ paddingLeft: 16 }}>
            <NavTabs
              tabs={stocks.map((item) => ({ name: item.stockName, id: item.stockId }))}
              activeTabId={activeStorage?.stockId ?? stocks[0]?.stockId}
              handleTabClick={handleTabClick}
            />
          </div>
        </CCardHeader>
        <CCardBody>
          <WrappedComponent activeStorage={activeStorage} />
        </CCardBody>
      </CCard>
    );
  };
};
