import { CModal, CModalBody } from '@coreui/react-pro';
import cn from 'classnames';
import { useState } from 'hooks/hooks';
import React , { FC } from 'react';

import styles from './styles.module.scss';

interface IProps {
  src: string,
  width: number,
  alt: string,
  className?: string,
  bodyClass?: string,
}

const ViewImage: FC<IProps> = ({
  src,
  width,
  alt,
  className,
  bodyClass,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <img
        src={ src }
        width={ width }
        alt={ alt }
        className={ cn( styles.imagePreview, className ) }
        onClick={ (): void => setIsOpen(true) }
      />
      <CModal
        visible={ isOpen }
        alignment="center"
        size="xl"
        onClose={(): void => setIsOpen(false)}
        className={ styles.viewModal }
        keyboard
      >
        <CModalBody className={ cn(styles.viewModalBody, bodyClass) } onClick={(): void => setIsOpen(false)}>
          <img
            src={src}
            height="100%"
            alt={alt}
          />
        </CModalBody>
      </CModal>
    </>
  );
};

export { ViewImage };
