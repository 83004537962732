import { ApiPath, ContentType, HttpMethod, ServicesApiPath } from 'common/enums/enums';
import { CarServiceDto, CarServiceEditRequestDto, CarServicesGetByUserIdRequestDto } from 'common/types/types';
import { Http } from 'services/http/http.service';

type Constructor = {
  http: Http;
  apiPrefix: string;
};

class CarServiceApi {

  #http: Http;

  #apiPrefix: string;

  public constructor({ http, apiPrefix }: Constructor) {
    this.#http = http;
    this.#apiPrefix = apiPrefix;
  }

  public getAllCarServiceByUserId({ userId }: CarServicesGetByUserIdRequestDto): Promise<CarServiceDto[]> {

    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.SERVICES}${ServicesApiPath.GET_BY_USER_ID}/${userId}`,
    );
  }

  public updateCarServiceById(payload: CarServiceEditRequestDto): Promise<CarServiceDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.SERVICES}${ServicesApiPath.ROOT}${payload.id}`,
      {
        method: HttpMethod.PUT,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }

  public createCarService(payload: CarServiceEditRequestDto): Promise<CarServiceDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.SERVICES}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }
}

export { CarServiceApi };
