import { cilPrint } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { useAppDispatch } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { documentationAction } from 'store/actions';

import styles from './style.module.scss';

interface IProps {
  orderId: number,
}

const OrderCertificateButton: FC<IProps> = ({ orderId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(AppTranslationKey.COMMON);

  const onRoutToCertificatePage = (): void => {
    dispatch(documentationAction.getCertificateOfCompletedWork({ requestId: Number(orderId) }))
      .unwrap()
      .then(({ url }) => window.open(url));
  };

  return (
    <CButton
      variant="outline"
      color="warning"
      className={ styles.certificateButton }
      onClick={ onRoutToCertificatePage }
    >
      { t('certificateButton') }
      <CIcon icon={cilPrint} size="lg"/>
    </CButton>
  );
};

export { OrderCertificateButton };
