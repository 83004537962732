import { CCardTitle, CForm, CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react-pro';
import { AppTranslationKey, SelectWorkModalTranslationKey } from 'common/enums/enums';
import { CarWorkFav, SelectedWorks } from 'common/types/types';
import { FavCarWorkTable, NoData, SelectedWorksTable, TotalField } from 'components/common/common';
import { ModalFooter } from 'components/modals/components/components';
import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type Props = {
  isOpen: boolean,
  onClose: () => void,
  onSelectWork: (work: CarWorkFav) => void,
  onEditSelectedWork: () => void,
  onChangePrice: (value: string, workId: number) => void,
  onRemoveSelectedWork: (workId: number) => void,
  onChangeQty: (value: string, workId: number) => void,
  selectedWork: SelectedWorks[],
  workList: CarWorkFav[],
  isCanRemoveSelectedWork: (item?: SelectedWorks) => boolean,
  isDisabledFavWork: (item?: CarWorkFav) => boolean,
};

const SelectWorkModal: FC<Props> = ( {
  isOpen,
  onClose,
  onSelectWork,
  selectedWork,
  workList,
  onEditSelectedWork,
  onChangePrice,
  onRemoveSelectedWork,
  onChangeQty,
  isCanRemoveSelectedWork,
  isDisabledFavWork,
}) => {

  const { t } = useTranslation(AppTranslationKey.SELECT_WORK_MODAL);

  const TotalModalSum = (): ReactElement => {
    const label = t(SelectWorkModalTranslationKey.SELECT_WORK_SUM);
    const items = selectedWork.map((it) => (parseFloat(it.qty) * parseFloat(it.price)));

    return <TotalField label={ label } items={ items } />;
  };

  return (
    <CModal
      visible={isOpen}
      backdrop="static"
      onClose={onClose}
      size="xl"
      scrollable
    >
      <CModalHeader>
        <CModalTitle>
          {t(SelectWorkModalTranslationKey.SELECT_WORK_TITLE)}
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div>
          <div className={styles.carWorkTableWrap}>
            <FavCarWorkTable
              isLoading={false}
              onSelectCarWork={onSelectWork}
              selectedWorks={selectedWork}
              carWork={workList}
              isDisabled={isDisabledFavWork}
            />
          </div>
          <CCardTitle className={styles.cardTitle}>
            {t(SelectWorkModalTranslationKey.SELECTED)} ({selectedWork.length})
          </CCardTitle>
          {
            selectedWork.length > 0 ? <div className={styles.selectedWorkTableWrap}>
              <SelectedWorksTable
                onEdit={onEditSelectedWork}
                onChangePrice={onChangePrice}
                selectedWorks={selectedWork}
                isCanRemove={isCanRemoveSelectedWork}
                onRemoveSelectedWork={ (work):void => {
                  onRemoveSelectedWork(work.id);
                } }
                onChangeQty={onChangeQty}
              />
            </div> : <NoData
              title={t(SelectWorkModalTranslationKey.NO_SELECT)} message=""
            />
          }
        </div>
      </CModalBody>
      <CForm
        className="form-scroll"
      >
        <ModalFooter
          hasModifyBtn={false}
          isUpdateData={false}
          desc={ <TotalModalSum /> }
          onClose={onClose}
        />
      </CForm>

    </CModal>
  );
};

export { SelectWorkModal };
