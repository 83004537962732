import { CToast, CToastBody } from '@coreui/react-pro';
import { getValidClasses } from 'helpers/helpers';
import { FC, ReactNode } from 'react';

import styles from './style.module.scss';

type Props = {
  visible: boolean,
  children: ReactNode,
  type?: 'warn' | 'info'
};

const AppToast:FC<Props> = ({
  visible,
  type = 'info',
  children,
}) => {
  return (
    <CToast
      autohide={false}
      visible={visible}
      className={getValidClasses(styles.appToast, styles[type])}
    >
      <CToastBody>
        {children}
      </CToastBody>
    </CToast>
  );
};

export { AppToast };
