import { cilLockLocked } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CFormInput, CInputGroup, CInputGroupText } from '@coreui/react-pro';
import { Icon } from 'components/common/icon/icon';
import { getValidClasses } from 'helpers/helpers';
import { useState } from 'hooks/hooks';
import React, { forwardRef } from 'react';

import styles from './styles.module.scss';

type Props = {
  withShowPassword?: boolean,
  placeholder?: string,
  classNameContainer?: string,
  invalid?: boolean,
  valid?: boolean,
  feedbackInvalid?: string,
  name?: string,
  invalidFeedbackClass?: string,
  autoComplete?: boolean,
};

const PasswordInput= forwardRef<HTMLInputElement, Props>(({
  withShowPassword = true,
  classNameContainer,
  invalid,
  valid,
  feedbackInvalid,
  invalidFeedbackClass,
  autoComplete,
  ...inputProps
}, ref) => {
  const [ isPasswordHidden, setIsPasswordHidden ] = useState<boolean>(true);
  const handlePassHiddenClick = (): void => setIsPasswordHidden(!isPasswordHidden);

  return (
    <>
      <CInputGroup className={ classNameContainer }>
        <CInputGroupText>
          <CIcon icon={cilLockLocked}/>
        </CInputGroupText>
        <CFormInput
          name="password"
          { ...inputProps }
          ref={ ref }
          type={isPasswordHidden ? 'password' : 'text'}
          invalid={ invalid }
          valid={ valid }
          autoComplete={ autoComplete ? 'new-password' : undefined }
        />
        {
          withShowPassword && (
            <CInputGroupText
              className={styles.passwordHiddeIcon}
              onClick={handlePassHiddenClick}
            >
              <Icon
                name={isPasswordHidden ? 'showPass' : 'hidePass'}
              />
            </CInputGroupText>
          )
        }
      </CInputGroup>
      <div className={
        getValidClasses(
          styles.invalidFeedback,
          invalidFeedbackClass,
          invalid || styles.invalidFeedbackHidden,
        )}>
        {feedbackInvalid}
      </div>
    </>
  );
});

export { PasswordInput };
