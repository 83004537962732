import { ApiPath, WidgetApiPath } from 'common/enums/enums';
import {
  CountResponse,
  GetOpenWorkOrdersRequest,
  NotCompletedTasksByUserIdRequest,
  NotCompletedTasksByUserIdResponse,
  OpenRequest, OpenWorkOrder,
  WidgetsData,
} from 'common/types/types';
import { Http } from 'services/http/http.service';

type Constructor = {
  http: Http;
  apiPrefix: string;
};

class WidgetApi {
  #http: Http;

  #apiPrefix: string;

  public constructor({ http, apiPrefix }: Constructor) {
    this.#http = http;
    this.#apiPrefix = apiPrefix;
  }

  public getWidgetsData({ userId }: { userId: number }): Promise<WidgetsData> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.WIDGET}${WidgetApiPath.USER}/${userId}`,
    );
  }

  public getNotCompletedTasksByUserId(
    { userId }: NotCompletedTasksByUserIdRequest,
  ): Promise<NotCompletedTasksByUserIdResponse[]> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.WIDGET}${WidgetApiPath.USER}/${userId}${WidgetApiPath.NOT_COMPLETED_REQUEST}`,
    );
  }

  public getOpenRequests({ userId }: NotCompletedTasksByUserIdRequest): Promise<OpenRequest[]>{
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.WIDGET}${WidgetApiPath.USER}/${userId}${WidgetApiPath.OPEN_REQUESTS}`,
    );
  }

  public getOpenWorkOrders({ userId }: GetOpenWorkOrdersRequest): Promise<OpenWorkOrder[]> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.WIDGET}${WidgetApiPath.USER}/${userId}${WidgetApiPath.OPEN_WORK_ORDERS}`,
    );
  }

  public getAllCarsByCarServiceId(id: number): Promise<CountResponse> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.WIDGET}${WidgetApiPath.CAR_SERVICE}/${id}${WidgetApiPath.CAR}`,
    );
  }

  public getAllRequestsByCarServiceId(id: number): Promise<CountResponse> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.WIDGET}${WidgetApiPath.CAR_SERVICE}/${id}${WidgetApiPath.REQUEST}`,
    );
  }

  public getNotCompletedTasksByCarServiceId(id: number): Promise<CountResponse> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.WIDGET}${WidgetApiPath.CAR_SERVICE}/${id}${WidgetApiPath.NOT_COMPLETED_REQUEST}`,
    );
  }
}

export { WidgetApi };
