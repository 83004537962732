import { createSlice } from '@reduxjs/toolkit';
import { LocalStorageKey } from 'common/enums/enums';
import { storage } from 'services/services';

export interface IAppState {
  isOpenLeftMenu: boolean
}

const initialState: IAppState = {
  isOpenLeftMenu: storage.getBoolean(LocalStorageKey.OPEN_LEFT_MENU, true),
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    openLeftMenu: (state, { payload }):void => {
      state.isOpenLeftMenu = payload;
      storage.setItem(LocalStorageKey.OPEN_LEFT_MENU, payload);
    },
  },
});

const appReducer = appSlice.reducer;
const { openLeftMenu } = appSlice.actions;

export { appReducer, openLeftMenu };
