import { CCol, CFormInput, CFormLabel, CRow } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/translation-key/app-translation-key.enum';
import { CarModalFormData } from 'common/types/types';
import { onlyNumberString } from 'helpers/helpers';
import React, { FC } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  register: UseFormRegister<CarModalFormData>,
  setValue: UseFormSetValue<CarModalFormData>,
  disabled?: boolean,
};

const CreateYearField: FC<Props> = ({ disabled = false, register, setValue }) => {
  const { t } = useTranslation(AppTranslationKey.CAR_MODAL);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="createYear" className="col-sm-3 col-form-label">
        {t('createYear')}
      </CFormLabel>
      <CCol sm={9}>
        <CFormInput
          disabled={disabled}
          { ...register('createYear') }
          id="createYear"
          type="text"
          onChange={(event): void => setValue('createYear', onlyNumberString(event.target.value))}
        />
      </CCol>
    </CRow>
  );
};

export { CreateYearField };
