enum AppRoute {
  ROOT = '/',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  PERSONAL = '/personal',
  HELP = '/help',
  SERVICES = '/services',
  RECOVERY_PASSWORD = '/recovery-password',
  CHANGE_PASSWORD_$UUID = '/change-pass/:uuid',
  ANY = '*',
  VALIDATE = '/validate',
  ERROR = '/error',
  EMPLOYEES = '/employees',
}

export { AppRoute };
