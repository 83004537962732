import { SparePartForm } from 'common/types/types';

const DEFAULT_SPARE_PART_MODAL_PAYLOAD: SparePartForm = {
  sparePartId: undefined,
  sparePartsTypeId: null,
  sparePartsName: '',
  sparePartsInfo: '',
  counterpartyId: -1,
  valueCurrent: null,
  valueMin: null,
  priceSell: null,
  originalCode: '',
};

export { DEFAULT_SPARE_PART_MODAL_PAYLOAD };
