import { BindOrder, OrderDto } from 'common/types/types';
import { getFormattedDate } from 'helpers/helpers';

const orderDtoToBindOrderMap = (orderDto: OrderDto): BindOrder => {
  return {
    orderId: orderDto.id,
    statusColor: orderDto?.status.color,
    statusName: orderDto?.status.name,
    statusTextColor: orderDto?.status.colorFont,
    comment: orderDto.comment ?? '',
    carInfo: [orderDto?.car?.carBrandName ?? '', orderDto?.car?.carModelName ?? '', orderDto?.car?.carRegNum ?? ''],
    problemDescription: orderDto.problemDescription,
    dateTime: (orderDto.dateCarReceive && getFormattedDate(orderDto?.dateCarReceive,'yyyy-MM-dd HH:mm' )) ?? '-',
    work: orderDto.works,
    clientSpareParts: orderDto.sparePartsFromClient,
    carId: orderDto.car?.carId ?? 0,
  };
};

export { orderDtoToBindOrderMap };
