import { CCard, CCardHeader, CContainer } from '@coreui/react-pro';
import { AppRoute, AppTranslationKey, DataStatus, PersonalRoute } from 'common/enums/enums';
import { SupplierAddRequestDto } from 'common/types/types';
import { useAppDispatch, useAppSelector, useEffect, useNavigate, useParams } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { supplierActions } from 'store/actions';
import { resetEditPage } from 'store/supplier/reducer';

import { SupplierForm } from '../supplier-form/supplier-form';

const EditSupplier: FC = () => {
  const { supplierId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(AppTranslationKey.SUPPLIERS);
  const { currentSupplier, dataStatusEdit } = useAppSelector(({ supplier }) => supplier);

  useEffect(() => {
    if (supplierId) {
      dispatch(supplierActions.getSupplierById({ supplierId: +supplierId }));
    }

    return () => {
      dispatch(resetEditPage());
    };
  }, [dispatch, supplierId]);

  useEffect(() => {
    if (dataStatusEdit === DataStatus.FULFILLED) {
      navigate(`${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.SUPPLIERS}`);
    }
  }, [dataStatusEdit, navigate]);

  const handleConfirm = (supplier: SupplierAddRequestDto): void => {
    let counterpartyStatusId = supplier.counterpartyStatusId === -1 ? undefined : supplier.counterpartyStatusId;

    counterpartyStatusId = counterpartyStatusId ? (+counterpartyStatusId) : undefined;

    if (supplierId) {
      dispatch(supplierActions.editSupplier({
        ...supplier,
        counterpartyStatusId,
        counterpartyId: +supplierId,
      }));
    }
  };

  return (
    <CContainer fluid>
      <CCard>
        <CCardHeader>
          {t('editSupplierTitle')}
        </CCardHeader>
        <SupplierForm onSubmit={handleConfirm} supplier={currentSupplier}/>
      </CCard>
    </CContainer>
  );
};

export { EditSupplier };
