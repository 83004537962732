import { AppTranslationKey, Pages, PersonalRoute } from 'common/enums/enums';
import { WithCarServicesTabs } from 'common/types/types';
import { CarServiceDetails, usePermissionToastContext } from 'components/common/common';
import { Tariffs } from 'constants/tariffs';
import { useExtraButtonContext } from 'contexts/extra-button-context';
import { withCarServicesTabs } from 'hoc/hoc';
import { useEffect, useNavigate } from 'hooks/hooks';
import { t } from 'i18next';
import React, { FC } from 'react';

const CarServicesProfiles: FC<WithCarServicesTabs> = ( { activeCarService } ) => {
  const { setExtraButton, removeExtraButton } = useExtraButtonContext();
  const navigate = useNavigate();
  const { onPermissionAction } = usePermissionToastContext();

  useEffect(() => {
    setExtraButton({
      isHide: false,
      translateTitleKey: `${AppTranslationKey.CAR_SERVICE}:addCarServiceCaptionButton`,
      action: onPermissionAction(
        () => navigate(PersonalRoute.ADD_CAR_SERVICE.split('/').pop() ?? 'add'),
        Tariffs.NO_TARIFF,
      ),
    });

    return () => {
      removeExtraButton();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (<CarServiceDetails carService={activeCarService}/>);
};

export const CarServicesProfilesWithCarServicesTabs = withCarServicesTabs(
  CarServicesProfiles,
  false,
  Pages.CAR_SERVICE,
  () => t('mainMenu:carServicesNavItem') ?? '',
);
