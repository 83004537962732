import { ApiPath, ContentType, HttpMethod, SupplierApiPath } from 'common/enums/enums';
import { SupplierAddRequestDto,SupplierDto, SupplierEditRequestDto } from 'common/types/types';
import { Http } from 'services/http/http.service';

type Constructor = {
  http: Http;
  apiPrefix: string;
};

class SupplierApiService {
  #http: Http;

  #apiPrefix: string;

  public constructor({ http, apiPrefix }: Constructor) {
    this.#http = http;
    this.#apiPrefix = apiPrefix;
  }

  public create(payload: SupplierAddRequestDto): Promise<SupplierDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.SUPPLIERS}`,
      {
        method: HttpMethod.POST,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }

  public getList({ userId }: { userId: number }): Promise<SupplierDto[]> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.SUPPLIERS}${SupplierApiPath.USER}/${userId}`,
    );
  }

  public getById({ supplierId }: { supplierId: number }): Promise<SupplierDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.SUPPLIERS}/${supplierId}`,
    );
  }

  public edit(payload: SupplierEditRequestDto): Promise<SupplierDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.SUPPLIERS}/${payload.counterpartyId}`,
      {
        method: HttpMethod.PUT,
        contentType: ContentType.JSON,
        payload: JSON.stringify(payload),
      },
    );
  }
}

export { SupplierApiService };
