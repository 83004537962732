enum HelpAboutRequestKey {
  TITLE = 'title',
  DESCRIPTION = 'description',
  ACTIVE_STATUSES_TITLE = 'activeStatusesTitle',
  NO_ACTIVE_STATUSES_TITLE = 'noActiveStatusesTitle',
  A_IN_WORK = 'aInWork',
  A_WAIT_SPARE_PARTS = 'aWaitSpareParts',
  A_IN_WORK_CONTRACTOR = 'aInWorkContractor',
  A_WORKS_FINISHED = 'aWorksFinished',
  A_WAIT_PAY = 'aWaitPay',
  A_WAIT_GIVE_CAR = 'aWaitGiveCar',
  NA_NEW = 'naNew',
  NA_WAIT_AGREEMENT = 'naWaitAgreement',
  NA_AGREEMENT = 'naAgreement',
  NA_WAIT_RECEIVE_CAR = 'naWaitReceiveCar',
  NA_WAIT_WORK = 'naWaitWork',
  NA_CAR_GAVE = 'naCarGave',
  NA_CLOSE = 'naClose',
  NA_CANCEL = 'naCancel',
}

export { HelpAboutRequestKey };
