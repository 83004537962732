import { CSmartTable } from '@coreui/react-pro';
import { AppTranslationKey, DataStatus } from 'common/enums/enums';
import { SupplierTableRaw } from 'common/types/types';
import {
  NoData,
  NoDataWithAction,
  Spinner,
  ToggleShowDetailsBtn,
  usePermissionToastContext,
} from 'components/common/common';
import { Tariffs } from 'constants/tariffs';
import { getItemPerPageOptions } from 'helpers/helpers';
import { useAppSelector, useMemo, useNavigate,useState  } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getColumns } from './get-columns';
import { getData } from './get-data';
import { SupplierItemDetails } from './supplier-item-details';

const SuppliersTable: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation([AppTranslationKey.SUPPLIERS, AppTranslationKey.COMMON]);
  const { onPermissionAction } = usePermissionToastContext();

  const { list, dataStatus } = useAppSelector(({ supplier }) => ({
    list: supplier.list,
    dataStatus: supplier.dataStatusList,
  }));

  const isListLoad = dataStatus === DataStatus.PENDING;
  const columns = getColumns(t('tableColumns'));

  const items = useMemo(() => getData(list), [list]);
  const itemsPerPage: number[] = useMemo( () => getItemPerPageOptions(items.length), [items] );
  const [openRows, setOpenRow] = useState<number[]>([]);

  const onToggleRow = (id: number): void => {
    if(openRows.includes(id)) {
      setOpenRow((prevState) => prevState.filter((item) => item !== id));
    } else {
      setOpenRow((prevState) => [...prevState, id]);
    }
  };

  if (!list.length) {
    return <NoDataWithAction
      title={t('noDataTitle')}
      message={t('noDataText')}
      actionName={t('addButtonTitle')}
      callback={onPermissionAction(() => navigate('add'), Tariffs.NO_TARIFF)}
    />;
  }

  if (isListLoad) {
    return <Spinner isOverflow containerHeight={150}/>;
  }

  return (
    <CSmartTable
      columns={ columns }
      columnSorter
      sorterValue={{ column: 'number', state: 'desc' }}
      items={ items }
      columnFilter
      itemsPerPageOptions={itemsPerPage}
      itemsPerPageSelect
      noItemsLabel={
        <NoData
          title={t('noItemsFoundTitle', { ns: AppTranslationKey.COMMON })}
          message={t('noItemsFoundMsg', { ns: AppTranslationKey.COMMON })}
        />
      }
      itemsPerPageLabel={t('itemsPerPageLabel') ?? 'Items per page'}
      pagination
      scopedColumns={{
        showDetails: (item: SupplierTableRaw) =>
          <ToggleShowDetailsBtn
            toggleDetails={(): void => onToggleRow(item.number)}
            isOpen={ openRows.includes(item.number) }
          />,
        details: (item) => (
          <SupplierItemDetails
            item={ item as SupplierTableRaw }
            visible={ openRows.includes(item.number) }
          />
        ),
      }}
      tableHeadProps={{
        color: 'light',
      }}
      tableProps={{
        striped: true,
        hover: true,
      }}
    />
  );
};

export { SuppliersTable };
