import { CCardTitle, CForm, CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react-pro';
import {
  AppTranslationKey,
  SelectSparePartsModalTranslationKey, SelectSparePartsTableTranslationKey,
} from 'common/enums/enums';
import { SparePartsBindToWorkOrder, UserSpareParts } from 'common/types/types';
import { NoData, SelectedSparePartsTable, SelectSparePartsTable, TotalField } from 'components/common/common';
import { ModalFooter } from 'components/modals/components/components';
import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type Props = {
  isOpen: boolean,
  onClose: () => void,
  userSpareParts: UserSpareParts[],
  onEditSelectedWork: () => void,
  onChangePrice: (value: string, sparePartId: number) => void,
  onRemoveSelectedSpareParts: (sparePartId: number) => void,
  onChangeQty: (value: string, sparePartId: number) => void,
  isLoading: boolean,
  onSelectSpareParts: (item: UserSpareParts) => void,
  selectedSpareParts: SparePartsBindToWorkOrder[],
};

const SelectSparePartsModal: FC<Props> = ({
  isOpen,
  onClose,
  userSpareParts,
  selectedSpareParts,
  onSelectSpareParts,
  isLoading,
  onEditSelectedWork,
  onChangePrice,
  onChangeQty,
  onRemoveSelectedSpareParts,
}) => {
  const { t } = useTranslation(AppTranslationKey.SELECT_SPARE_PARTS_MODAL);
  const { t: tTable } = useTranslation(AppTranslationKey.SELECT_SPARE_PARTS_TABLE);

  const TotalModalSum = (): ReactElement => {
    const label = tTable(SelectSparePartsTableTranslationKey.TOTAL_SPARE_PARTS_SUM);
    const items = selectedSpareParts.map((it) => (
      parseFloat(it.count as string) * parseFloat(it.priceOne as string)
    ));

    return <TotalField label={ label } items={ items } />;
  };

  return (
    <CModal
      visible={isOpen}
      backdrop="static"
      onClose={onClose}
      size="xl"
      scrollable
    >
      <CModalHeader>
        <CModalTitle>
          {t(SelectSparePartsModalTranslationKey.TITLE)}
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div>
          <div className={styles.sparePartsTableWrap}>
            <SelectSparePartsTable
              spareParts={userSpareParts}
              selectedSpareParts={selectedSpareParts}
              onSelectSpareParts={onSelectSpareParts}
              isLoading={isLoading}
            />
          </div>
          <CCardTitle className={styles.cardTitle}>
            {t(SelectSparePartsModalTranslationKey.SELECTED)} ({selectedSpareParts.length})
          </CCardTitle>
          {
            selectedSpareParts.length > 0 ? <div className={styles.selectedWorkTableWrap}>
              <SelectedSparePartsTable
                onEdit={onEditSelectedWork}
                onChangePrice={onChangePrice}
                selectedSpareParts={selectedSpareParts}
                isCanRemove={true}
                onRemoveSelectedSpareParts={ (spareParts):void => {
                  onRemoveSelectedSpareParts(spareParts.id);
                } }
                onChangeQty={onChangeQty}
              />
            </div> : <NoData
              title={t(SelectSparePartsModalTranslationKey.NO_SELECT)} message=""
            />
          }
        </div>
      </CModalBody>
      <CForm
        className="form-scroll"
      >
        <ModalFooter
          hasModifyBtn={false}
          isUpdateData={false}
          desc={ <TotalModalSum /> }
          onClose={onClose}
        />
      </CForm>

    </CModal>
  );
};

export { SelectSparePartsModal };
