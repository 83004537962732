import { cilPen, cilSave, cilTrash,cilXCircle } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CForm, CFormFeedback } from '@coreui/react-pro';
import cn from 'classnames';
import { AppTranslationKey, DangerAlertModalMode } from 'common/enums/enums';
import { usePermissionToastContext } from 'components/common/common';
import { DangerAlertModal } from 'components/modals/modals';
import { useAppDispatch,useEffect } from 'hooks/hooks';
import { phone as checkPhone } from 'phone';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { userActions } from 'store/actions';

import { getUserById } from '../../store/user/actions';
import styles from './styles.module.scss';

type Props = {
  userId: number,
  phone: string,
};

const UserPhoneValue: FC<Props> = ({ userId, phone }) => {
  const { t: tCommon } = useTranslation('common');
  const { t } = useTranslation(AppTranslationKey.PROFILE);
  const dispatch = useAppDispatch();
  const { onPermissionAction } = usePermissionToastContext();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isSave, setIsSave] = useState(false);
  const [isDangerAlertModalOpen, setIsDangerAlertModalOpen] = useState<boolean>(false);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{ phone: string }>({ defaultValues: { phone } });

  const onEdit = ():void => {
    setIsEdit(true);
  };

  const onDelete = ():void => {
    dispatch(userActions.changePhoneUser({ userId, phone: '' })).then(() => {
      dispatch(getUserById(userId));
      setIsDangerAlertModalOpen(false);
    });
  };

  const onCancel = ():void => {
    reset({ phone });
    setIsEdit(false);
  };

  const onSubmit = ({ phone }: { phone: string }): void => {
    dispatch(userActions.changePhoneUser({ userId, phone })).then(() => {
      dispatch(getUserById(userId));
    });
    setIsSave(true);
  };

  const onChangePhone = (value: string): void => {
    setValue('phone', value);
  };

  const handleDangerAlertClose = (): void => {
    setIsDangerAlertModalOpen(false);
  };

  const handleDangerAlertOpen = (): void => {
    setIsDangerAlertModalOpen(true);
  };

  useEffect(() => {
    if (isSave) {
      setIsSave(false);
      setIsEdit(false);
    }
  }, [isSave]);

  useEffect(() => {
    reset({ phone });
  }, [phone, reset]);

  useEffect(() => {
    register('phone',
      {
        validate: (value) => checkPhone(`+${ value }`).isValid || t('errorPhone') as string,
      });
  }, [register, t]);

  return (
    <CForm
      className={cn(styles.profileDataValue, styles.profileDataValueEdit)}
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <span className={styles.profileDataValueContent}>
        {
          isEdit ? (
            <div className="position-relative">
              <PhoneInput
                country={'ua'}
                placeholder=""
                countryCodeEditable={false}
                enableSearch
                disableSearchIcon
                value={phone}
                onChange={onChangePhone}
                containerClass={ cn( { 'is-invalid': Boolean(errors.phone) }) }
                inputClass={ styles.phoneInput }
                buttonClass={ styles.phoneDropdown }
              />
              <CFormFeedback invalid={ Boolean(errors.phone) } tooltip>
                { errors.phone?.message }
              </CFormFeedback>
            </div>
          )
            : <span>{phone ? `+${phone}` : ''}</span>
        }
      </span>
      <div className={styles.profileDataValueActions}>
        {
          isEdit
            ? [
              <CButton
                color="danger"
                variant="ghost"
                size="sm"
                title={tCommon('cancelBtn') as string}
                key="profileButtonCancel"
                className={styles.profileDataValueButton}
                onClick={onCancel}
              >
                <CIcon icon={cilXCircle} size="sm"/>
              </CButton>,
              <CButton
                color="info"
                variant="ghost"
                size="sm"
                title={tCommon('saveBtn') as string}
                key="profileButtonSave"
                className={styles.profileDataValueButton}
                type="submit"
              >
                <CIcon icon={cilSave} size="sm"/>
              </CButton>,
            ]
            : [
              <CButton
                color="info"
                variant="ghost"
                size="sm"
                title={tCommon('editButton') as string}
                key="profileButtonEdit"
                className={styles.profileDataValueButton}
                onClick={onPermissionAction(onEdit)}
              >
                <CIcon icon={cilPen} size="sm"/>
              </CButton>,
              <CButton
                color="danger"
                variant="ghost"
                size="sm"
                title={tCommon('removeBtn') as string}
                key="profileButtonRemove"
                className={styles.profileDataValueButton}
                onClick={handleDangerAlertOpen}
              >
                <CIcon icon={cilTrash} size="sm"/>
              </CButton>,
            ]
        }
        <DangerAlertModal
          mode={ DangerAlertModalMode.DELETE_USER_PHONE }
          isVisible={ isDangerAlertModalOpen }
          onClose={ handleDangerAlertClose }
          onConfirm={ onDelete }
        />
      </div>
    </CForm>
  );
};

export { UserPhoneValue };
