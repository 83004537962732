import { AppTranslationKey } from 'common/enums/enums';
import { UserTariff } from 'common/types/types';
import { getFormattedDate } from 'helpers/helpers';
import { i18next } from 'i18n';

import styles from './styles.module.scss';

const getDate = (date: string | null, defaultValue: string): string => (
  date ? getFormattedDate(date, 'dd.MM.yyyy') : defaultValue
);

const getActivePanelConfig = (
  tariffId: number,
  currentTariff: UserTariff | null,
  nextTariff: UserTariff | null,
): { isShow: boolean, text: string, className: string } => {
  let isShow = false;
  let text = '';
  let className = styles.emptyPanel;

  if (tariffId === currentTariff?.tariffId && !!currentTariff.isActive) {
    isShow = true;
    text = i18next.t(
      `${AppTranslationKey.TARIFFS}:activePanelText`,
      { endDate: getDate(currentTariff?.dateFinish, '') },
    );
    className = styles.currentPanel;
  } else if (tariffId === nextTariff?.tariffId) {
    isShow = true;
    text = i18next.t(
      `${AppTranslationKey.TARIFFS}:planedPanelText`,
      { startDate: getDate(nextTariff?.dateStart, '') },
    );
    className = styles.nextPanel;
  } else if (tariffId === currentTariff?.tariffId && !currentTariff.isActive) {
    isShow = true;
    text = i18next.t(
      `${AppTranslationKey.TARIFFS}:finishedPanelText`,
      { endDate: getDate(currentTariff?.dateFinish, '') },
    );
    className = styles.finishedPanel;
  }

  return { isShow, text, className };
};

export { getActivePanelConfig };
