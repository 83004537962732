import 'react-toastify/dist/ReactToastify.css';

import { FC } from 'react';
import { ToastContainer } from 'react-toastify';

const Toast: FC = () => {
  return <ToastContainer />;
};

export { Toast };
