import { ChangePasswordForm, ChangePasswordRequestDto } from 'common/types/types';
import { useAppDispatch, useState } from 'hooks/hooks';
import { userActions } from 'store/actions';

type UseChangePasswordModalType = {
  isOpen: boolean,
  onOpen: () => void,
  onClose: () => void,
  onSave: (data: ChangePasswordForm) => void,
};

const useChangePasswordModal = (userId: number): UseChangePasswordModalType => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = (): void => {
    setIsOpen(true);
  };

  const onClose = (): void => {
    setIsOpen(false);
  };

  const onSave = (data: ChangePasswordForm): void => {
    const payload: ChangePasswordRequestDto = {
      newPassword: data.newPassword,
      oldPassword: data.oldPassword,
      userId,
    };

    dispatch(userActions.changePassword(payload));
  };

  return {
    isOpen,
    onOpen,
    onClose,
    onSave,
  };
};

export { useChangePasswordModal };
