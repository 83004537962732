import { AppRoute, EmployeesRoute, HelpRoute, PersonalRoute, ServicesRoute } from 'common/enums/enums';
import { AboutRequest } from 'components/about-request/about-request';
import { Auth } from 'components/auth/auth';
import { ChangePassword } from 'components/auth/components/components';
import { AutoWithCarServicesTabs } from 'components/car-services-auto/car-services-auto';
import { CarServicesCustomers } from 'components/car-services-customers/car-services-customers';
import { CarServicesOrdersWithCarServiceTabs } from 'components/car-services-orders/car-services-orders';
import { AddCarService } from 'components/car-services-profiles/add-car-service/add-car-service';
import { CarServicesProfilesWithCarServicesTabs } from 'components/car-services-profiles/car-services-profiles';
import { EditCarService } from 'components/car-services-profiles/edit-car-service/edit-car-service';
import { WorkOrdersCarServicesTabs } from 'components/car-services-work-orders/car-services-work-orders';
import { ProtectedRoute } from 'components/common/common';
import { DashboardWithCarServiceTabs } from 'components/dashboard/dashboard';
import { StorageProfilesWithCarServicesTabs } from 'components/employees-profiles/employees-profiles';
import { CarWorkWithServiceTabs } from 'components/fav-car-work/fav-car-work';
import { VideoPreview } from 'components/help-desk/components/components';
import { InfoVin } from 'components/info-vin/info-vin';
import { MainLayout } from 'components/main-layout/main-layout';
import { Page404 } from 'components/page-404/page-404';
import { ErrorPage } from 'components/page-error/error-page';
import { Profile } from 'components/profile/profile';
import { SpareParts } from 'components/spare-parts/spare-parts';
import { AddStorage } from 'components/storage-profiles/add-storage/add-storage';
import { EditStorage } from 'components/storage-profiles/edit-storage/edit-storage';
import { StorageProfilesWithStoragesTabs } from 'components/storage-profiles/storages-profiles';
import { AddSupplier } from 'components/suppliers/add-supplier/add-supplier';
import { EditSupplier } from 'components/suppliers/edit-supplier/edit-supplier';
import { Suppliers } from 'components/suppliers/suppliers';
import { Tariffs } from 'components/tariffs/tariffs';
import { ValidateUser } from 'components/validate-user/validate-user';

export const route = [

  ...[AppRoute.ROOT, AppRoute.SIGN_IN, AppRoute.SIGN_UP, AppRoute.RECOVERY_PASSWORD].map( (path) => ({
    path,
    element: <Auth />,
  }) ),
  {
    path: AppRoute.CHANGE_PASSWORD_$UUID,
    element: <ChangePassword />,
  },
  {
    path: AppRoute.ERROR,
    element: <ErrorPage />,
  },
  {
    path: AppRoute.PERSONAL,
    element: <ProtectedRoute component={<MainLayout/>} />,
    children: [
      {
        path: AppRoute.ANY,
        element: <Page404/>,
      },
      {
        path: PersonalRoute.DASHBOARDS,
        element: <DashboardWithCarServiceTabs />,
      },
      {
        path: PersonalRoute.PROFILE,
        element: <Profile/>,
      },
      {
        path: PersonalRoute.CAR_SERVICES_ORDERS,
        element: <CarServicesOrdersWithCarServiceTabs />,
      },
      {
        path: PersonalRoute.EDIT_CAR_SERVICE_$CAR_SERVICE_ID,
        element: <EditCarService/>,
      },
      {
        path: PersonalRoute.ADD_CAR_SERVICE,
        element: <AddCarService/>,
      },
      {
        path: PersonalRoute.CAR_SERVICES_PROFILES,
        element: <CarServicesProfilesWithCarServicesTabs />,
      },
      {
        path: PersonalRoute.CAR_SERVICES_CUSTOMERS,
        element: <CarServicesCustomers />,
      },
      {
        path: PersonalRoute.CAR_SERVICES_AUTO,
        element: <AutoWithCarServicesTabs />,
      },
      {
        path: PersonalRoute.SUPPLIERS,
        element: <Suppliers />,
      },
      {
        path: PersonalRoute.STORAGE_PROFILES,
        element: <StorageProfilesWithStoragesTabs />,
      },
      {
        path: PersonalRoute.ADD_STORAGE,
        element: <AddStorage />,
      },
      {
        path: PersonalRoute.EDIT_STORAGE_$STORAGE_ID,
        element: <EditStorage />,
      },
      {
        path: PersonalRoute.ADD_SUPPLIER,
        element: <AddSupplier />,
      },
      {
        path: PersonalRoute.EDIT_SUPPLIER_$SUPPLIER_ID,
        element: <EditSupplier />,
      },
      {
        path: PersonalRoute.WORK,
        element: <CarWorkWithServiceTabs />,
      },
      {
        path: PersonalRoute.SPARE_PARTS,
        element: <SpareParts />,
      },
      {
        path: PersonalRoute.WORK_ORDERS,
        element: <WorkOrdersCarServicesTabs />,
      },
    ],
  },
  {
    path: AppRoute.VALIDATE,
    element: <ValidateUser />,
  },
  {
    path: AppRoute.SERVICES,
    element: <ProtectedRoute component={<MainLayout/>} />,
    children: [
      {
        path: ServicesRoute.INFO_VIN,
        element: <InfoVin />,
      },
    ],
  },
  {
    path: AppRoute.HELP,
    element: <ProtectedRoute component={ <MainLayout/> } />,
    children: [
      {
        path: HelpRoute.VIDEO_REVIEW,
        element: <VideoPreview />,
      },
      {
        path: HelpRoute.TARIFFS,
        element: <Tariffs />,
      },
      {
        path: HelpRoute.ABOUT_REQUEST,
        element: <AboutRequest />,
      },
    ],
  },
  {
    path: AppRoute.EMPLOYEES,
    element: <ProtectedRoute component={ <MainLayout/> } />,
    children: [
      {
        path: EmployeesRoute.PROFILES,
        element: <StorageProfilesWithCarServicesTabs />,
      },
    ],
  },
  {
    path: AppRoute.ANY,
    element: <Page404/>,
  },
];
