import { CCol, CFormLabel, CRow, CTimePicker } from '@coreui/react-pro';
import { AppTranslationKey, WorkOrderModalTranslationKey, WorkOrderTranslationKey } from 'common/enums/enums';
import { WorkOrderFormData } from 'common/types/types';
import React, { FC, ReactElement } from 'react';
import DatePicker from 'react-datepicker';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  isReadOnly: boolean,
  control:  Control<WorkOrderFormData> | undefined,
};

const WorkOrderDateTimeRow: FC<Props> = ({ isReadOnly = false, control }) => {

  const { t, i18n } = useTranslation(AppTranslationKey.WORK_ORDER);

  const onShowTime = (): void => {
    const timeMinutesContainer = document.querySelector('.js-car-receive-time .time-picker-roll-col:nth-child(2)');

    if (timeMinutesContainer && timeMinutesContainer.children.length > 12) {
      [].forEach.call(timeMinutesContainer.children, (child:HTMLElement, index) => {
        if(index % 15 !== 0 ) {
          child.style.display = 'none';
        }
      });
    }
  };

  return (
    <CRow className="mb-3">
      <CFormLabel className="col-sm-2 col-form-label">
        {t(`${WorkOrderTranslationKey.WORK_ORDER_MODAL}.${WorkOrderModalTranslationKey.DATE}`)}
      </CFormLabel>
      <CCol className="react-calendar-wrapper">
        <Controller
          name="workOrderDate"
          render={({ field }): ReactElement => (
            <DatePicker
              disabled={isReadOnly}
              id="workOrderDate"
              selected={field.value}
              locale={ i18n.language || 'uk' }
              placeholderText={'Date' ?? ''}
              onChange={(value): void => field.onChange(value)}
              dateFormat="dd.MM.yyyy"
              calendarStartDay={1}
            />
          )}
          control={control}/>
      </CCol>
      <CFormLabel className="col-sm-2 col-form-label" style={{ textAlign: 'end' }}>
        {t(`${WorkOrderTranslationKey.WORK_ORDER_MODAL}.${WorkOrderModalTranslationKey.TIME}`)}
      </CFormLabel>
      <CCol >
        <Controller
          name="workOrderTime"
          render={({ field }): ReactElement => (
            <CTimePicker
              disabled={isReadOnly}
              id="workOrderTime"
              className="js-car-receive-time"
              inputReadOnly
              cleaner={false}
              time={field.value}
              locale="uk-UA"
              seconds={false}
              onShow={onShowTime}
              placeholder={t('time') ?? ''}
              onTimeChange={
                (timeString, localeTimeString, value): void =>
                  field.onChange(value ?? null )
              }
            />
          )}
          control={control}/>
      </CCol>
    </CRow>
  );
};

export { WorkOrderDateTimeRow };
