import { CCard, CCardHeader, CContainer } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { CarServiceEditRequestDto } from 'common/types/types';
import { CarServiceForm } from 'components/car-services-profiles/car-service-form/car-service-form';
import { useAppDispatch, useNavigate } from 'hooks/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { carServicesActions } from 'store/actions';

const AddCarService: FC = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(AppTranslationKey.CAR_SERVICE);

  const handleConfirmEdit = (carService: CarServiceEditRequestDto): void => {
    dispatch(carServicesActions.createCarService(carService))
      .unwrap()
      .then(() => navigate(-1));
  };

  return (
    <CContainer fluid>
      <CCard>
        <CCardHeader>
          {t('addCarServiceTitle')}
        </CCardHeader>
        <CarServiceForm onSubmit={handleConfirmEdit}/>
      </CCard>
    </CContainer>
  );
};

export { AddCarService };
