import { cilPen, cilSave, cilXCircle } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CForm, CFormInput } from '@coreui/react-pro';
import cn from 'classnames';
import { AppTranslationKey } from 'common/enums/enums';
import { usePermissionToastContext } from 'components/common/common';
import { useAppDispatch,useEffect } from 'hooks/hooks';
import truncate from 'lodash/truncate';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { userActions } from 'store/actions';

import { getUserById } from '../../store/user/actions';
import styles from './styles.module.scss';

type Props = {
  userId: number,
  name: string,
};

const UserNameValue: FC<Props> = ({ userId, name }) => {
  const { t: tCommon } = useTranslation('common');
  const { t } = useTranslation(AppTranslationKey.PROFILE);
  const dispatch = useAppDispatch();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isSave, setIsSave] = useState(false);

  const { onPermissionAction } = usePermissionToastContext();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{ userName: string }>({ defaultValues: { userName: name } });

  const onEdit = ():void => {
    setIsEdit(true);
  };

  const onCancel = ():void => {
    reset({ userName: name });
    setIsEdit(false);
  };

  const onSubmit = ({ userName }: { userName: string }):void => {
    dispatch(userActions.changeNameUser({ userId, name: userName })).then(() => {
      dispatch(getUserById(userId));
    });
    setIsSave(true);
  };

  useEffect(() => {
    if (isSave) {
      setIsSave(false);
      setIsEdit(false);
    }
  }, [isSave]);

  useEffect(() => {
    reset({ userName: name });
  }, [name, reset]);

  return (
    <CForm
      className={ cn(styles.profileDataValue, styles.profileDataValueEdit) }
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <span className={ styles.profileDataValueContent }>
        {
          isEdit
            ? (
              <CFormInput
                { ...register('userName', {
                  required: t('requiredName') ?? 'Required',
                }) }
                className={ styles.profileDataValueContentInput }
                invalid={Boolean(errors.userName)}
                feedbackInvalid={errors.userName?.message}
                tooltipFeedback
              />
            )
            : <span title={ name }>{truncate(name, { length: 40 })}</span>
        }
      </span>
      <div className={ styles.profileDataValueActions }>
        {
          isEdit
            ? [
              <CButton
                color="danger"
                variant="ghost"
                size="sm"
                title={ tCommon('cancelBtn') as string }
                key="profileButtonCancel"
                className={ styles.profileDataValueButton }
                onClick={ onCancel }
              >
                <CIcon icon={cilXCircle} size="sm"/>
              </CButton>,
              <CButton
                color="info"
                variant="ghost"
                size="sm"
                title={ tCommon('saveBtn') as string }
                key="profileButtonSave"
                className={ styles.profileDataValueButton }
                type="submit"
              >
                <CIcon icon={cilSave} size="sm"/>
              </CButton>,
            ]
            : (
              <CButton
                color="info"
                variant="ghost"
                size="sm"
                title={ tCommon('editButton') as string }
                key="profileButtonEdit"
                className={ styles.profileDataValueButton }
                onClick={ onPermissionAction(onEdit) }
              >
                <CIcon icon={cilPen} size="sm"/>
              </CButton>
            )
        }
      </div>
    </CForm>
  );
};

export { UserNameValue };
