import { App } from 'components/app/app';
import { Toast } from 'components/common/common';
// import { locale as ukLocale } from 'i18n/ukRsuite';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { CustomProvider } from 'rsuite';
// import enUs from 'rsuite/locales/en_US';
import { store } from 'store/store';

import { PermissionToastContextProvider } from './components/common/common';
import { i18next } from './i18n';

const root = createRoot(document.getElementById('root') as HTMLElement);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
root.render(
  // <StrictMode>
  <Provider store={store} >
    <I18nextProvider i18n={i18next}>
      {/*<CustomProvider locale={ i18next.language === 'uk' ? ukLocale : enUs }>*/}
      <CustomProvider>
        <PermissionToastContextProvider>
          <App/>
          <Toast/>
        </PermissionToastContextProvider>
      </CustomProvider>
    </I18nextProvider>
  </Provider>,
  // </StrictMode>,
);
