import { CCol, CFormLabel, CFormSelect, CRow } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { Brand, CarModalFormData } from 'common/types/types';
import React, { FC } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  register: UseFormRegister<CarModalFormData>,
  errors: FieldErrors<CarModalFormData>,
  brands: Brand[],
  disabled?: boolean,
};

const BrandField: FC<Props> = ({
  disabled = false, register, errors, brands ,
}) => {
  const { t } = useTranslation(AppTranslationKey.CAR_MODAL);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="brand" className="col-sm-3 col-form-label">
        {t('brand')}*
      </CFormLabel>
      <CCol sm={9}>
        <CFormSelect
          disabled={disabled}
          { ...register('carBrandId',
            {
              required: t('requiredFormField') ?? 'This field is required',
              validate: (value) => value > -1 || t('requiredFormField') as string,
            },
          ) }
          id="brand"
          invalid={Boolean(errors.carBrandId)}
          feedbackInvalid={errors.carBrandId?.message}
        >
          <option key={-1} value={-1}>{t('selectBrand')}</option>
          {
            brands.map((brand) => (
              <option key={brand.id} value={brand.id}>
                {brand.name}
              </option>
            ))
          }
        </CFormSelect>
      </CCol>
    </CRow>
  );
};

export { BrandField };
