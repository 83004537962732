import { AppColor } from 'common/enums/enums';
import { CarDto, CarsTableRow } from 'common/types/types';

const getCarsTableData = (cars: CarDto[]): CarsTableRow[] =>
  cars.map<CarsTableRow>((item) => {

    return {
      carId: item.carId,
      carRegNum: item.carRegNum,
      carBrandName: item?.carBrandName,
      carModelName: item?.carModelName,
      carColorName: item.carColorName,
      carColorHtmlCode: item?.carColorHtmlCode ?? AppColor.CAR_COLOR.UNKNOWN,
      vinCode: item.vinCode,
      carInfo: item.carInfo,
      carBodyTypeName: item.carBodyTypeName,
      createYear: item.createYear,
      mileageKm: item.mileageKm,
      carColorId: item.carColorId,
      capacity: item.capacity,
    };
  });

export { getCarsTableData };
