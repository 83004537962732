import { AppTranslationKey, NotificationType } from 'common/enums/enums';
import { SparePartsDto } from 'common/types/types';
import { useAppDispatch, useState } from 'hooks/hooks';
import { useTranslation } from 'react-i18next';
import { appActions } from 'store/actions';

type SettingsModalType = {
  isReadOnly: boolean,
  sparePart: SparePartsDto | null,
  stockId: number,
};

const INITIAL_SETTINGS: SettingsModalType = { isReadOnly: false, sparePart: null, stockId: 0 };

type UseSparePartModalType = {
  isReadOnly: boolean,
  sparePart: SparePartsDto | null,
  stockId: number,
  isOpen: boolean,
  setSetting: (newSettings: Partial<SettingsModalType>) => void,
  onOpen: () => void,
  onClose: () => void,
  onReset: () => void,
};

const useSparePartModal = (): UseSparePartModalType => {
  const [ settings, setSettings] = useState<SettingsModalType>(INITIAL_SETTINGS);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation(AppTranslationKey.SPARE_PARTS);
  const dispatch = useAppDispatch();

  const onOpen = (): void => {
    setIsOpen(true);
  };

  const onClose = (): void => {
    setIsOpen(false);
  };

  const setSetting = (newSettings: Partial<SettingsModalType>): void => {

    if(newSettings.stockId) {
      setSettings({ ...settings, ...newSettings });
      onOpen();
    }
    else {
      dispatch(appActions.notify({ type: NotificationType.ERROR, message: t('noStoreErrorMes') }));
    }
  };

  const onReset = (): void => {
    setSettings(INITIAL_SETTINGS);
  };

  return {
    ...settings,
    isOpen,
    setSetting,
    onOpen,
    onClose,
    onReset,
  };
};

export { useSparePartModal };
