import {
  AllCarWorkModalTranslationKey,
  AppTranslationKey,
  CarWorkTranslationKey,
  ClientSparePartsTranslationKey,
} from 'common/enums/enums';
import { TableColumn } from 'common/types/types';
import { t } from 'i18next';

const getClientSparePartsFieldTableColumns = (): TableColumn[] => [
  {
    key: 'sparePartName',
    label: t(`${AppTranslationKey.CLIENT_SPARE_PARTS}:${ClientSparePartsTranslationKey.NAME}`),
    _style: { width: '46%' },
  },
  {
    key: 'orderId',
    label: t(`${
      AppTranslationKey.CAR_WORK
    }:${
      CarWorkTranslationKey.ALL_CAR_WORK_MODAL
    }:${
      AllCarWorkModalTranslationKey.CAR_WORK_NUM_MODAL
    }`),
    _style: { width: '19%' },
  },
  {
    key: 'quantity',
    label: t(`${AppTranslationKey.CLIENT_SPARE_PARTS}:${ClientSparePartsTranslationKey.COUNT}`),
    _style: { width: '10%' },
  },
  {
    key: 'remove',
    label: '',
    _style: { width: '25%' },
  },
];

export { getClientSparePartsFieldTableColumns };
