import { CCol, CFormInput, CFormLabel, CRow } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { CarModalFormData } from 'common/types/types';
import React, { FC } from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  register: UseFormRegister<CarModalFormData>,
  disabled?: boolean,
};

const VinField: FC<Props> = ({ register, disabled = false }) => {
  const { t } = useTranslation(AppTranslationKey.CAR_MODAL);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="vinCode" className="col-sm-3 col-form-label">
        {t('vin')}
      </CFormLabel>
      <CCol sm={9}>
        <CFormInput
          { ...register('vinCode') }
          disabled={disabled}
          id="vinCode"
        />
      </CCol>
    </CRow>
  );
};

export { VinField };
