import React, { FC } from 'react';

import { CarStateLeftSide } from './car-state-left-side';
import styles from './styles.module.scss';

type Props = {
  carStateNum: string;
};
const CarStateNumber: FC<Props> = ({ carStateNum }) => {
  return (
    <div className={styles.carStateNumberWrap}>
      <div className={styles.carStateNum}>
        <CarStateLeftSide />
        <span className={styles.carStateNumRightSide}>{carStateNum || 'XX 0000 XX'}</span>
      </div>
    </div>
  );
};

export { CarStateNumber };
