import { CImage } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { FeedBackModalForm } from 'components/modals/modals';
import { useState } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import FeedbackImage from 'static/feedback-new.png';

import styles from './styles.module.scss';

const FeedbackButton: FC = () => {
  const { t } = useTranslation(AppTranslationKey.COMMON);
  const [ feedBackModalIsOpen, setFeedBackModalIsOpen ] = useState<boolean>(false);

  const handleOpenFeedBackModal = (): void => {
    setFeedBackModalIsOpen(true);
  };

  const handleCloseFeedBackModal = (): void => {
    setFeedBackModalIsOpen(false);
  };

  const onClickFeedback = (): void => {
    handleOpenFeedBackModal();
  };

  return (
    <>
      <div
        className={styles.feedBackButton}
        data-tooltip-id="feedback"
        onClick={onClickFeedback}
      >
        <CImage className="sidebar-brand-full"
          src={FeedbackImage}
          height={25}
        />
        <span>{ t('feedbackBtnTitle') }</span>
      </div>
      <Tooltip
        id="feedback"
        content={ t('feedback') as string }
        place="left"
        className={ styles.feedbackTooltip }
      />
      <FeedBackModalForm
        isOpen={feedBackModalIsOpen}
        isReadOnly={ false }
        isUpdating={ false }
        onCloseModal={handleCloseFeedBackModal}
        onSubmit={handleOpenFeedBackModal}
      />
    </>
  );
};

export { FeedbackButton };
