import { CCol, CFormTextarea, CRow } from '@coreui/react-pro';
import { FeedBackModalKey } from 'common/enums/enums';
import { AppTranslationKey } from 'common/enums/translation-key/app-translation-key.enum';
import { FeedBackSendRequestType } from 'common/types/types';
import { useAppSelector } from 'hooks/hooks';
import React, { FC } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  register: UseFormRegister<FeedBackSendRequestType>,
  titleId?: number,
  disabled?: boolean,
  setValue: UseFormSetValue<FeedBackSendRequestType>,
};

const BodyField: FC<Props> = ({ disabled = false, titleId, register, setValue }) => {
  const { t } = useTranslation(AppTranslationKey.FEED_BACK_MODAL);
  const themes = useAppSelector(({ feedBack }) => feedBack.themes);
  const customPlaceholder = themes.find((item) => item.themeId === titleId)?.placeholder;
  const placeholder =  customPlaceholder || t(FeedBackModalKey.DEFAULT_PLACEHOLDER) as string;

  const onActive = (): void => {
    setValue('body', customPlaceholder || '');
  };

  return (
    <>
      <CRow className="mb-3">
        <CCol sm={12}>
          <CFormTextarea
            disabled={disabled}
            { ...register('body') }
            id="body"
            required={true}
            rows={5}
            placeholder={ placeholder }
            onFocus={ onActive }
          />
        </CCol>
      </CRow>
    </>
  );
};

export { BodyField };
