import { CCard, CCardHeader, CContainer } from '@coreui/react-pro';
import { AppTranslationKey, StorageTranslationKey } from 'common/enums/enums';
import { StorageModifyRequest } from 'common/types/types';
import { StorageForm } from 'components/storage-profiles/storage-form/storage-form';
import { useAppDispatch, useNavigate } from 'hooks/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { storageActions } from 'store/actions';

const AddStorage: FC = () => {
  const { t } = useTranslation(AppTranslationKey.STOCK);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleFormSubmit = (payload: StorageModifyRequest):void => {
    dispatch(storageActions.createStorage(payload))
      .unwrap()
      .then(() => navigate(-1));
  };

  return<CContainer fluid>
    <CCard>
      <CCardHeader>
        {t(StorageTranslationKey.ADD_STORAGE_TITLE)}
      </CCardHeader>
      <StorageForm onSubmit={handleFormSubmit} />
    </CCard>
  </CContainer>;
};

export { AddStorage };
