import { AppTranslationKey, SelectSparePartsTableTranslationKey } from 'common/enums/enums';
import { TableColumn } from 'common/types/types';
import { t } from 'i18next';

const getSelectSparePartsTableColumns = (): TableColumn[] => {

  return [
    {
      key: 'isSelect',
      label: '',
      filter: false,
      sorter: false,
      _style: { width: '5%' },
    },
    {
      key: 'sparePartsName',
      label: t(
        `${
          AppTranslationKey.SELECT_SPARE_PARTS_TABLE
        }:${
          SelectSparePartsTableTranslationKey.SPARE_PARTS_NAME
        }`),
      _style: { width: '35%' },
    },
    {
      key: 'stockName',
      label: t(
        `${
          AppTranslationKey.SELECT_SPARE_PARTS_TABLE
        }:${
          SelectSparePartsTableTranslationKey.SPARE_PARTS_STOCK_NAME
        }`),
      _style: { width: '20%' },
    },
    {
      key: 'valueCurrent',
      label: t(
        `${
          AppTranslationKey.SELECT_SPARE_PARTS_TABLE
        }:${
          SelectSparePartsTableTranslationKey.SPARE_PARTS_QTY
        }`),
      _style: { width: '10%' },
    },
    {
      key: 'priceSell',
      label: t(
        `${
          AppTranslationKey.SELECT_SPARE_PARTS_TABLE
        }:${
          SelectSparePartsTableTranslationKey.SPARE_PARTS_PRICE
        }`),
      _style: { width: '10%' },
    },
    {
      key: 'counterpartyName',
      label: t(
        `${
          AppTranslationKey.SELECT_SPARE_PARTS_TABLE
        }:${
          SelectSparePartsTableTranslationKey.SPARE_PARTS_COUNTERPARTY_NAME
        }`),
      _style: { width: '20%' },
    },
  ];
};

export { getSelectSparePartsTableColumns };
