import { cilCarAlt, cilSettings } from '@coreui/icons';

type ConfigType = {
  icon: string | string[] | undefined,
  titleKey: string,
  fieldData: 'allRequests' | 'notCompletedRequests' | 'allCars',
};

const getDashboardConfig: ConfigType[][] = [
  [
    {
      icon: cilSettings,
      titleKey: 'countAllTask',
      fieldData: 'allRequests',
    },
    {
      icon: cilSettings,
      titleKey: 'countOpenTask',
      fieldData: 'notCompletedRequests',
    },
    {
      icon: cilCarAlt,
      titleKey: 'countAllCars',
      fieldData: 'allCars',
    },
  ],
];

export { getDashboardConfig };
