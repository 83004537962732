import { CCard, CCardHeader, CContainer } from '@coreui/react-pro';
import { AppTranslationKey, DataStatus } from 'common/enums/enums';
import { CarServiceEditRequestDto } from 'common/types/types';
import { CarServiceForm } from 'components/car-services-profiles/car-service-form/car-service-form';
import { Spinner } from 'components/common/common';
import { Page404 } from 'components/page-404/page-404';
import { useAppDispatch, useAppSelector, useEffect, useNavigate, useParams } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { carServicesActions } from 'store/actions';

const EditCarService: FC = () => {

  const { userId, userCarServices, dataStatus } = useAppSelector(({ carServices, auth }) => ({
    userCarServices: carServices.userCarServices,
    dataStatus: carServices.dataStatus,
    userId: auth.currentUser?.id ?? 0,
  }));
  const { carServiceId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(AppTranslationKey.CAR_SERVICE);

  const carService = userCarServices.find((item) => item.id.toString() === carServiceId) ?? null;

  useEffect( () => {
    if (carService === null) {
      dispatch(carServicesActions.getAllCarServiceByUserId({ userId }));
    }
  },[dispatch, userId, carService]);

  if(dataStatus === DataStatus.PENDING) {
    return <Spinner isOverflow containerHeight={250} />;
  }

  if (carService === null) {
    return <Page404 />;
  }

  const handleConfirmEdit = (carService: CarServiceEditRequestDto): void => {
    dispatch(carServicesActions.updateCarServiceById(carService))
      .unwrap()
      .then(() => navigate(-1) );
  };

  return (
    <CContainer fluid>
      <CCard>
        <CCardHeader>
          {t('editCarServiceTitle')}
        </CCardHeader>
        <CarServiceForm onSubmit={handleConfirmEdit} carService={carService}/>
      </CCard>
    </CContainer>
  );
};

export { EditCarService };
