import { CCol, CFormLabel, CFormSelect, CRow } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/translation-key/app-translation-key.enum';
import { CarModalFormData, Model } from 'common/types/types';
import React, { FC } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

type Props = {
  register: UseFormRegister<CarModalFormData>,
  errors: FieldErrors<CarModalFormData>,
  models: Model[],
  disabled? :boolean,
};

const ModelField: FC<Props> = ({
  disabled = false, register, errors, models,
}) => {
  const { t } = useTranslation(AppTranslationKey.CAR_MODAL);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="model" className="col-sm-3 col-form-label">
        {t('model')}*
      </CFormLabel>
      <CCol sm={9}>
        <CFormSelect
          disabled={disabled}
          { ...register('carModelId',
            {
              required: t('requiredFormField') ?? 'This field is required',
              validate: (value) => value > -1 || t('requiredFormField') as string,
            },
          ) }
          id="model"
          invalid={Boolean(errors.carModelId)}
          feedbackInvalid={errors.carModelId?.message}
        >
          <option key={-1} value={-1}>{t('selectModel')}</option>
          {
            models.map((model) => (
              <option key={model.id} value={model.id}>
                {model.name}
              </option>
            ))
          }
        </CFormSelect>
      </CCol>
    </CRow>
  );
};

export { ModelField };
