import { ApiPath, HttpMethod, WorkOrderApiPath } from 'common/enums/enums';
import {
  AddWorkOrderRequest,
  GetWorkOrderByIdRequest,
  GetWorkOrderWorkRequest,
  GetWorkOrderWorkResponse, SparePartsAddToWorkOrderRequest, SparePartsEditInWorkOrderRequest,
  UpdateWorkOrderRequest,
  WorkDto,
  WorkOrderAddWorkResponse,
  WorkOrderDto,
  WorkOrderRemoveSparePartRequest,
  WorkOrderRemoveSparePartResponse,
  WorkOrderRemoveWorkRequest,
  WorkOrderRemoveWorkResponse,
  WorkOrdersGetByFiltersRequestDto,
  WorkOrderUpdateWorkRequest,
} from 'common/types/types';
import { Http } from 'services/http/http.service';

type Constructor = {
  http: Http;
  apiPrefix: string;
};

class WorkOrderApi {
  #http: Http;

  readonly #apiPrefix: string;

  public constructor({ http, apiPrefix }: Constructor) {
    this.#http = http;

    this.#apiPrefix = apiPrefix;
  }

  public getWorkOrdersByFilters( payload: WorkOrdersGetByFiltersRequestDto ): Promise<WorkOrderDto[]> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.WORK_ORDERS}${WorkOrderApiPath.GET_ORDERS_BY_BY_FILTERS}`,
      {
        method: HttpMethod.POST,
        payload: JSON.stringify(payload),
      },
    );
  }

  public addWorkOrder( payload: AddWorkOrderRequest): Promise<WorkOrderDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.WORK_ORDERS}`,
      {
        method: HttpMethod.POST,
        payload: JSON.stringify(payload),
      },
    );
  }

  public getWorkOrderById({ orderId }: GetWorkOrderByIdRequest):Promise<WorkOrderDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.WORK_ORDERS}/${orderId}`,
    );
  }

  public getWorkOrderWorks({ orderId }: GetWorkOrderWorkRequest): Promise<GetWorkOrderWorkResponse> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.WORK_ORDERS}/${orderId}${WorkOrderApiPath.GET_WORK}`,
    );
  }

  public removeWorkFromWorkOrder(payload: WorkOrderRemoveWorkRequest): Promise<WorkOrderRemoveWorkResponse> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.WORK_ORDERS}${WorkOrderApiPath.REMOVE_WORK}`, {
        method: HttpMethod.POST,
        payload: JSON.stringify(payload),
      },
    );
  }

  public removeSparePartFromWorkOrder(payload: WorkOrderRemoveSparePartRequest):
    Promise<WorkOrderRemoveSparePartResponse> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.WORK_ORDERS}${WorkOrderApiPath.REMOVE_SPARE_PART}`, {
        method: HttpMethod.POST,
        payload: JSON.stringify(payload),
      },
    );
  }

  public addWorkToWorkOrder(payload: WorkDto): Promise<WorkOrderAddWorkResponse> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.WORK_ORDERS}${WorkOrderApiPath.ADD_WORK}`,
      {
        method: HttpMethod.POST,
        payload: JSON.stringify(payload),
      },
    );
  }

  public addSparePartToWorkOrder(payload: SparePartsAddToWorkOrderRequest): Promise<WorkOrderRemoveSparePartResponse> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.WORK_ORDERS}${WorkOrderApiPath.ADD_SPARE_PART}`,
      {
        method: HttpMethod.POST,
        payload: JSON.stringify(payload),
      },
    );
  }

  public updateWorkInWorkOrder(payload: WorkOrderUpdateWorkRequest): Promise<void> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.WORK_ORDERS}${WorkOrderApiPath.EDIT_WORK}`,
      {
        method: HttpMethod.POST,
        payload: JSON.stringify(payload),
      },
    );
  }

  public updateSparePartInWorkOrder(payload: SparePartsEditInWorkOrderRequest): Promise<void> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.WORK_ORDERS}${WorkOrderApiPath.EDIT_SPARE_PART}`,
      {
        method: HttpMethod.POST,
        payload: JSON.stringify(payload),
      },
    );
  }

  public updateWorkOrder(payload: UpdateWorkOrderRequest): Promise<WorkOrderDto> {
    const { orderId } = payload;

    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.WORK_ORDERS}/${orderId}`,
      {
        method: HttpMethod.PUT,
        payload: JSON.stringify(payload),
      },
    );
  }

}

export { WorkOrderApi };
