import { CBadge } from '@coreui/react-pro';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { IBadge,IMainMenuConfig } from './interfaces';

interface ISidebarNav {
  items: IMainMenuConfig[]
}

const SidebarNav: FC<ISidebarNav> = ({ items }) => {
  const location = useLocation();
  const { t }    = useTranslation('mainMenu');
  const navLink = (name: string, icon: React.ReactNode, badge?: IBadge):React.ReactNode => {
    return (
      <>
        {icon && icon}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    );
  };

  const navItem = (item:IMainMenuConfig, index:number):React.ReactNode => {
    const { component, name, badge, icon, ...rest } = item;
    const Component = component;
    const params = (rest.to && !rest.items) ? { component: NavLink } : {};

    return (
      <Component {...params} key={`${index}-${name}`} {...rest}>
        {navLink(t(name), icon, badge)}
      </Component>
    );
  };
  const navGroup = (item:IMainMenuConfig, index:number):React.ReactNode => {
    const { component, name, icon, to, ...rest } = item;
    const Component = component;

    return (
      <Component
        idx={`${index}-${name}`}
        key={`${index}-${name}`}
        toggler={navLink(t(name), icon)}
        visible={location.pathname.startsWith(to ?? '#')}
        {...rest}
      >
        {item.items?.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index),
        )}
      </Component>
    );
  };

  return (
    <>
      {items &&
        items.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)))}
    </>
  );
};

export { SidebarNav };
