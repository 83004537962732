import { AppTranslationKey } from 'common/enums/enums';
import { TableColumn } from 'common/types/common/table-column.type';
import { t } from 'i18next';

const getSparePartsFieldTableColumns = (): TableColumn[] => [
  {
    key: 'sparePartName',
    label: t(`${AppTranslationKey.SPARE_PARTS}:sparePartName`),
    _style: { width: '45%' },
  },
  {
    key: 'orderId',
    label: t(`${AppTranslationKey.SPARE_PARTS}:orderId`),
    _style: { width: '19%' },
  },
  {
    key: 'priceOne',
    label: t(`${AppTranslationKey.SPARE_PARTS}:priceOne`),
    _style: { width: '10%' },
  },
  {
    key: 'count',
    label: t(`${
      AppTranslationKey.SPARE_PARTS}:count`),
    _style: { width: '10%' },
  },
  {
    key: 'priceTotal',
    label: t(`${AppTranslationKey.SPARE_PARTS}:priceTotal`),
    _style: { width: '16%' },
  },
];

export { getSparePartsFieldTableColumns };
