import { CBadge, CNav, CNavItem, CNavLink } from '@coreui/react-pro';
import { FC } from 'react';

import styles from './styles.module.scss';

type Item = {
  id: number,
  name: string,
};

type Props<T extends Item > = {
  tabs: T[],
  activeTabId: number | undefined,
  handleTabClick: (id: number) => void,
  badgeText?: Record<number, number>,
};

const NavTabs: FC<Props<Item>> = ({ activeTabId, tabs, badgeText, handleTabClick }) => {
  const TITLE_MAX_LENGTH = 20;

  return (
    <CNav variant="tabs" className="card-header-tabs">
      {
        tabs.map((item) =>
          <CNavItem key={item.id} className={styles.navTab}>
            <CNavLink
              className={activeTabId !== item.id ? styles.tab : styles.activeTab}
              // href="#"
              active={activeTabId === item.id}
              onClick={(): void => handleTabClick(item.id)}
            >
              { item.name.length > TITLE_MAX_LENGTH ?
                item.name.slice(0, TITLE_MAX_LENGTH - 3) + '...' :
                item.name }
              <CBadge
                className={styles.badge}
                color="primary"
                position="top-end"
                shape="rounded"
              >
                {badgeText?.[item.id] ?? null}
              </CBadge>
            </CNavLink>
          </CNavItem>,
        )
      }
    </CNav>
  );
};

export { NavTabs };
