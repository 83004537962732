import { CButton } from '@coreui/react-pro';
import { AppRoute, AppTranslationKey, RecoveryPasswordTranslationKey } from 'common/enums/enums';
import { MessageStatus } from 'components/auth/common/enums/enums';
import { getValidClasses } from 'helpers/helpers';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

type Props = {
  message: string;
  msgType: MessageStatus;
};
const Message: FC<Props> = ({ message, msgType }) => {
  const { t } = useTranslation(AppTranslationKey.RECOVERY_PASSWORD);

  return (
    <>
      <div className={getValidClasses(styles.message , styles[msgType])}>
        {message}
      </div>
      <Link to={AppRoute.SIGN_IN} className="d-grid">
        <CButton color="link" >
          {t(RecoveryPasswordTranslationKey.BACK_TO_SIGN_IN) ?? 'Back to Sign In'}
        </CButton>
      </Link>
    </>
  );
};

export { Message };
