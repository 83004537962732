import { FILTER_DATE_FORMAT, FILTER_DATE_FORMAT_VIEW } from 'constants/date-formats';
import format from 'date-fns/format';
import React, { FC } from 'react';
import DateRangePicker, { DateRange } from 'rsuite/DateRangePicker';

import { useFilterPanelContext } from '../filter-panel-context';

const FieldPeriod: FC = () => {
  const { filters: { dateFrom, dateTo }, setOrderFilters } = useFilterPanelContext();
  const value = (!dateFrom || !dateTo) ? null : ([new Date(dateFrom), new Date(dateTo)] as DateRange);

  const onChangeDate = (dates: DateRange | null): void => {
    const dateFrom = (dates && dates.length) ? format(dates[0], FILTER_DATE_FORMAT) : undefined;
    const dateTo = (dates && dates.length > 0) ? format(dates[1], FILTER_DATE_FORMAT) : undefined;

    setOrderFilters({ dateFrom, dateTo });
  };

  return (
    <DateRangePicker
      placeholder="dd.mm.yyyy ~ dd.mm.yyyy"
      format={ FILTER_DATE_FORMAT_VIEW }
      value={ value }
      isoWeek
      onChange={ onChangeDate }
    />
  );
};

export { FieldPeriod };
