import { AppRoute, AppTranslationKey, AuthTranslationKey, LocalStorageKey, NotificationType } from 'common/enums/enums';
import { Spinner } from 'components/common/common';
import { useAppDispatch, useEffect, useNavigate, useSearchParams } from 'hooks/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { storage } from 'services/services';
import { appActions, authActions } from 'store/actions';

const ValidateUser: FC = () => {
  const  [hash]  = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(AppTranslationKey.AUTH);

  useEffect(() => {
    dispatch(authActions.validateUser({ hash: hash.get('hash') ?? '' }))
      .unwrap()
      .then(() => {
        dispatch(appActions.notify({ type: NotificationType.SUCCESS, message: t(AuthTranslationKey.ACTIVATED) }));
      })
      .finally(() => {
        if (storage.getItem(LocalStorageKey.TOKEN)) {
          dispatch(authActions.logOut()).unwrap().then(() => navigate(AppRoute.ROOT));
        }
        else {
          navigate(AppRoute.ROOT);
        }
      });
    // eslint-disable-next-line
  }, []);

  return <Spinner isOverflow />;
};

export { ValidateUser };
