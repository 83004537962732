const getItemPerPageOptions = (itemCount: number): number[] => {
  switch(true) {
  case (itemCount <= 10): return [10];
  case((itemCount > 10 && itemCount <= 20)): return [10, 20];
  case((itemCount > 20 && itemCount <= 50)): return [10, 20, 50];
  case((itemCount > 50 && itemCount <= 100)): return [10, 50, 100];
  case((itemCount > 100 && itemCount <= 500)): return [10, 50, 100, 500];
  }

  return [10, 50, 100, 500, 2000];
};

export { getItemPerPageOptions };
