enum WorkOrderApiPath {
  GET_ORDERS_BY_BY_FILTERS = '/carservice/by-date-and-status',
  REMOVE_WORK = '/work/remove',
  REMOVE_SPARE_PART = '/spare/remove',
  ADD_WORK = '/work/add',
  ADD_SPARE_PART = '/spare/add',
  EDIT_WORK = '/work/edit',
  EDIT_SPARE_PART = '/spare/edit',
  GET_WORK = '/works',
}

export { WorkOrderApiPath };
