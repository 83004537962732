import { ApiPath, DocumentationApiPath } from 'common/enums/enums';
import { Http } from 'services/http/http.service';

type Constructor = {
  http: Http;
  apiPrefix: string;
};

class DocumentationApi {

  #http: Http;

  #apiPrefix: string;

  public constructor({ http, apiPrefix }: Constructor) {
    this.#http = http;
    this.#apiPrefix = apiPrefix;
  }

  public getCertificateOfCompletedWork({ requestId }: { requestId: number }): Promise<{ url: string }> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.DOCUMENTATION}${DocumentationApiPath.CERTIFICATE_OF_COMPLETED_WORK}/${requestId}`,
    );
  }
}

export { DocumentationApi };
