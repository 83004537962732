import 'assets/scss/style.scss';
import 'rsuite/dist/rsuite.min.css';

import { DataStatus, LocalStorageKey } from 'common/enums/enums';
import { PermissionToast, Spinner, usePermissionToastContext } from 'components/common/common';
import { loadDictionariesFromStorage } from 'helpers/helpers';
import { useAppDispatch, useAppSelector, useEffect } from 'hooks/hooks';
import { i18next } from 'i18n';
import { FC } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { route } from 'route/route';
import { storage } from 'services/services';
import { authActions, dictionaryActions, userActions } from 'store/actions';
import { manualAddDictionaries } from 'store/dictionary/reducer';

const router = createBrowserRouter(route);

const App: FC = () => {

  const { currentUser, dataStatus, locale } = useAppSelector(({ auth, user }) => ({
    currentUser: auth.currentUser,
    dataStatus: auth.dataStatus,
    locale: user.user?.locale,
  }));
  const hasCurrentUser = Boolean(currentUser);
  const hasToken = Boolean(storage.getItem(LocalStorageKey.TOKEN));
  const dispatch = useAppDispatch();
  const { isVisible, onHide } = usePermissionToastContext();

  useEffect(() => {

    if (hasToken && !hasCurrentUser) {
      dispatch(authActions.getCurrentUser())
        .unwrap()
        .catch(() => {
          dispatch(authActions.refreshToken({
            refresh_token: storage.getItem(LocalStorageKey.REFRESH_TOKEN) ?? '',
          }));
        });
    }
  }, [ hasCurrentUser, hasToken, dispatch ]);

  useEffect(() => {
    if (hasCurrentUser && currentUser && !locale) {
      dispatch(userActions.getUserById(currentUser.id));
    }
  }, [currentUser, dispatch, hasCurrentUser, locale]);

  useEffect(() => {
    if (hasCurrentUser && currentUser && locale) {
      i18next.changeLanguage(locale).then( () => {
        const dictionaries = loadDictionariesFromStorage();

        if (dictionaries) {
          dispatch(manualAddDictionaries(dictionaries));
        } else {
          dispatch(dictionaryActions.getDictionaries(currentUser.id));
        }
      });
    }
  }, [currentUser, dispatch, hasCurrentUser, locale]);

  if (!hasCurrentUser && hasToken && dataStatus !== DataStatus.REJECTED) {
    return <Spinner isOverflow/>;
  }

  return (
    <>
      <RouterProvider router={router}/>
      <PermissionToast visible={isVisible} onClose={onHide} />
    </>
  );
};

export { App };
