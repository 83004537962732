enum InfoVinTranslationKey {
  TITLE = 'title',
  SEARCH_BUTTON_TEXT = 'searchButtonText',
  SEARCH_INPUT_TITLE = 'searchInputTitle',
  VIN_INPUT_PLACEHOLDER = 'vinInputPlaceholder',
  GOS_NUMBER_INPUT_PLACEHOLDER = 'gosNumberInputPlaceholder',
  GOS_NUMBER_INPUT_ACTIVE_PLACEHOLDER = 'gosNumberInputActivePlaceholder',
  ERROR_SHORT_VIN = 'errorShortVin',
  ERROR_LONG_VIN = 'errorLongVin',
  ERROR_REG_NUM = 'errorRegNum',
  ERROR_EMPTY_SEARCH = 'errorEmptySearch',
  TABLE_INFO_TITLE = 'tableInfoTitle',
  SEARCH_INFO_MESSAGE_TITLE = 'searchInfoMessageTitle',
  SEARCH_INFO_MESSAGE_TEXT = 'searchInfoMessageText',
  TABLE_FIELD_CAR = 'tableFieldCar',
  TABLE_FIELD_VIN = 'tableFieldVin',
  TABLE_FIELD_YEAR = 'tableFieldYear',
  TABLE_FIELD_COLOR = 'tableFieldColor',
  TABLE_FIELD_BODY = 'tableFieldBody',
  TABLE_FIELD_FUEL = 'tableFieldFuel',
  TABLE_FIELD_CAPACITY = 'tableFieldCapacity',
  TABLE_FIELD_OWN_WEIGHT = 'tableFieldOwnWeight',
  TABLE_FIELD_TOTAL_WEIGHT = 'tableFieldTotalWeight',
  TABLE_FIELD_REG_NUM = 'tableFieldRegNum',
  INFO_BLOCK_VIN_TITLE = 'infoBlockVinTitle',
  INFO_BLOCK_VIN_TEXT = 'infoBlockVinText',
  INFO_BLOCK_VIN_CAR_TITLE = 'infoBlockVinCarTitle',
  INFO_BLOCK_VIN_CAR_TEXT = 'infoBlockVinCarText',
}

export { InfoVinTranslationKey };
