import { AllCarWorkModalTranslationKey,AppTranslationKey, CarWorkTranslationKey } from 'common/enums/enums';
import { TableColumn } from 'common/types/common/table-column.type';
import { t } from 'i18next';

const getWorkFieldTableColumns = (): TableColumn[] => [
  {
    key: 'serviceWorkName',
    label: t(
      `${
        AppTranslationKey.CAR_WORK
      }:${
        CarWorkTranslationKey.ALL_CAR_WORK_MODAL
      }:${
        AllCarWorkModalTranslationKey.CAR_WORK_MODAL_WORK_NAME
      }`,
    ),
    _style: { width: '45%' },
  },
  {
    key: 'orderId',
    label: t(`${
      AppTranslationKey.CAR_WORK
    }:${
      CarWorkTranslationKey.ALL_CAR_WORK_MODAL
    }:${
      AllCarWorkModalTranslationKey.CAR_WORK_NUM_MODAL
    }`),
    _style: { width: '15%' },
  },
  {
    key: 'priceOne',
    label: t(`${
      AppTranslationKey.CAR_WORK
    }:${
      CarWorkTranslationKey.ALL_CAR_WORK_MODAL
    }:${
      AllCarWorkModalTranslationKey.CAR_WORK_PRICE_MODAL
    }`),
    _style: { width: '15%' },
  },
  {
    key: 'workCount',
    label: t(`${
      AppTranslationKey.CAR_WORK
    }:${
      CarWorkTranslationKey.ALL_CAR_WORK_MODAL
    }:${
      AllCarWorkModalTranslationKey.CAR_WORK_QTY_MODAL
    }`),
    _style: { width: '10%' },
  },
  {
    key: 'priceTotal',
    label: t(`${
      AppTranslationKey.CAR_WORK
    }:${
      CarWorkTranslationKey.ALL_CAR_WORK_MODAL
    }:${
      AllCarWorkModalTranslationKey.CAR_WORK_SUM_MODAL
    }`),
    _style: { width: '15%' },
  },
];

export { getWorkFieldTableColumns };
