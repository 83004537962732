enum OrdersApiPath {
  ROOT= '/',
  GET_ORDERS_BY_CAR_SERVICE_ID = '/carservice',
  GET_ORDERS_BY_BY_FILTERS = '/carservice/by-date-and-status',
  ADD_WORK = '/work/add',
  REMOVE_WORK = '/work/remove',
  EDIT_WORK = '/work/edit',
}

export { OrdersApiPath };
