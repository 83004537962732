import React, { createContext, FC, useContext } from 'react';

import { useSparePartModal } from './use-spare-part-modal';

type SparePartModalContextType = ReturnType<typeof useSparePartModal>;

const SparePartModalContext = createContext<SparePartModalContextType | null>(null);

const useSparePartModalContext = (): SparePartModalContextType => {
  const context = useContext(SparePartModalContext);

  if (!context) {
    throw new Error('useSparePartModalContext must be used within the SparePartModalContext');
  }

  return context;
};

interface IProps {
  children: React.ReactNode,
}

const SparePartModalContextProvider: FC<IProps> = ({ children }) => (
  <SparePartModalContext.Provider value={ useSparePartModal() }>
    { children }
  </SparePartModalContext.Provider>
);

export { SparePartModalContextProvider, useSparePartModalContext };
