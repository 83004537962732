import { SelectedWorks, WorkDto } from 'common/types/types';

const worksToSelectedWorks = (works: WorkDto): SelectedWorks => {
  return {
    rowId: works?.rowId,
    workId: works?.serviceWorkId,
    workOrder: works?.orderId ?? '',//<==== наряд
    name: works?.serviceWorkName ?? '',
    qty: works?.workCount?.toString() ?? '1',
    price: works?.priceOne?.toString() ?? '0',
    sum: works?.priceTotal?.toString() ?? '0',
  };
};

export { worksToSelectedWorks };
