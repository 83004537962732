import { AppTranslationKey } from 'common/enums/enums';
import { TableColumn } from 'common/types/types';
import { t } from 'i18next';

const getOrderClientSparePartsTableColumns = (): TableColumn[] => [
  {
    key: 'sparePartName',
    label: t(`${AppTranslationKey.SPARE_PARTS}:sparePartName`),
    _style: { width: '45%' },
  },
  {
    key: 'orderId',
    label: t(`${AppTranslationKey.SPARE_PARTS}:orderId`),
    _style: { width: '15%' },
  },
  {
    key: 'quantity',
    label: t(`${
      AppTranslationKey.SPARE_PARTS}:count`),
  },
];

export { getOrderClientSparePartsTableColumns };
