import { TableColumn } from 'common/types/types';

const getColumns = (labels: string): TableColumn[] => {
  const labelsArr = labels.split('|');

  return [
    {
      key: 'sparePartId',
      label: labelsArr[0],
      filter: false,
      _style: { width: '70px' },
    },
    {
      key: 'sparePartsName',
      label: labelsArr[1],
      filter: true,
      _style: { width: '50%' },
    },
    {
      key: 'originalCode',
      label: labelsArr[5],
      filter: true,
      _style: { width: '350px' },
    },
    {
      key: 'valueCurrent',
      label: labelsArr[2],
      filter: false,
      _style: { width: '110px' },
    },
    {
      key: 'valueMin',
      label: labelsArr[3],
      filter: false,
      _style: { width: '130px' },
    },
    {
      key: 'priceSell',
      label: labelsArr[4],
      filter: false,
      _style: { width: '110px' },
    },
  ];
};

export { getColumns };
