import { CCollapse } from '@coreui/react-pro';
import { CarDto, ClientSpareParts, SparePartsInOrder, WorkDto } from 'common/types/types';
import React, { ReactElement } from 'react';

import { OrderDetails } from './order-details';

type TableRowDetails = {
  visible: boolean,
  id: number,
  comment: string,
  performer: string,
  car: CarDto,
  orderTask: WorkDto[],
  spares: SparePartsInOrder[],
  clientSpares: ClientSpareParts[],

  handleViewEditTaskClick: (readOnly: boolean ) => void
};
const renderTableItemDetails = (item: TableRowDetails): ReactElement => {
  return (
    <CCollapse visible={item.visible}>
      <OrderDetails
        id={item.id}
        comment={item.comment}
        spares={item.spares}
        clientSpares={item.clientSpares}
        auto={item.car}
        orderTask={item.orderTask.map((it) => {return  { ...it, orderId: it.orderId === null ? '' : it.orderId }; })}
        onViewEditOrderClick={(readOnly): void => {
          item.handleViewEditTaskClick(readOnly);
        }}
      />
    </CCollapse>
  );
};

export { renderTableItemDetails };
