import { CButton, CCardSubtitle, CPopover, CSmartTable } from '@coreui/react-pro';
import { SorterValue } from '@coreui/react-pro/dist/components/smart-table/types';
import { AppTranslationKey, LocalStorageKey } from 'common/enums/enums';
import { OrderDto, OrderTableRaw } from 'common/types/types';
import {
  CarStateNumber,
  ColorItem, NoData,
  NoDataWithAction,
  StatusBadge,
  ToggleShowDetailsBtn, usePermissionToastContext,
} from 'components/common/common';
import { Tariffs } from 'constants/tariffs';
import { getItemPerPageOptions } from 'helpers/helpers';
import { useAppDispatch, useEffect ,useMemo }  from 'hooks/hooks';
import React, { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { storage } from 'services/services';
import { ordersSort } from 'store/car-service/reducer';

import { getOrderTableColumn, getOrderTableData, renderTableItemDetails } from './helpers/helpers';
import styles from './style.module.scss';

type carServiceStateType = {
  [carServiceId: number]: {
    perPage: number,
    activePage: number,
  }
};

type Props = {
  carServiceId: number,
  orders: OrderDto[],
  handleViewEditTaskClick: (orderId: number, readOnly: boolean) => void,
  handleAddOrderClick: () => void,
  onOrderNumberClick: (id: number) => void,
};

const OrderTable: FC<Props> = ({
  orders,
  carServiceId,
  handleViewEditTaskClick,
  handleAddOrderClick,
  onOrderNumberClick,
}) => {
  const { t } = useTranslation([AppTranslationKey.CAR_SERVICE_ORDER, AppTranslationKey.COMMON]);
  const dispatch = useAppDispatch();
  const tableData = getOrderTableData(orders, t('defPerformer'));
  const orderTableColumn = getOrderTableColumn(t('orderTableColumns'));
  const { onPermissionAction } = usePermissionToastContext();
  const [details, setDetails] = useState<number[]>([]);
  const [sorterValue, setSorterValue] = useState<SorterValue>({ column: 'id', state: 'desc' });

  const itemsPerPage: number[] = useMemo( () => getItemPerPageOptions(tableData.length), [ tableData ] );
  const toggleDetails = (id: number): void => {
    const position = details.indexOf(id);
    let newDetails = details.slice();

    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, id];
    }
    setDetails(newDetails);
  };

  useEffect(() => {
    dispatch(ordersSort(sorterValue));
  }, [dispatch, sorterValue, tableData]);

  const renderBadges = (item: OrderTableRaw): ReactElement =>
    <td>
      <StatusBadge color={item.statusColor} title={item.statusName} textColor={item.statusTextColor}/>
    </td>;

  const carServiceState: carServiceStateType = JSON.parse(storage.getItem(LocalStorageKey.CAR_SERVICE_STATE) ?? '{}');

  if (orders.length === 0) {
    return <NoDataWithAction
      title={t('noOrdersTitle')}
      message={t('noOrdersText')}
      actionName={t('addOrderCaptionButton')}
      callback={onPermissionAction(handleAddOrderClick, Tariffs.NO_TARIFF)}
    />;
  }

  const onActivePageChange = (value: number): void => {
    storage.setItem(
      LocalStorageKey.CAR_SERVICE_STATE,
      JSON.stringify(
        {
          ...carServiceState,
          [carServiceId]: {
            ...JSON.parse(storage.getItem(LocalStorageKey.CAR_SERVICE_STATE) ?? '{}')[carServiceId],
            activePage: value,
          },
        }),
    );
  };

  const onItemsPerPageChange = (value: number): void => {
    storage.setItem(
      LocalStorageKey.CAR_SERVICE_STATE,
      JSON.stringify(
        {
          ...carServiceState,
          [carServiceId]: {
            ...JSON.parse(storage.getItem(LocalStorageKey.CAR_SERVICE_STATE) ?? '{}')[carServiceId],
            perPage: value,
          },
        }),
    );
  };
  const renderCarNameField = (item: OrderTableRaw): ReactElement => {
    return <td>
      <CCardSubtitle>{item.auto}</CCardSubtitle>
    </td>;
  };
  const renderCarColorField = (item: OrderTableRaw): ReactElement => {
    return <td>
      <ColorItem colorCode={item?.car?.carColorHtmlCode ?? 'transparent'}  size={ 20 } />
    </td>;
  };
  const renderCarNumField = (item: OrderTableRaw): ReactElement => {
    return <td>
      {item.car && <CarStateNumber carStateNum={item.car.carRegNum}/>}
    </td>;
  };

  return (
    <CSmartTable
      columns={orderTableColumn}
      columnSorter={{
        external: true,
        resetable: false,
      }}
      sorterValue={{ column: 'id', state: 'desc' }}
      items={tableData}
      itemsPerPageSelect
      itemsPerPageOptions={itemsPerPage}
      noItemsLabel={
        <NoData
          title={t('noItemsFoundTitle', { ns: AppTranslationKey.COMMON })}
          message={t('noItemsFoundMsg', { ns: AppTranslationKey.COMMON })}
        />
      }
      itemsPerPageLabel={t('itemsPerPageLabel') ?? 'Елементів на сторінці'}
      activePage={carServiceState[carServiceId]?.activePage}
      itemsPerPage={carServiceState[carServiceId]?.perPage}
      onActivePageChange={onActivePageChange}
      onItemsPerPageChange={onItemsPerPageChange}
      pagination
      scopedColumns={{
        id: (item: OrderTableRaw): ReactElement =>
          <td>
            <CButton
              color="link"
              size={'sm'}
              className="px-0"
              onClick={():void => onOrderNumberClick(item.id)}
            >
              { item.id }
            </CButton>
          </td>,
        statusName: (item: OrderTableRaw) => renderBadges(item),
        comment: (item: OrderTableRaw) => (
          <td style={{ maxWidth: 0 }}>
            <CPopover
              content={item.comment}
              placement="right"
              trigger="hover"
            >
              <div className={styles.commentColumn}>
                {item.comment}
              </div>
            </CPopover>
          </td>
        ),
        totalSum: (item: OrderTableRaw) => (
          <td>
            <div>
              <span style={{ color: 'darkred' }} >{item.totalSum}</span>
            </div>
          </td>
        ),
        sumOrders: (item: OrderTableRaw) => (
          <td>
            <div>
              <span style={{ color: 'darkblue' }} >{item.sumOrders}</span>
            </div>
          </td>
        ),
        sumWorksWithoutOrders: (item: OrderTableRaw) => (
          <td>
            <div>
              <span style={{ color: 'gray' }} >{item.sumWorksWithoutOrders}</span>
            </div>
          </td>
        ),
        auto: (item: OrderTableRaw) => renderCarNameField(item),
        autoNum: (item: OrderTableRaw) => renderCarNumField(item),
        autoColor: (item: OrderTableRaw) => renderCarColorField(item),
        show_details: (item: OrderTableRaw) =>
          <ToggleShowDetailsBtn
            toggleDetails={(): void => toggleDetails(item.id)}
            isOpen={details.includes(item.id)}
          />,
        details: (item) => renderTableItemDetails(
          {
            comment: item.comment,
            id: item.id,
            orderTask: item.orderTask,
            spares: item.spares,
            clientSpares: item.clientSpares,
            performer: item.performer,
            car: item.car,
            visible: details.includes(item.id),
            handleViewEditTaskClick: (readOnly) => {
              setDetails(details.splice(details.indexOf(item.id), 1));
              handleViewEditTaskClick(item.id, readOnly);
            },
          },
        ),
      }}
      onSorterChange={ (value: SorterValue): void => {
        setSorterValue(value);
      }}
      tableHeadProps={{
        color: 'light',
      }}
      tableProps={{
        striped: true,
        hover: true,
        align: 'middle',
      }}
    />
  );
};

export { OrderTable };
