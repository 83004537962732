import { createSlice } from '@reduxjs/toolkit';
import { DataStatus } from 'common/enums/enums';
import { WidgetsData } from 'common/types/types';

import {
  getAllCarsByCarServiceId,
  getAllRequestsByCarServiceId, getNotCompletedTasksByCarServiceId,
  getOpenRequests,
  getOpenWorkOrders,
  getWidgetsData,
} from './actions';

type State = {
  dataStatusWidgetsData: DataStatus,
  widgetsData: WidgetsData,
  openRequests: Record<number, number>; //<carServiceId : openRequests>,
  openWorkOrders: Record<number, number>; //<carServiceId : openWorkOrders>,
};

const initialWidgetData = {
  allRequests: null,
  notCompletedRequests: null,
  allCars: null,
};

const initialState: State = {
  dataStatusWidgetsData: DataStatus.IDLE,
  widgetsData: initialWidgetData,
  openRequests: {},
  openWorkOrders: {},
};

const widgetSlice = createSlice({
  name: 'widget',
  initialState,
  reducers: {
    clearWidgetData: (state) => {
      state.widgetsData = initialWidgetData;
    },
    clearOpenRequests: (state) => {
      state.openRequests = {};
    },
    clearOpenWorkOrders: (state) => {
      state.openWorkOrders = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWidgetsData.pending, (state) => {
        state.dataStatusWidgetsData = DataStatus.PENDING;
      })
      .addCase(getWidgetsData.rejected, (state) => {
        state.dataStatusWidgetsData = DataStatus.REJECTED;
        state.widgetsData = initialWidgetData;
      })
      .addCase(getWidgetsData.fulfilled, (state, { payload }) => {
        state.dataStatusWidgetsData = DataStatus.FULFILLED;
        state.widgetsData = payload;
      })
      .addCase(getOpenRequests.fulfilled, (state, { payload }) => {
        payload
          .filter((it) => it.openRequests > 0)
          .forEach( (it) => state.openRequests[it.carServiceId] = it.openRequests);
      })
      .addCase(getOpenWorkOrders.fulfilled, (state, { payload }) => {
        payload
          .filter((it) => it.openOrders > 0)
          .forEach((it) => state.openWorkOrders[it.carServiceId] = it.openOrders);
      })
      .addCase(getAllCarsByCarServiceId.fulfilled, (state, { payload }) => {
        state.widgetsData = { ...state.widgetsData,  allCars: payload.count };
      })
      .addCase(getAllRequestsByCarServiceId.fulfilled, (state, { payload }) => {
        state.widgetsData = { ...state.widgetsData,  allRequests: payload.count };
      })
      .addCase(getNotCompletedTasksByCarServiceId.fulfilled, (state, { payload }) => {
        state.widgetsData = { ...state.widgetsData,  notCompletedRequests: payload.count };
      });
  },
});

const widgetReducer = widgetSlice.reducer;
const {
  clearOpenRequests,
  clearOpenWorkOrders,
  clearWidgetData,
} = widgetSlice.actions;

export { clearOpenRequests, clearOpenWorkOrders, clearWidgetData, widgetReducer };
