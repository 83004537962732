import { CCardTitle, CForm, CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react-pro';
import {
  AppTranslationKey,
  CarWorkTranslationKey,
  DataStatus,
  OrderModalTranslationKey,
  SelectWorkToOrderModalTranslationKey,
} from 'common/enums/enums';
import { carWorkFavToSelectedWorks } from 'common/map/maps';
import { CarWorkFav, SelectedWorks } from 'common/types/types';
import { FavCarWorkTable, NoData, SelectedWorksTable, TotalField } from 'components/common/common';
import { ModalFooter } from 'components/modals/components/components';
import { onlyNumberString } from 'helpers/helpers';
import { useAppDispatch, useAppSelector, useEffect } from 'hooks/hooks';
import { isNumber } from 'lodash';
import React, { FC, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { carWorkActions, taskActions } from 'store/actions';
import { addWorkToOrderLocally, removeWorkFromOrderLocally, updateWorkInOrderLocally } from 'store/car-service/reducer';
import {
  addSelectedWorkLocally,
  changePriceSelectedWork,
  changeQtySelectedWork,
  removeSelectedWorkByName,
  removeSelectedWorkByRowId,
} from 'store/task/reducer';

import { carWorkFavToWorkDto } from './map/car-work-fav-to-work-dto.map';
import styles from './styles.module.scss';
import { RemoveItemPayload } from './type/remove-item-payload.type';

type Props ={
  isOpen: boolean,
  onClose: () => void,
  onSubmit?: () => void,
  carServiceId: number,
  orderId?: number | null,
};

const SelectWorkToOrderModal: FC<Props> = ({
  isOpen,
  onClose,
  onSubmit = (): void => {
    //do nothing
  },
  carServiceId,
  orderId,
}) => {
  const { t } = useTranslation([AppTranslationKey.CAR_WORK, AppTranslationKey.ORDER_MODAL]);
  const { handleSubmit } = useForm();

  const {
    favoritesCarWork,
    favoritesCarWorkDataStatus,
    selectedWorks,
    dataStatusAddWorkInTask,
  } = useAppSelector(({ carWork, task }) => ({
    favoritesCarWork: carWork.favoritesCarWork,
    favoritesCarWorkDataStatus: carWork.favoritesCarWorkDataStatus,
    selectedWorks: task.selectedWorks,
    dataStatusAddWorkInTask: task.dataStatusModifyWorkInTask,
  }));

  const isLoading = favoritesCarWorkDataStatus === DataStatus.PENDING;
  const isModifyWorkInTask = dataStatusAddWorkInTask === DataStatus.PENDING;

  const dispatch = useAppDispatch();

  const TotalModalSum = (): ReactElement => {
    const label = t(`${CarWorkTranslationKey.SELECT_WORK_TO_ORDER_MODAL}.${SelectWorkToOrderModalTranslationKey.SUM}`);
    const items = selectedWorks.map((it) => (parseFloat(it.qty) * parseFloat(it.price)));

    return <TotalField label={ label } items={ items } />;
  };

  useEffect(() => {

    if (isOpen) {
      dispatch(carWorkActions.getFavoritesCarWork({ carServiceId: carServiceId }));
    }
  }, [carServiceId, dispatch, isOpen]);

  const handleRemoveSelectedWork = (item: RemoveItemPayload): void => {

    if(orderId) {
      item.rowId &&
      dispatch(taskActions.removeWork({ rowId: item.rowId }))
        .unwrap()
        .then(() => {
          if(item.rowId) {
            dispatch(removeSelectedWorkByRowId(item.rowId));
            dispatch(removeWorkFromOrderLocally({ orderId: orderId, rowId: item.rowId }));
          }

        });
    } else {
      dispatch(removeSelectedWorkByName(item.name));
    }
  };

  const handleAddSelectedWork = (item: CarWorkFav): void => {
    if (orderId) {
      const work = carWorkFavToWorkDto(
        item,{
          workCount: 1,
          requestId: orderId,
        });
      dispatch(taskActions.addWorkInTask(work))
        .unwrap()
        .then(
          (res) => {
            dispatch(addSelectedWorkLocally(carWorkFavToSelectedWorks({ ...item, rowId: res.rowId })));
            dispatch(addWorkToOrderLocally({ orderId: orderId,  work: { ...work, rowId: res.rowId } }));
          },
        );
    } else {
      dispatch(addSelectedWorkLocally(carWorkFavToSelectedWorks(item)));
    }
  };

  const handleSelectWork = (item: CarWorkFav): void => {
    if(selectedWorks.some((work) => work.name === item.serviceWorkName)) {
      handleRemoveSelectedWork(
        {
          name: item.serviceWorkName,
          rowId: selectedWorks.find((selectedWorks) => selectedWorks.workId === item.serviceWorkId )?.rowId ?? null,
          id: item.serviceWorkId,
        });
    } else {
      handleAddSelectedWork(item);
    }
  };

  const handleChangePrice = (value: string, workId: number ,rowId: number | null): void => {
    dispatch(changePriceSelectedWork({ value: onlyNumberString(value), workId, rowId }));
  };

  const handleChangeQty = (value: string, workId: number, rowId: number | null): void => {
    dispatch(changeQtySelectedWork({ value: onlyNumberString(value), workId, rowId }));
  };

  const handleEditWork = (rowId: number):void =>  {
    const work = selectedWorks.find((item) => item.rowId === rowId);
    dispatch(taskActions.editWork({
      priceOne: parseFloat(work?.price ?? '0'),
      priceTotal: parseFloat(work?.sum ?? '0'),
      rowId: work?.rowId ?? null,
      workCount: parseFloat(work?.qty ?? '0'),
    }))
      .unwrap()
      .then(() => work && dispatch(updateWorkInOrderLocally({
        orderId: work.workOrder as number,
        sparePartsId: null,
        requestId: orderId as number,
        serviceWorkId: work.workId,
        rowId: work.rowId,
        priceTotal: work.sum ?? 0,
        workCount: work.qty ?? 0,
        priceOne: work.price ?? 0,
        serviceWorkName: work.name,
      })));
  };

  const isCanRemoveSelectedWorksItem = (item?: SelectedWorks): boolean => {
    return !isNumber(item?.workOrder) ?? false;
  };

  const isDisabledFavCarWork = (item?: CarWorkFav): boolean => {
    return isNumber(selectedWorks.find((it) => it.workId === item?.serviceWorkId)?.workOrder) ?? false;
  };

  return (
    <CModal
      visible={isOpen}
      backdrop="static"
      onClose={onClose}
      size="xl"
      scrollable
    >
      <CModalHeader>
        <CModalTitle>
          {t(`${CarWorkTranslationKey.SELECT_WORK_TO_ORDER_MODAL}.${SelectWorkToOrderModalTranslationKey.TITLE}`)} {orderId ?? ''}
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div>
          <div className={styles.favCarWorkTableWrap}>
            <FavCarWorkTable
              isModifyWorkInTask={isModifyWorkInTask}
              isLoading={isLoading}
              onSelectCarWork={handleSelectWork}
              selectedWorks={selectedWorks}
              carWork={favoritesCarWork}
              isDisabled={isDisabledFavCarWork}
            />
          </div>
          <CCardTitle className={styles.cardTitle}>
            {
              t(`${CarWorkTranslationKey.SELECT_WORK_TO_ORDER_MODAL}.${SelectWorkToOrderModalTranslationKey.SELECTED}`)
            }({selectedWorks.length})
          </CCardTitle>
          {
            selectedWorks.length > 0 ? <div className={styles.selectedWorkTableWrap}>
              <SelectedWorksTable
                isModifyWorkInTask={isModifyWorkInTask}
                onEdit={handleEditWork}
                onChangePrice={handleChangePrice}
                selectedWorks={selectedWorks}
                onRemoveSelectedWork={handleRemoveSelectedWork}
                onChangeQty={handleChangeQty}
                isCanRemove={isCanRemoveSelectedWorksItem}
              />
            </div> : <NoData
              title={t(OrderModalTranslationKey.ORDER_NO_WORK, { ns: AppTranslationKey.ORDER_MODAL })} message=""
            />
          }
        </div>
      </CModalBody>
      <CForm
        className="form-scroll"
        onSubmit={handleSubmit(onSubmit)}
      >
        <ModalFooter
          hasModifyBtn={false}
          isUpdateData={false}
          desc={ <TotalModalSum /> }
          onClose={onClose}
        />
      </CForm>

    </CModal>
  );
};

export { SelectWorkToOrderModal };
