import {
  CButton,
  CCardBody,
  CCardFooter,
  CCol,
  CForm,
  CFormInput,
  CFormSelect,
  CLoadingButton,
  CRow,
} from '@coreui/react-pro';
import { AppTranslationKey, DataStatus, StorageTranslationKey } from 'common/enums/enums';
import { StorageDto, StorageModifyRequest } from 'common/types/types';
import { useAppSelector, useEffect,useNavigate } from 'hooks/hooks';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextArea } from '../../textarea/text-area';
import { DEFAULT_STORAGE_FORM_PAYLOAD } from './common';

type Props = {
  onSubmit: (payload: StorageModifyRequest) => void;
  storage?: StorageDto,
};

const StorageForm: FC<Props> = ({ onSubmit, storage }) => {
  const { t } = useTranslation(AppTranslationKey.STOCK);
  const navigate = useNavigate();
  const {
    storageStatus,
    modifyStorageDataStatus,
  } = useAppSelector(({ dictionary, stock }) => ({
    storageStatus: dictionary.data?.stockStatuses || [],
    modifyStorageDataStatus: stock.modifyStorageDataStatus,
  }) );
  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm<StorageModifyRequest>(
    {
      defaultValues: DEFAULT_STORAGE_FORM_PAYLOAD,
    },
  );
  useEffect(() => {
    if (storage) {
      reset({
        stockName: storage.stockName,
        address: storage.address,
        stockInfo: storage.stockInfo,
        stockId: storage.stockId,
        stockStatusId: storage.stockStatusId,
        addressId: storage.addressId,
      });
    }
  }, [ reset, storage ]);

  const watchInfo = watch('stockInfo');
  const isModifyData = Boolean(modifyStorageDataStatus === DataStatus.PENDING);

  return (
    <CForm onSubmit={handleSubmit(onSubmit)}>
      <CCardBody>
        <CRow xs={{ gutterY: 3 }}>
          <CCol md={3}>
            <CFormInput
              {...register(('stockName'),
                {
                  required: t(StorageTranslationKey.STORAGE_FORM_FIELD_REQUIRED) ?? 'This field is required',
                })
              }
              type="text"
              label={t(StorageTranslationKey.STORAGE_FORM_NAME_LABEL)}
              name="stockName"
              invalid={Boolean(errors.stockName)}
              feedbackInvalid={errors.stockName?.message}
            />
          </CCol>
          <CCol>
            <CFormSelect
              {...register('stockStatusId',
                {
                  required: t(StorageTranslationKey.STORAGE_FORM_FIELD_REQUIRED) ?? 'This field is required',
                },
              )}
              name="stockStatusId"
              label={t(StorageTranslationKey.STORAGE_FORM_STATUS_LABEL)}
              invalid={Boolean(errors.stockStatusId)}
              feedbackInvalid={errors.stockStatusId?.message}
            >
              {
                storageStatus.map((item) => (
                  <option
                    key={item.id} value={item.id}>{item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                  </option>
                ))
              }
            </CFormSelect>
          </CCol>
          <CCol md={6}>
            <CFormInput
              {...register('address',
                {
                  required: t(StorageTranslationKey.STORAGE_FORM_FIELD_REQUIRED) ?? 'This field is required',
                },
              )}
              name="address"
              type="text"
              label={t(StorageTranslationKey.STORAGE_FORM_ADDRESS_LABEL)}
              invalid={Boolean(errors.address)}
              feedbackInvalid={errors.address?.message}
            >
            </CFormInput>
          </CCol>
          <CCol md={12}>
            <TextArea<StorageModifyRequest>
              register={ register }
              limitSymbols={ 1024 }
              label={ t(StorageTranslationKey.STORAGE_FORM_INFO_LABEL) }
              nameFiled="stockInfo"
              value={ watchInfo }
              rows={ 3 }
            />
          </CCol>
        </CRow>
      </CCardBody>
      <CCardFooter>
        <CRow>
          <CCol style={{ display: 'flex', justifyContent: 'flex-end' }} md={12}>
            <CButton
              className="me-2"
              color="dark"
              variant="ghost"
              onClick={ (): void => navigate(-1) }
            >
              {t(StorageTranslationKey.BACK_CAPTION_BUTTON)}
            </CButton>
            <CLoadingButton
              color="primary"
              type="submit"
              disabledOnLoading
              loading={isModifyData}
            >
              {t('saveCaptionButton')}
            </CLoadingButton>
          </CCol>
        </CRow>
      </CCardFooter>
    </CForm>
  );
};

export { StorageForm };
