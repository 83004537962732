enum Pages {
  ORDERS = 'orders',
  WORK_ORDERS = 'workOrders',
  CARS = 'cars',
  WORKS = 'works',
  CAR_SERVICE = 'carService',
  STORAGE = 'storage',
  EMPLOYEES = 'employees',
}

export { Pages };
