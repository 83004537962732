
enum CarWorkTranslationKey {
  ADD_CAR_WORK_CAPTION_BUTTON = 'addCarWorkCaptionButton',
  NO_CAR_WORK_TITLE = 'noCarsWorkTitle',
  NO_CAR_WORK_TEXT = 'noCarsWorkText',
  CAR_WORK_TABLE_COLUMNS =  'carWorkTableColumns',
  SHOW_DETAILS_CAPTION = 'showDetailsCaption',
  HIDE_DETAILS_CAPTION = 'hideDetailsCaption',
  ITEMS_PER_PAGE_LABEL = 'itemsPerPageLabel',
  CAR_WORK_VIEW_BUTTON = 'carWorkViewButton',
  CAR_WORK_EDIT_BUTTON = 'carWorkEditButton',
  FAV_CAR_WORK_NAME = 'favCarWorkName',
  UPDATE_SUCCESS = 'updateSuccess',
  FAV_CAR_WORK_TYPE = 'favCarWorkType',
  FAV_CAR_WORK_TIME = 'favCarWorkTime',
  FAV_CAR_WORK_COST = 'favCarWorkCost',
  FAV_CAR_WORK_INFO = 'favCarWorkInfo',
  VALUE_LESS_ZERO = 'valueLessZero',
  ALL_CAR_WORK_MODAL = 'allCarWorkModal',
  EDIT_CAR_WORK_MODAL_TITLE = 'editCarWorkModalTitle',
  SELECT_WORK_TO_ORDER_MODAL = 'selectWorkToOrderModal',
  INVALID_NUMBER = 'invalidNumber',
}

export { CarWorkTranslationKey };
