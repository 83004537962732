import { CButton, CModalFooter } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { Spinner, usePermissionToastContext } from 'components/common/common';
import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './style.module.scss';

type Props = {
  isReadOnly?: boolean,
  isUpdateData: boolean,
  hasModifyBtn?: boolean,
  onEditButtonClick?: () => void,
  onClose: () => void,
  desc?: ReactElement,
};

const ModalFooter: FC<Props> = (
  {
    isReadOnly,
    isUpdateData,
    hasModifyBtn = true,
    onEditButtonClick,
    onClose,
    desc = '',
  }) => {
  const { t } = useTranslation(AppTranslationKey.CAR_MODAL);
  const { onPermissionAction } = usePermissionToastContext();

  return (
    <CModalFooter className={styles.modalFooter} >
      {desc && <h3 style={ { flexGrow: 1 } }>{desc}</h3> }
      <CButton
        color="dark"
        variant = {hasModifyBtn ? 'ghost' : undefined}
        onClick={onClose}
      >
        {t('closeButtonCaption')}
      </CButton>
      { hasModifyBtn && <div>
        {isReadOnly && <CButton
          color="danger"
          onClick={onEditButtonClick && onPermissionAction(onEditButtonClick)}
        >
          {t('editButtonCation')}
        </CButton>}
        {isReadOnly ||
          <CButton
            color="primary"
            type="submit"
            disabled={isUpdateData}
          >
            {isUpdateData && (<Spinner color="light"/>)}
            {t('saveButtonCaption')}
          </CButton>
        }
      </div>}
    </CModalFooter>
  );
};

export { ModalFooter };
