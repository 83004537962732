import { CForm, CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react-pro';
import { AppTranslationKey, DataStatus } from 'common/enums/enums';
import { SparePartForm, SparePartsCreateRequestDto } from 'common/types/types';
import { useAppDispatch, useAppSelector, useEffect } from 'hooks/hooks';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { sparePartsActions } from 'store/actions';

import { ModalFooter } from '../components/components';
import { DEFAULT_SPARE_PART_MODAL_PAYLOAD } from './common';
import { AddInfoField } from './components/add-info-field';
import { NameField } from './components/name-field';
import { OriginalCodeField } from './components/original-code-field';
import { PriceSellField } from './components/price-sell-field';
import { SupplierField } from './components/supplier-field';
import { ValueCurrentField } from './components/value-current-field';
import { ValueMinField } from './components/value-min-field';
import { useSparePartModalContext } from './context/spare-part-modal-context';

// type PaginationStateType = {
//   perPage: number,
//   activePage: number,
// };

// const INIT_PAGINATION: PaginationStateType = { perPage: 10, activePage: 0 };

const SparePartModalForm: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(AppTranslationKey.SPARE_PARTS);
  const {
    sparePart,
    stockId,
    isOpen,
    isReadOnly,
    onClose,
    setSetting,
    onReset,
  } = useSparePartModalContext();

  // const { paginationState } = useSparePartTableContext();

  const modalTitle = sparePart ? t('editModalTitle') : t('createModalTitle');

  const { suppliers, createDataStatus, updateDataStatus } = useAppSelector(({ supplier, spareParts }) => ({
    suppliers: supplier.list,
    updateDataStatus: spareParts.dataStatusUpdate,
    createDataStatus: spareParts.dataStatusCreate,
  }));

  const isUpdateData = createDataStatus === DataStatus.PENDING || updateDataStatus === DataStatus.PENDING;

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<SparePartForm>({
    defaultValues: DEFAULT_SPARE_PART_MODAL_PAYLOAD,
  });

  const watchInfo = watch('sparePartsInfo');

  const onEditButtonClick = (): void => {
    setSetting({ isReadOnly: false, stockId:  stockId });
  };

  const onHandleClose = (): void => {
    onClose();
    onReset();
    reset(DEFAULT_SPARE_PART_MODAL_PAYLOAD);
  };

  const onSave = (data: SparePartForm): void => {
    const payload: SparePartsCreateRequestDto = {
      sparePartsTypeId: null,
      sparePartsName: data.sparePartsName,
      sparePartsInfo: data.sparePartsInfo,
      counterpartyId: +data.counterpartyId,
      stockId,
      valueCurrent: data.valueCurrent as number || 0,
      valueMin: data.valueMin as number || 0,
      priceSell: data.priceSell as number || 0,
      originalCode: data.originalCode,
    };

    // const { perPage, activePage } = paginationState[stockId] || INIT_PAGINATION;

    if (sparePart) {
      dispatch(sparePartsActions.updateSparePart({ sparePartId: sparePart.sparePartId, payload })).then(() => {
        // dispatch(sparePartsActions.getListSpareParts({ stockId, size: perPage, page: activePage }));
        dispatch(sparePartsActions.getAllSparePartsByStoreId({ stockId }));
        onHandleClose();
      });
    } else {
      dispatch(sparePartsActions.createSparePart(payload)).then(() => {
        // dispatch(sparePartsActions.getListSpareParts({ stockId, size: perPage, page: activePage }));
        dispatch(sparePartsActions.getAllSparePartsByStoreId({ stockId }));
        onHandleClose();
      });
    }
  };

  useEffect(() => {
    if (sparePart) {
      reset({ ...sparePart });
    } else {
      reset(DEFAULT_SPARE_PART_MODAL_PAYLOAD);
    }
  }, [sparePart, reset]);

  return (
    <CModal
      visible={ isOpen }
      onClose={ onHandleClose }
      backdrop="static"
      size="lg"
      scrollable
    >
      <CModalHeader>
        <CModalTitle>{modalTitle} #{sparePart?.sparePartId}</CModalTitle>
      </CModalHeader>
      <CForm className="form-scroll" onSubmit={ handleSubmit(onSave) }>
        <CModalBody>
          <NameField disabled={ isReadOnly } register={ register } errors={ errors } />
          <AddInfoField disabled={ isReadOnly } register={ register } value={ watchInfo } />
          <SupplierField disabled={ isReadOnly } register={ register } errors={ errors } suppliers={ suppliers } />
          <ValueCurrentField disabled={ isReadOnly } register={ register } errors={ errors } setValue={setValue}/>
          <PriceSellField disabled={ isReadOnly } register={ register } errors={ errors } setValue={setValue}/>
          <ValueMinField disabled={ isReadOnly } register={ register } errors={ errors } setValue={setValue}/>
          <OriginalCodeField disabled={ isReadOnly } register={ register } />
        </CModalBody>
        <ModalFooter
          isReadOnly={ isReadOnly }
          isUpdateData={ isUpdateData }
          onEditButtonClick={ onEditButtonClick }
          onClose={ onHandleClose }
        />
      </CForm>
    </CModal>
  );
};

export { SparePartModalForm };
