import {
  AppTranslationKey, ClientSparePartsTranslationKey,
  DataStatus,
  ModalFormMode,
  ModalFormType,
  NotificationType,
  OrderStatus,
} from 'common/enums/enums';
import { CarServiceDto, ClientSpareParts, OrderDataRequestDto, OrderModalFormData, WorkDto } from 'common/types/types';
import { OrderModalForm } from 'components/modals/modals';
import { getFormattedDate } from 'helpers/helpers';
import {
  useAppDispatch,
  useAppSelector,
  useCarModal, useCustomerSparePartsModal,
  useListCarsModal,
  useSelectWorkToOrderModal,
  useState,
} from 'hooks/hooks';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { appActions, taskActions, widgetActions } from 'store/actions';
import { clearModalSelectedCar } from 'store/car/reducer';
import { disableReadOnlyModalMode, enabledReadOnlyModalMode } from 'store/modal/reducer';
import { clearOrder, clearSelectedWorks, stashClientSpareParts } from 'store/task/reducer';

type UseOrderModal = {
  getOrderModal: () => ReactElement,
  handleOpenTaskModal: (orderId: number) => void,
};
const useOrderModal = (activeCarService: CarServiceDto | null): UseOrderModal => {
  const {
    order,
    modalFormMode,
    dataStatusAddEditTask,
    selectedWorks,
    userId,
    statuses,
  } = useAppSelector( ({ auth, task, modal, dictionary })=> ({
    userId: auth.currentUser?.id ?? 0,
    order: task.order,
    statuses: dictionary.data?.requestStatuses,
    dataStatusAddEditTask: task.dataStatusAddEditTask,
    selectedWorks: task.selectedWorks,
    modalFormMode: modal.modalFormMode?.[ModalFormType.ORDER_MODAL] ?? ModalFormMode.READONLY_MODE,
  }));
  const { t } =
    useTranslation(
      [ AppTranslationKey.CAR_SERVICE_ORDER, AppTranslationKey.CAR_SERVICE, AppTranslationKey.CLIENT_SPARE_PARTS ],
    );

  const dispatch = useAppDispatch();
  const { getSelectWorkToOrderModal, toggleSelectWorkToOrderModal } = useSelectWorkToOrderModal();
  const { getCarModal, toggleCarModal } = useCarModal();
  const { getListCarsModal, toggleListCarsModal } = useListCarsModal();
  const { getCustomerSparePartsModal, toggleCustomerSparePartsModal } =
    useCustomerSparePartsModal( order?.sparePartsFromClient ?? [], order?.id ?? null );

  const [ isTaskModalOpen, setIsTaskModalOpen ] = useState<boolean>(false);
  const isOrderModalFormReadOnly = modalFormMode === ModalFormMode.READONLY_MODE;
  const isUpdateOrder = dataStatusAddEditTask === DataStatus.PENDING;

  const handleCloseOrderModal = (): void => {
    dispatch(clearModalSelectedCar());
    dispatch(clearSelectedWorks());
    dispatch(clearOrder());
    setIsTaskModalOpen(false);
  };

  const handleOrderModify = (orderModalFormData: OrderModalFormData): void => {

    const dateStr = getFormattedDate(orderModalFormData.carReceiveDate || new Date(), 'yyyy-MM-dd');
    const timeStr = getFormattedDate(orderModalFormData.carReceiveTime || new Date(), 'HH:mm:ss');

    const order: OrderDataRequestDto = {
      carId: orderModalFormData.autoId,
      dateCarReceive: `${ dateStr }T${ timeStr }`,
      requestStatusId: orderModalFormData.requestStatusId,
      requestSourceId: 2,
      carServiceId: activeCarService?.id,
      problemDescription: orderModalFormData.problemDescription,
      comment: orderModalFormData.comment,
      requestTypeId: 1,
      works: selectedWorks.map( (item): WorkDto => {
        return {
          priceOne: parseFloat(item.price),
          serviceWorkId: item.workId,
          serviceWorkName: item.name,
          workCount: parseFloat(item.qty),
          priceTotal: parseFloat(item.sum),
        };
      }),
    };

    if (orderModalFormData.orderId) {
      dispatch(taskActions.updateOrder({ ...order, requestId: orderModalFormData.orderId }))
        .unwrap()
        .then(() => {
          dispatch(widgetActions.getOpenRequests({ userId }));
          dispatch(appActions.notify({ type: NotificationType.SUCCESS, message: t('successEditOrderMes') }));
          handleCloseOrderModal();
        });
    } else {
      if (
        order.requestTypeId === OrderStatus.CLOSE &&
        (order?.works ?? []).length > 0
      ) {
        dispatch(appActions.notify({
          type: NotificationType.ERROR,
          message:t('openWorkErrorMes'),
        }));
      } else {
        dispatch(taskActions.addTask(order))
          .unwrap()
          .then(() => {
            dispatch(widgetActions.getOpenRequests({ userId }));
            dispatch(appActions.notify({ type: NotificationType.SUCCESS, message: t('successAddOrderMes') }));
            handleCloseOrderModal();
          });
      }
    }
  };

  const handleEditButtonClick = (): void => {
    dispatch(disableReadOnlyModalMode(ModalFormType.ORDER_MODAL));
  };

  const handleOpenAddCarModal = (): void => toggleCarModal(true);
  const handleOpenListCarsModal = (): void => toggleListCarsModal(true);
  const handleWorkFieldAction = (): void => toggleSelectWorkToOrderModal(true);
  const handleClientSparePartsFieldAction = (): void => toggleCustomerSparePartsModal(true);

  const handleOpenTaskModal = (orderId: number): void => {
    dispatch(enabledReadOnlyModalMode(ModalFormType.ORDER_MODAL));
    dispatch(taskActions.getOrderById({ orderId }))
      .unwrap()
      .then(() => {
        setIsTaskModalOpen(true);
      });
  };

  const onSaveClientSpareParts = (clientSpareParts: ClientSpareParts[]): void => {
    order ?
      dispatch(taskActions.updateClientSPInOrder({
        sparePartFromClientList: clientSpareParts,
        order: order,
      }))
        .unwrap()
        .then(() => {
          dispatch(appActions.notify({
            type: NotificationType.SUCCESS,
            message: t(ClientSparePartsTranslationKey.SUCCESS_ADDED_SP, { ns: AppTranslationKey.CLIENT_SPARE_PARTS }),
          }));
        }) :
      dispatch(stashClientSpareParts(clientSpareParts));
    toggleCustomerSparePartsModal(false);
  };

  const getOrderModal = (): ReactElement => {

    return <>
      <OrderModalForm
        isOpen={ isTaskModalOpen }
        order={ order }
        isReadOnly={ isOrderModalFormReadOnly }
        isUpdateOrder={ isUpdateOrder }
        onClose={ handleCloseOrderModal }
        onSubmit={ handleOrderModify }
        onEditButtonClick={ handleEditButtonClick }
        statuses={ statuses ?? [] }
        onOpenCarModal={ handleOpenAddCarModal }
        onOpenListCarsModal={ handleOpenListCarsModal }
        workFieldAction={ handleWorkFieldAction }
        clientSparePartsFieldAction={handleClientSparePartsFieldAction}
        activeCarServiceId={activeCarService?.id ?? 0}
      />
      {getSelectWorkToOrderModal({
        orderId: order?.id ?? 0,
        activeCarServiceId: activeCarService?.id ?? 0,
      })}
      {getCarModal({
        activeCarServiceId: activeCarService?.id ?? 0,
      })}
      {getListCarsModal({
        activeCarServiceId: activeCarService?.id ?? 0,
      })}
      { getCustomerSparePartsModal({ onSaveClientSpareParts }) }
    </>;
  };

  return { getOrderModal, handleOpenTaskModal };
};

export { useOrderModal };
