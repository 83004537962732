import { CarModalFormData } from 'common/types/types';

const DEFAULT_CAR_MODAL_PAYLOAD: CarModalFormData = {
  vinCode: '',
  carBrandId: -1,
  carModelId: -1,
  carBodyTypeId: null,
  carColorId: null,
  carRegNum: '',
  createYear: null,
  mileageKm: null,
  capacity: null,
  carInfo: '',
};

export { DEFAULT_CAR_MODAL_PAYLOAD };
