import { CarServiceEditRequestDto } from 'common/types/types';

const DEFAULT_EDIT_CAR_SERVICE_PAYLOAD: CarServiceEditRequestDto = {
  id: undefined,
  name: '',
  address: '',
  info: '',
  statusId: 1,
};

export { DEFAULT_EDIT_CAR_SERVICE_PAYLOAD };
