import { ApiPath, DictionaryApiPath } from 'common/enums/enums';
import { DictionaryDto, StatusTask, WorkOrdersStatus } from 'common/types/types';
import { i18next } from 'i18n';
import { Http } from 'services/http/http.service';

type Constructor = {
  http: Http;
  apiPrefix: string;
};

class DictionaryApi {
  #http: Http;

  #apiPrefix: string;

  public constructor({ http, apiPrefix }: Constructor) {
    this.#http = http;
    this.#apiPrefix = apiPrefix;
  }

  public getDictionaries(userId: number):Promise<DictionaryDto> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.DICTIONARY}${DictionaryApiPath.INIT}?userId=${userId}`,
    );
  }

  public getStatuses():Promise<StatusTask[]> {
    const locale = (i18next.language || 'uk');

    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.DICTIONARY}${DictionaryApiPath.STATUSES}?locale=${locale}`,
    );
  }

  public getWorkOrderStatuses(): Promise<WorkOrdersStatus[]> {
    return this.#http.load(
      `${this.#apiPrefix}${ApiPath.DICTIONARY}${DictionaryApiPath.WORK_ORDER_STATUSES}`,
    );
  }

}

export { DictionaryApi };
