import { CCard, CCardBody, CCardHeader } from '@coreui/react-pro';
import { AppTranslationKey, DataStatus } from 'common/enums/enums';
import { CarServicesNavTabs, Spinner } from 'components/common/common';
import { NoData } from 'components/common/no-data/no-data';
import { useExtraButtonContext } from 'contexts/extra-button-context';
import { useAppSelector, useCarServices, useEffect } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './style.module.scss';

const CarServicesCustomers: FC = () => {
  const { t } =
    useTranslation([AppTranslationKey.CAR_SERVICE_CUSTOMER, AppTranslationKey.CAR_SERVICE ]);
  const { setExtraButton, removeExtraButton } = useExtraButtonContext();
  const { userId, userCarServices, dataStatus } = useAppSelector(({ carServices, auth }) => ({
    userCarServices: carServices.userCarServices,
    dataStatus: carServices.dataStatus,
    userId: auth.currentUser?.id ?? 0,
  }));

  const { activeCarService, handleActiveCarServiceChange } = useCarServices({ userId });

  useEffect(() => {
    handleActiveCarServiceChange(userCarServices[0]);
  }, [ handleActiveCarServiceChange, userCarServices ]);

  useEffect(() => {
    setExtraButton({
      isHide: false,
      translateTitleKey: `${AppTranslationKey.CAR_SERVICE_CUSTOMER}:addCustomerCaptionButton`,
      action: () => null,
    });

    return () => {
      removeExtraButton();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabClick = (id: number): void => {
    handleActiveCarServiceChange(userCarServices.find((item) => item?.id === id) ?? null);
  };

  const isLoading = dataStatus === DataStatus.PENDING;

  if (isLoading) {
    return <Spinner isOverflow/>;
  }

  if (userCarServices.length === 0) {
    return <NoData
      title={t('noCarServiceTitle', { ns: AppTranslationKey.CAR_SERVICE })}
      message={t('noCarServiceText', { ns: AppTranslationKey.CAR_SERVICE })}/>;
  }

  return (
    <CCard>
      <CCardHeader className={styles.cardTitle}>
        <CarServicesNavTabs
          carServices={userCarServices}
          activeTabId={activeCarService?.id}
          handleTabClick={handleTabClick}
        />
      </CCardHeader>
      <CCardBody>
        <NoData title={t('noCustomersTitle')} message={t('noCustomersText')}/>
      </CCardBody>
    </CCard>
  );
};

export { CarServicesCustomers };
