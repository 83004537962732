import { CCol, CFormLabel, CRow } from '@coreui/react-pro';
import { Item } from '@coreui/react-pro/dist/components/smart-table/types';
import { AppTranslationKey, WorkOrderTranslationKey } from 'common/enums/enums';
import { SparePartsBindToWorkOrder } from 'common/types/types';
import { TableField } from 'components/common/table-field/table-field';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { getSparePartsRowTableColumns } from './helpers/helper';

type Props = {
  isDisable?: boolean,
  spareParts: SparePartsBindToWorkOrder[],
  onSelectClick?: () => void,
  onRemoveSparePartItem: (sparePartId: number) => void,
  totalLabel?: React.ReactElement,
};

const WorkOrderSparePartsRow: FC<Props> = (
  {
    spareParts,
    onRemoveSparePartItem,
    isDisable = false,
    onSelectClick,
    totalLabel,
  }) => {
  const { t } = useTranslation(AppTranslationKey.WORK_ORDER);
  const columns = getSparePartsRowTableColumns();

  const handleRemoveClick = (item: Item): void =>  {
    onRemoveSparePartItem((item as SparePartsBindToWorkOrder ).sparePartId);
  };

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="problemDescription" className="col-sm-2 col-form-label">
        {t(WorkOrderTranslationKey.WORK_ORDER_DETAILS_SPARE_PARTS_TITLE)}
      </CFormLabel>
      <CCol sm={10}>
        <TableField
          noDataMsg= { t(WorkOrderTranslationKey.WORK_ORDER_NO_SPARE_PARTS) ?? undefined }
          columns={columns}
          data={spareParts}
          onRemoveClick={handleRemoveClick}
          isDisable={isDisable}
          onSelectClick={onSelectClick}
          totalLabel={ totalLabel }
        />
      </CCol>
    </CRow>
  );
};

export { WorkOrderSparePartsRow };
