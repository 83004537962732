import { createSlice } from '@reduxjs/toolkit';
import { DataStatus } from 'common/enums/enums';

import { getCertificateOfCompletedWork } from './action';

type State = {
  certificateOfCompletedWorkUrl: string,
  dataStatusCertificateOfCompletedWork: DataStatus,
};

const initialState: State = {
  certificateOfCompletedWorkUrl: '',
  dataStatusCertificateOfCompletedWork: DataStatus.IDLE,
};

const documentationSlice = createSlice({
  name: 'documentation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    // get Certificate Of Completed Work
      .addCase(getCertificateOfCompletedWork.pending, (state) => {
        state.dataStatusCertificateOfCompletedWork = DataStatus.PENDING;
      })
      .addCase(getCertificateOfCompletedWork.rejected, (state) => {
        state.dataStatusCertificateOfCompletedWork = DataStatus.REJECTED;
        state.certificateOfCompletedWorkUrl = initialState.certificateOfCompletedWorkUrl;
      })
      .addCase(getCertificateOfCompletedWork.fulfilled, (state, { payload }) => {
        state.dataStatusCertificateOfCompletedWork = DataStatus.FULFILLED;
        state.certificateOfCompletedWorkUrl = payload.url;
      });
  },
});

const documentationReducer = documentationSlice.reducer;
export { documentationReducer };
