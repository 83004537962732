import { CCard, CCardHeader, CContainer } from '@coreui/react-pro';
import { AppRoute, AppTranslationKey, DataStatus, PersonalRoute } from 'common/enums/enums';
import { SupplierAddRequestDto } from 'common/types/types';
import { useAppDispatch, useAppSelector, useEffect, useNavigate } from 'hooks/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { supplierActions } from 'store/actions';
import { resetCreatePage } from 'store/supplier/reducer';

import { SupplierForm } from '../supplier-form/supplier-form';

const AddSupplier: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(AppTranslationKey.SUPPLIERS);
  const { dataStatusCreate } = useAppSelector(({ supplier }) => supplier);

  const handleConfirm = (supplier: SupplierAddRequestDto): void => {
    let counterpartyStatusId = supplier.counterpartyStatusId === -1 ? undefined : supplier.counterpartyStatusId;

    counterpartyStatusId = counterpartyStatusId ? (+counterpartyStatusId) : undefined;

    dispatch(supplierActions.createSupplier({
      ...supplier,
      counterpartyStatusId,
    }));
  };

  useEffect(() => () => {
    dispatch(resetCreatePage());
  }, [dispatch]);

  useEffect(() => {
    if(dataStatusCreate === DataStatus.FULFILLED) {
      navigate(`${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.SUPPLIERS}`);
    }
  }, [dataStatusCreate, navigate]);

  return (
    <CContainer fluid>
      <CCard>
        <CCardHeader>
          {t('addSupplierTitle')}
        </CCardHeader>
        <SupplierForm onSubmit={ handleConfirm } />
      </CCard>
    </CContainer>
  );
};

export { AddSupplier };
