enum SelectOrderModalTranslationKey {
  TITLE = 'title',
  NO_ORDER_SELECTED = 'noOrderSelected',
  AUTO_TABLE_FIELD = 'autoTableField',
  DATETIME_TABLE_FIELD = 'dateTimeTableField',
  COMMENT_TABLE_FIELD = 'comment',
  STATUS_TABLE_FIELD = 'status',
  ALERT_ORDER_TITLE = 'alertOrderTitle',
  ALERT_AUTO_TITLE = 'alertAutoTitle',
  SELECTED_ORDER_MSG = 'selectedOrderMsg',
}

export { SelectOrderModalTranslationKey };
