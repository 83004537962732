import { cifUa } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CCol, CFormInput, CFormLabel, CRow } from '@coreui/react-pro';
import { NotificationType } from 'common/enums/enums';
import { AppTranslationKey } from 'common/enums/translation-key/app-translation-key.enum';
import { CarModalFormData, Color, InfoSearchDataDto, InfoSearchErrorDto } from 'common/types/types';
import { getValidClasses } from 'helpers/helpers';
import { useAppDispatch, useAppSelector, useState } from 'hooks/hooks';
import { isEmpty } from 'lodash';
import React, { FC } from 'react';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { UseFormGetValues, UseFormRegister, UseFormSetError, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { appActions, carActions } from 'store/actions';

import { ConfirmFillFormModal } from './confirm-fill-form-modal';
import styles from './styles.module.scss';

type Props = {
  register: UseFormRegister<CarModalFormData>,
  getValues:  UseFormGetValues<CarModalFormData>,
  setValue:  UseFormSetValue<CarModalFormData>,
  setError: UseFormSetError<CarModalFormData>,
  clearErrors: UseFormSetError<CarModalFormData>,
  errors: FieldErrors<CarModalFormData>,
  disabled: boolean,
};

const RegNumField: FC<Props> = ({
  disabled = false, register, errors, getValues, setError, clearErrors, setValue,
}) => {
  const { t } = useTranslation(AppTranslationKey.CAR_MODAL);
  const dispatch = useAppDispatch();
  const [isVisibleConfirmModal, setVisibleConfirmModal] = useState<boolean>(false);
  const [isValid, setValid] = useState<boolean>(false);
  const [infoSearchData, setInfoSearchData] = useState<InfoSearchDataDto | null>(null);
  const {
    brands,
    bodyTypes,
    colors,
  } = useAppSelector(({ car, dictionary }) => ({
    brands: dictionary.data?.carBrands || [],
    models: car.models,
    bodyTypes: dictionary.data?.bodyTypes || [],
    colors: dictionary.data?.carColors || [],
  }));

  const isEmptyFields = (formData: CarModalFormData): boolean => {
    return isEmpty(formData.vinCode) &&
      isEmpty(formData.createYear) &&
      isEmpty(formData.capacity) &&
      isEmpty(formData.carColorId) &&
      isEmpty(formData.carBodyTypeId) &&
      isEmpty(formData.carBodyTypeId);
  };

  const setFormData = (it: InfoSearchDataDto): void => {
    const colorId =
      colors.find(
        (color: Color) => color.name.toUpperCase() === it.color.toUpperCase(),
      )?.id ?? -1;
    const bodyTypesId =
      bodyTypes.find(
        (body) => body.name.toUpperCase() === it.body.toUpperCase(),
      )?.id ?? -1;
    const brandId =
      brands.find(
        (brand) =>
          brand.name.toUpperCase() ===
          it.brand.split(' ')[0].toString().toUpperCase(),
      )?.id ?? -1;

    setValue('vinCode', it.vin);
    setValue('createYear', it.makeYear);
    setValue('capacity', it.capacity);
    setValue('carColorId', colorId);
    setValue('carBodyTypeId', bodyTypesId);
    setValue('carBrandId', brandId);
    setValue('mileageKm', '');
    setValue('carInfo', '');
  };

  const setEmptyFieldFarmData = (it: InfoSearchDataDto): void => {
    const formData = getValues();

    isEmpty(formData.vinCode) && setValue('vinCode', it.vin);
    isEmpty(formData.createYear) && setValue('createYear', it.makeYear);
    isEmpty(formData.capacity) && setValue('capacity', it.capacity);
    formData.carColorId?.toString() === '-1' &&
    setValue('carColorId', colors.find(
      (color) => color.name.toUpperCase() === it.color.toUpperCase())?.id ?? -1);
    formData.carBodyTypeId?.toString() === '-1' &&
    setValue('carBodyTypeId', bodyTypes.find(
      (body) => body.name.toUpperCase() === it.body.toUpperCase())?.id ?? -1);
    formData.carBrandId.toString() === '-1' &&
    setValue('carBrandId', brands.find(
      (brand) =>
        brand.name.toUpperCase() ===
        it.brand.split(' ')[0].toString().toUpperCase())?.id ?? -1);
  };

  const fillFormData = (it: InfoSearchDataDto): void =>  {
    const formData = getValues();

    if(isEmptyFields(formData)) {
      setFormData(it);
    } else {
      setVisibleConfirmModal(true);
      setInfoSearchData(it);
    }
  };

  const handleSearchClick = (): void => {
    const formData = getValues();

    if(isEmpty(formData.carRegNum)) {
      setError('carRegNum', { message: t('searchBtnErrorText') ?? 'error' });
    } else {
      dispatch(carActions.searchCar({
        regNum: formData.carRegNum,
        vinCode: null,
      }))
        .unwrap()
        .then((it) => {
          if ('code' in it) {
            dispatch(appActions.notify({
              type: NotificationType.WARNING,
              message: (it as InfoSearchErrorDto).title,
            }));
          } else {
            setValid(true);
            fillFormData(it as InfoSearchDataDto);
          }
        });
    }
  };

  const handleChangeField = (): void => {
    clearErrors('carRegNum', { message: '' });
    setValid(false);
  };

  const handleConfirmClose = (): void =>  {
    setVisibleConfirmModal(false);
  };

  const handleConfirm = (): void => {
    infoSearchData && setEmptyFieldFarmData(infoSearchData);
    setVisibleConfirmModal(false);
  };

  const handleConfirmAll = (): void => {
    infoSearchData && setFormData(infoSearchData);
    setVisibleConfirmModal(false);
  };

  return (
    <>
      <CRow className="mb-3">
        <CFormLabel htmlFor="carRegNum" className="col-sm-3 col-form-label">
          {t('regNum')}*
        </CFormLabel>
        <CCol sm={7}>
          <CFormInput
            disabled={disabled}
            {...register('carRegNum', {
              required: t('requiredFormField') ?? 'This field is required',
            })}
            id="carRegNum"
            invalid={Boolean(errors.carRegNum)}
            feedbackInvalid={errors.carRegNum?.message}
            onChange={handleChangeField}
            valid={isValid}
          />
        </CCol>
        <CCol sm={2}>
          <div
            className={disabled ? styles.searchBtnDisabled : styles.searchBtn}
            onClick={(): void => {
              disabled || handleSearchClick();
            }}
          >
            <div
              className={getValidClasses(
                styles.carStateNumLeftSide,
                disabled ? styles.bColorDisabled : styles.bColor,
              )}
            >
              {
                <CIcon
                  className={styles.carStateNumLeftSideIcon}
                  icon={disabled ? '' : cifUa}
                  width={10}
                />
              }
              <span className={styles.carStateNumLeftSideText}>UA</span>
            </div>
            <div style={{ fontSize: 16, margin: 'auto' }}>
              {t('findBtnText')}
            </div>
          </div>
        </CCol>
      </CRow>
      <ConfirmFillFormModal
        isVisible={isVisibleConfirmModal}
        onClose={handleConfirmClose}
        onConfirm={handleConfirm}
        onAllConfirm={handleConfirmAll}
      />
    </>
  );
};

export { RegNumField };
