import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppTranslationKey } from 'common/enums/enums';
import {
  AsyncThunkConfig,
  CarServiceDto,
  CarServiceEditRequestDto,
  CarServicesGetByUserIdRequestDto, FiltersPayload,
  OrderDto,
  OrdersGetByCarServiceIdRequestDto,
} from 'common/types/types';
import { t } from 'i18next';
import { userActions } from 'store/actions';

import { ActionType } from './common';

const getAllCarServiceByUserId = createAsyncThunk<
  CarServiceDto[],
  CarServicesGetByUserIdRequestDto,
  AsyncThunkConfig
>(ActionType.GET_ALL, async (payload, { extra }) => {
  const { carServicesApi } = extra;

  return carServicesApi.getAllCarServiceByUserId(payload);
});

const updateCarServiceById = createAsyncThunk<
  CarServiceDto,
  CarServiceEditRequestDto,
  AsyncThunkConfig
>(ActionType.UPDATE_BY_ID, async (payload, { extra }) => {
  const { carServicesApi, notification } = extra;

  const response = await carServicesApi.updateCarServiceById(payload);
  notification.success(t(`${AppTranslationKey.CAR_SERVICE}:carServiceUpdate`) ?? 'Service station successfully updated');

  return response;
});

const createCarService = createAsyncThunk<
  CarServiceDto,
  CarServiceEditRequestDto,
  AsyncThunkConfig
>(ActionType.CREATE, async (payload, { extra, dispatch, getState }) => {
  const { carServicesApi, notification } = extra;
  const { auth } = getState();

  const response = await carServicesApi.createCarService(payload);
  auth.currentUser?.id && dispatch(userActions.getUserById(auth.currentUser?.id));
  notification.success(t(`${AppTranslationKey.CAR_SERVICE}:carServiceAdd`) ?? 'Service station successfully added');

  return response;
});

const getOrdersByCarServiceId = createAsyncThunk<
  OrderDto[],
  OrdersGetByCarServiceIdRequestDto,
  AsyncThunkConfig
  >(ActionType.GET_ALL_ORDERS, async (payload, { extra }) => {
    const { orderApi } = extra;

    return orderApi.getOrdersByCarServiceId(payload);
  });

const getActiveOrdersByCarServiceId = createAsyncThunk<
  OrderDto[],
  OrdersGetByCarServiceIdRequestDto,
  AsyncThunkConfig
>(ActionType.GET_ALL_ORDERS, async (payload, { extra }) => {
  const { orderApi } = extra;

  return orderApi.getOrdersByFilters({
    carServiceId: payload.carServiceId,
    dateFrom: '',
    dateTo: '',
    statuses:[1,2,3,4,5,6,7,8,9,10],
  });
});

const getOrdersByFilters = createAsyncThunk<
  OrderDto[],
  FiltersPayload,
  AsyncThunkConfig
>(ActionType.GET_ORDERS_BY_FILTERS, async (payload, { extra }) => {
  const { orderApi } = extra;

  return orderApi.getOrdersByFilters(payload);
});

export {
  createCarService,
  getActiveOrdersByCarServiceId,
  getAllCarServiceByUserId,
  getOrdersByCarServiceId,
  getOrdersByFilters,
  updateCarServiceById,
};
