enum ClientSparePartsTranslationKey {
  TITLE = 'title',
  COUNT = 'count',
  ADD_SPARE_PART_TITLE = 'addSparePartTitle',
  ADDED = 'added',
  NO_SPARE_PARTS = 'noSpareParts',
  CLOSE = 'close',
  SAVE = 'save',
  NAME = 'name',
  REQUIRED = 'required',
  EMPTY_SPARE_PARTS_LIST_ERROR = 'emptySparePartsListError',
  SUCCESS_ADDED_SP = 'successAddedSP',
  NOT_ADDED_SPARE_PART_ERROR = 'notAddedSparePartError',
}

export { ClientSparePartsTranslationKey };
