import { cilChevronBottom, cilChevronTop } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import React, { FC } from 'react';

import styles from './styles.module.scss';

type Props = {
  isOpen: boolean,
  toggleDetails?: () => void
};

const ToggleShowDetailsBtn: FC<Props> = ({ isOpen, toggleDetails }) => {

  return (
    <td className={styles.iconHover}>
      <CIcon
        icon={ isOpen ? cilChevronTop : cilChevronBottom } size="lg" onClick={toggleDetails}
      />
    </td>
  );
};

export { ToggleShowDetailsBtn };
