import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataStatus } from 'common/enums/enums';
import { DictionaryDto } from 'common/types/types';
import { uploadDictionariesToStorage } from 'helpers/helpers';

import { getDictionaries } from './actions';

type State = {
  data: DictionaryDto | null,
  dataDataStatus: DataStatus,
};

const initialState: State = {
  data: null,
  dataDataStatus: DataStatus.IDLE,
};

const dictionarySlice = createSlice( {
  name: 'dictionary',
  initialState,
  reducers: {
    manualAddDictionaries: (state, action:PayloadAction<DictionaryDto>) => {
      state.dataDataStatus = DataStatus.FULFILLED;
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // get dictionaries
      .addCase(getDictionaries.pending, (state) => {
        state.dataDataStatus = DataStatus.PENDING;
      })
      .addCase(getDictionaries.rejected, (state) => {
        state.dataDataStatus = DataStatus.REJECTED;
        state.data = null;
      })
      .addCase(getDictionaries.fulfilled, (state, { payload }) => {
        state.dataDataStatus = DataStatus.FULFILLED;
        state.data = payload;
        uploadDictionariesToStorage(payload);
      });
  },
});

const dictionaryReducer = dictionarySlice.reducer;
const { manualAddDictionaries } = dictionarySlice.actions;

export { dictionaryReducer, manualAddDictionaries };
