import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkConfig } from 'common/types/types';

import { ActionType } from './common';

const getCertificateOfCompletedWork = createAsyncThunk<
  { url: string },
  { requestId: number },
  AsyncThunkConfig
>(ActionType.GET_CERTIFICATE_OF_COMPLETED_WORK, async (payload, { extra }) => {
  const { documentationApi } = extra;

  return documentationApi.getCertificateOfCompletedWork(payload);
});

export { getCertificateOfCompletedWork };
