enum ExceptionMessage {
  UNKNOWN_ERROR = 'Unknown error occurred.',
  BAD_CREDENTIALS = 'You have entered an invalid email or password.',
  UNAUTHORIZED_USER = 'Unauthorized user.',
  EMAIL_IS_ALREADY_TAKEN = 'This email is already taken.',
  INVALID_TOKEN = 'Invalid token!',
  INVALID_USER_REQUEST = 'User request is invalid.',
  DEFAULT_SERVER_ERROR = 'Network Error',
  INVALID_AUTHORIZATION = 'Invalid authorization!',
  INVALID_REFRESH_TOKEN = 'Invalid refresh-token or authorization!',
}
export { ExceptionMessage };
